// export const SIGN_UP = '/signup';
// export const SIGN_UP_ANONYMOUS = '/anonymous/signup';
// export const SIGN_UP_KIT = '/kit/signup/:key?';
// export const SIGN_UP_ANONYMOUS_KIT = '/kit/anonymous/signup/:key?';

// export const SIGN_IN = '/signin';
// export const SIGN_IN_ANONYMOUS = '/anonymous/signin';

export const SIGN_UP_ANONYMOUS = '/signup';
export const SIGN_UP_ANONYMOUS_KIT = '/kit/signup/:key?';
// export const SIGN_IN_ANONYMOUS = '/signin';

export const REDEEM_TRIAL = '/trial/redeem/:promo?';
export const SIGN_UP_TRIAL = '/trial/signup/:promo?';

export const REDEEM_GROUP = '/group/redeem/:code?';
export const SIGN_UP_GROUP = '/group/signup/:code?';


// export const LANDING = '/';
export const SIGN_IN_ANONYMOUS = '/';
export const HOME = '/home';
export const PROFILE = '/temperature-profile';
export const ACCOUNT = '/account';
export const GROUPS = '/groups';
export const GROUPS_VIEW = '/groups/view/:name?';
export const GROUPS_ADMIN = '/groups/admin/:name?';


export const VERIFY = '/verify';
export const VERIFY_VIEW = '/verify/view/:id?';
export const SHARE = '/share';
export const SHARE_VIEW = '/share/view/:id?';

export const ADMIN = '/admin/ark';
export const RESET_PASSWORD = '/reset-password';
export const ACCOUNT_IMPORT = '/import-account';
export const HISTORY = '/history';
export const TOS = '/tos';
export const PRIVACY_POLICY = '/privacy';
export const LEGAL_INFORMATION = '/legal';
export const CHANGELOG = '/changelog';
export const ABOUT_US = '/about';




