import React, { useContext, useEffect, useState } from 'react';

import { withAuthorization } from '../../components/Session';

import { Alert, Button, Container, Form } from "react-bootstrap";

import firebase from 'firebase/app';
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/auth'
import LoadingOverlay from 'react-loading-overlay';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import { AuthUserContext } from '../../components/Session'


function AccountPage(props) {
  return(
    <Container style={{padding:0}}>
      <Helmet>
          <title>Account | PWT</title>
          <meta name="description" content="Update your temperature." />
        </Helmet>
        <AccountPageBase></AccountPageBase>
    </Container>         
  );
}






function AccountPageBase(props) {  
  let userContext = useContext(AuthUserContext);

  const [displayChangeUsername, setDisplayChangeUsername] = useState(false);

  useEffect(() => {
    let email = userContext.authUser.email
    if (!email.includes("warningtemperature.health")) {
      setDisplayChangeUsername(false)
    } else {
      setDisplayChangeUsername(true)
    }
  }, []);

  return(
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Account</h1>
      <ChangePasswordModule></ChangePasswordModule>
      {displayChangeUsername
      ?
      <>
        <hr/>
        <ChangeUsernameModule></ChangeUsernameModule>
      </>
      :
      null
      }
    </div>
  );    
}


function ChangePasswordModule(props) {
  const [showErrorPassword, setShowErrorPassword] = useState(false);
  const [showSuccessPassword, setShowSuccessPassword] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState("");


  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string()
    .min(6,"Password must be at least 6 characters.")   
    .required("Password is required."),
    newPassword: Yup.string()
    .min(6,"Password must be at least 6 characters.")   
    .required("Password is required."),
    newPasswordConfirm: Yup.string()
      .required("Please confirm the password.")
      .label('Confirm password')
      .test('passwords-match', 'Passwords must match.', function(value) {
        return this.parent.newPassword === value;
      }),
  });

  return(
    <div>       
      <Formik
        initialValues={{ currentPassword:"", newPassword:"", newPasswordConfirm:""}}
        validationSchema={changePasswordSchema}
        onSubmit={(values, {setSubmitting, resetForm}) => {
              setSubmitting(true);
              setShowErrorPassword(false);
              setShowSuccessPassword(false);

              const user = firebase.auth().currentUser;
              const credential = firebase.auth.EmailAuthProvider.credential(user.email, values.currentPassword);
              user.reauthenticateWithCredential(credential)
              .then(function() {
                  // User re-authenticated.
                  user.updatePassword(values.newPassword).then(function() {
                    // Update successful.
                    setSubmitting(false);
                    setShowSuccessPassword(true);
                  }).catch(function(error) {
                    // An error happened.
                    setSubmitting(false);
                    setErrorMessagePassword(error.message);
                    setShowErrorPassword(true);
                  });
              })
              .catch(function(error) {
              // An error happened.
              setSubmitting(false);
              setErrorMessagePassword('Could not validate account. Check that your current password is accurate.');
              setShowErrorPassword(true);
          });

        }}
      >
      {( {values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="mx-auto">
          <LoadingOverlay
          active={isSubmitting}
          spinner
          text='Updating password...'
          styles={{
            overlay: (base) => ({
              ...base,
            })
          }}
          >
          <h2>Change Your Password</h2>
          <Form.Group controlId="formUsername" hidden={true}>
            <Form.Label>Username:</Form.Label>
              <Form.Control
                type="text"
                name="username"
                autoComplete={"username"}
                placeholder="example-user-42"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username}
                isInvalid={errors.username && touched.username}
                isValid={!errors.username && touched.username}
              />
              <Form.Control.Feedback type="invalid" style={{
                      color: '#dc3545',
                      fontSize: '.8em',
                    }}>
                {errors.username}
              </Form.Control.Feedback>
              {/* <Form.Control.Feedback type="valid">Username looks valid.</Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group controlId="formGroupPassword">
          <Form.Label>Current Password:</Form.Label>
            <Form.Control
              type="password"
              name="currentPassword"
              autoComplete={"current-password"}
              placeholder="********"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.currentPassword}
              isInvalid={errors.currentPassword && touched.currentPassword}
              isValid={!errors.currentPassword && touched.currentPassword}
            />
            <Form.Control.Feedback type="invalid" style={{
                    color: '#dc3545',
                    fontSize: '.8em',
                  }}>
              {errors.currentPassword}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formGroupNewPassword">
            <Form.Label>New Password:</Form.Label>
            <Form.Control
              type="password"
              name="newPassword"
              autoComplete={"new-password"}
              placeholder="********"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPassword}
              isInvalid={errors.newPassword && touched.newPassword}
              isValid={!errors.newPassword && touched.newPassword}
            />
            <Form.Control.Feedback type="invalid" style={{
                    color: '#dc3545',
                    fontSize: '.8em',
                  }}>
              {errors.newPassword}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formGroupNewPasswordConfirm">
          <Form.Label>Confirm New Password:</Form.Label>
            <Form.Control
              type="password"
              name="newPasswordConfirm"
              autoComplete={"new-password"}
              placeholder="********"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPasswordConfirm}
              isInvalid={errors.newPasswordConfirm && touched.newPasswordConfirm}
              isValid={!errors.newPasswordConfirm && touched.newPasswordConfirm}
            />
            <Form.Control.Feedback type="invalid" style={{
                    color: '#dc3545',
                    fontSize: '.8em',
                  }}>
              {errors.newPasswordConfirm}
            </Form.Control.Feedback>
          </Form.Group>
          <Alert show={showSuccessPassword} variant="success">
            <Alert.Heading>Password Updated</Alert.Heading>
            <p>{'Your password has successfully been changed.'}</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShowSuccessPassword(false)} variant="outline-success">
                Dismiss
              </Button>
            </div>
          </Alert>
          <Alert show={showErrorPassword} variant="danger">
            <Alert.Heading>Failed to Update Password</Alert.Heading>
            <p>{errorMessagePassword}</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShowErrorPassword(false)} variant="outline-danger">
                Dismiss
              </Button>
            </div>
          </Alert>
          <Button className='pwt-button' disabled={isSubmitting} block bssize="large"  type="submit">
            Update Password
          </Button>
          </LoadingOverlay>
        </Form>
        )}
        </Formik>
    </div>
  );    
}
  
function ChangeUsernameModule(props) {
  const [showErrorUsername, setShowErrorUsername] = useState(false);
  const [showSuccessUsername, setShowSuccessUsername] = useState(false);
  const [errorMessageUsername, setErrorMessageUsername] = useState("");

  const changeUsernameSchema = Yup.object().shape({
    username: Yup.string()
    .min(2, "Username must have at least 2 characters.")
    .max(64, "Username can't be longer than 64 characters.")
    .required("Username is required.")
    .matches(
      /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/,
      "Username may contain only letters and numbers, optionally separated by underscores (_) or dashes (-)."
    ),
    usernameConfirm: Yup.string()
    .required("Please confirm the username.")
    .label('Confirm username')
    .test('usernames-match', 'Usernames must match.', function(value) {
      return this.parent.username === value;
    }),
    currentPassword: Yup.string()
    .min(6,"Password must be at least 6 characters.")   
    .required("Password is required."),
  });

  return(
    <div>       
      <Formik
          initialValues={{ currentPassword:"", username:"", usernameConfirm: "" }}
          validationSchema={changeUsernameSchema}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            setShowErrorUsername(false);
            const user = firebase.auth().currentUser;
            const credential = firebase.auth.EmailAuthProvider.credential(user.email, values.currentPassword);
            user.reauthenticateWithCredential(credential)
            .then(function() {
                var addMessage = firebase.functions().httpsCallable('changeUsername');
                addMessage({newUsername: values.username})
                .then(function(result) {
                  firebase.auth()
                    .signInWithEmailAndPassword(result.data.newEmail, values.currentPassword)
                    .then(function(result) {
                      setSubmitting(false);
                      setShowSuccessUsername(true);
                    })
                    .catch(function(error) {
                        setSubmitting(false);
                        setErrorMessageUsername(error.message);
                        setShowErrorUsername(true);
                    });
                })
                .catch(function(error) {
                  setSubmitting(false);
                  setErrorMessageUsername(error.message);
                  setShowErrorUsername(true);
                });
            })
            .catch(function(error) {
              // An error happened.
              setSubmitting(false);
              setErrorMessageUsername('Could not validate account. Check that your current password is accurate.');
              setShowErrorUsername(true)
            });
        }}
        >
        {( {values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            <LoadingOverlay
            active={isSubmitting}
            spinner
            text='Updating username...'
            styles={{
              overlay: (base) => ({
                ...base,
              })
            }}
            >
            <h2>Change Your Username</h2>
            <p>If your account was provided to you through your organization, you may not be able to change your username without first contacting your organization.</p>
            <Form.Group controlId="formNewUsername">
              <Form.Label>New Username:</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  autoComplete={"new-username"}
                  placeholder="example-user-42"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isInvalid={errors.username && touched.username}
                  isValid={!errors.username && touched.username}
                />
                <Form.Control.Feedback type="invalid" style={{
                        color: '#dc3545',
                        fontSize: '.8em',
                      }}>
                  {errors.username}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">Be sure to write this down. Do not use personally identifiable information.</Form.Control.Feedback>
              </Form.Group>
            <Form.Group controlId="formNewUsernameConfirm">
              <Form.Label>Confirm New Username:</Form.Label>
              <Form.Control
                type="text"
                name="usernameConfirm"
                autoComplete={"new-username"}
                placeholder="example-user-42"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                isInvalid={errors.usernameConfirm && touched.usernameConfirm}
                isValid={!errors.usernameConfirm && touched.usernameConfirm}
              />
              <Form.Control.Feedback type="invalid" style={{
                      color: '#dc3545',
                      fontSize: '.8em',
                    }}>
                {errors.usernameConfirm}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formCurrentPassword">
            <Form.Label>Current Password:</Form.Label>
              <Form.Control
                type="password"
                name="currentPassword"
                autoComplete={"current-password"}
                placeholder="********"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.currentPassword}
                isInvalid={errors.currentPassword && touched.currentPassword}
                isValid={!errors.currentPassword && touched.currentPassword}
              />
              <Form.Control.Feedback type="invalid" style={{
                      color: '#dc3545',
                      fontSize: '.8em',
                    }}>
                {errors.currentPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Alert show={showSuccessUsername} variant="success">
              <Alert.Heading>Username Updated</Alert.Heading>
              <p>{'Your username has successfully been changed.'}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShowSuccessUsername(false)} variant="outline-success">
                  Dismiss
                </Button>
              </div>
            </Alert>
            <Alert show={showErrorUsername} variant="danger">
              <Alert.Heading>Failed to Update Username</Alert.Heading>
              <p>{errorMessageUsername}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShowErrorUsername(false)} variant="outline-danger">
                  Dismiss
                </Button>
              </div>
            </Alert>
            <Button className='pwt-button' disabled={isSubmitting} block bssize="large"  type="submit">
              Update Username
            </Button>
            </LoadingOverlay>
          </Form>
        )}
        </Formik>
    </div>
  );    
}


// function ChangeEmailModule(props) {
//   const [showErrorEmail, setShowErrorEmail] = useState(false);
//   const [showSuccessEmail, setShowSuccessEmail] = useState(false);
//   const [errorMessageEmail, setErrorMessageEmail] = useState("");

//   const changeEmailSchema = Yup.object().shape({
//     email: Yup.string()
//     .required("Email is required.")
//     .email("Must be a valid email address."),
//     currentPassword: Yup.string()
//     .min(6,"Password must be at least 6 characters.")   
//     .required("Password is required."),
//   });

//   return(
//     <div>       
//       <Formik
//           initialValues={{ currentPassword:"", email:"" }}
//           validationSchema={changeEmailSchema}
//           onSubmit={(values, {setSubmitting, resetForm}) => {
//             setSubmitting(true);
//             setShowErrorEmail(false);
//             const user = firebase.auth().currentUser;
//             const credential = firebase.auth.EmailAuthProvider.credential(user.email, values.currentPassword);
//             user.reauthenticateWithCredential(credential)
//             .then(function() {
//                 // var addMessage = firebase.functions().httpsCallable('changeUsername');
//                 // addMessage({newUsername: values.email})
//                 // .then(function(result) {
//                 //   firebase.auth()
//                 //     .signInWithEmailAndPassword(result.data.newEmail, values.currentPassword)
//                 //     .then(function(result) {
//                 //       setSubmitting(false);
//                 //       setShowSuccessUsername(true);
//                 //     })
//                 //     .catch(function(error) {
//                 //         setSubmitting(false);
//                 //         setErrorMessageUsername(error.message);
//                 //         setShowErrorUsername(true);
//                 //     });
//                 // })
//                 // .catch(function(error) {
//                 //   setSubmitting(false);
//                 //   setErrorMessageUsername(error.message);
//                 //   setShowErrorUsername(true);
//                 // });
//             })
//             .catch(function(error) {
//               // An error happened.
//               setSubmitting(false);
//               setErrorMessageEmail('Could not validate account. Check that your current password is accurate.');
//               setShowErrorEmail(true)
//             });
//         }}
//         >
//         {( {values,
//             errors,
//             touched,
//             handleChange,
//             handleBlur,
//             handleSubmit,
//             isSubmitting }) => (
//           <Form onSubmit={handleSubmit} className="mx-auto">
//             <LoadingOverlay
//             active={isSubmitting}
//             spinner
//             text='Updating email...'
//             styles={{
//               overlay: (base) => ({
//                 ...base,
//               })
//             }}
//             >
//             <h2>Change Your Email</h2>
//             {/* <p>If your account was provided to you through your organization, you may not be able to change your username without first contacting your organization.</p> */}
//             <Form.Group controlId="formEmail">
//               <Form.Label>New Email:</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="Email"
//                   placeholder="john@example.com"
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   value={values.email}
//                   isInvalid={errors.email && touched.email}
//                   isValid={!errors.email && touched.email}
//                 />
//                 <Form.Control.Feedback type="invalid" style={{
//                         color: '#dc3545',
//                         fontSize: '.8em',
//                       }}>
//                   {errors.email}
//                 </Form.Control.Feedback>
//                 <Form.Control.Feedback type="valid">Be sure to write this down. Do not use personally identifiable information.</Form.Control.Feedback>
//               </Form.Group>
//             <Form.Group controlId="formCurrentPassword">
//             <Form.Label>Current Password:</Form.Label>
//               <Form.Control
//                 type="password"
//                 name="currentPassword"
//                 placeholder="********"
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//                 value={values.currentPassword}
//                 isInvalid={errors.currentPassword && touched.currentPassword}
//                 isValid={!errors.currentPassword && touched.currentPassword}
//               />
//               <Form.Control.Feedback type="invalid" style={{
//                       color: '#dc3545',
//                       fontSize: '.8em',
//                     }}>
//                 {errors.currentPassword}
//               </Form.Control.Feedback>
//             </Form.Group>
//             <Alert show={showSuccessEmail} variant="success">
//               <Alert.Heading>Email Updated</Alert.Heading>
//               <p>{'Your email has successfully been changed.'}</p>
//               <hr />
//               <div className="d-flex justify-content-end">
//                 <Button onClick={() => setShowSuccessEmail(false)} variant="outline-success">
//                   Dismiss
//                 </Button>
//               </div>
//             </Alert>
//             <Alert show={showErrorEmail} variant="danger">
//               <Alert.Heading>Failed to Update Email</Alert.Heading>
//               <p>{errorMessageEmail}</p>
//               <hr />
//               <div className="d-flex justify-content-end">
//                 <Button onClick={() => setShowErrorEmail(false)} variant="outline-danger">
//                   Dismiss
//                 </Button>
//               </div>
//             </Alert>
//             <Button className='pwt-button' disabled={isSubmitting} block bssize="large"  type="submit">
//               Update Email
//             </Button>
//             </LoadingOverlay>
//           </Form>
//         )}
//         </Formik>
//     </div>
//   );    
// }

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(AccountPage);
