import React, {useContext, useEffect, useState} from 'react';
import { compose } from 'recompose';

import { AuthUserContext, withAuthorization } from '../../components/Session';

import { Container } from 'react-bootstrap';

import UpdateTemperatureModule from '../../components/UpdateTemperature'

import { withFirebase } from '../../components/Firebase';

import { Helmet } from 'react-helmet-async';

function HomePage(props) {
  return (
    <Container style={{padding:0}}>
      <Helmet>
          <title>Home | PWT</title>
          <meta name="description" content="Update your temperature." />
      </Helmet>
      <HomePageBase></HomePageBase>
    </Container>           
    );  
}

function HomePageBase(props) {
  return (
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <GreetingModule></GreetingModule>
      <hr/>
      <h1>Update Your Temperature</h1>
      <p>You should update your temperature at least once each morning to help build an accurate temperature profile. Twice a day is optimal (morning and evening).</p>
      <UpdateTemperatureModule></UpdateTemperatureModule>
    </div>
  );
}

function GreetingModule(props){
  const userContext = useContext(AuthUserContext);
  const [greetingMessage, setGreetingMessage] = useState('');
  const [loading, setLoading] = useState(true);

  function getGreeting() {
    const HOUR_MORNING_MINIMUM = 4;
    const HOUR_MORNING_MAXIMUM = 12;
    const HOUR_AFTERNOON_MINIMUM = 12;
    const HOUR_AFTERNOON_MAXIMUM = 18;
    const HOUR_EVENING_MINIMUM = 18;
    const HOUR_EVENING_MAXIMUM = 24;
    const HOUR_NIGHT_MINIMUM = 0;
    const HOUR_NIGHT_MAXIMUM = 4;

    let currentDate = new Date();
    let hour = currentDate.getHours();
    let greeting = '';
    if ((hour >= HOUR_MORNING_MINIMUM) && (hour < HOUR_MORNING_MAXIMUM)) {
      greeting = 'Good morning';
    } else if ((hour >= HOUR_AFTERNOON_MINIMUM) && (hour < HOUR_AFTERNOON_MAXIMUM)) {
      greeting = 'Good afternoon';
    } else if (((hour >= HOUR_EVENING_MINIMUM) && (hour < HOUR_EVENING_MAXIMUM)) || ((hour >= HOUR_NIGHT_MINIMUM) && (hour < HOUR_NIGHT_MAXIMUM))) {
      greeting = 'Good evening';
    } else {
      greeting = 'Welcome';
    }
    return greeting;
  }

  

  useEffect(() => {
    function updateGreetingMessage() {
      setLoading(true);
      // props.firebase.user(props.firebase.auth.currentUser.uid)
      // var username = String(props.email).split('@')[0];
      var username = userContext.userDoc?.username != null ? userContext.userDoc.username : ""
      // var username = String(props.firebase.auth.currentUser.email).split('@')[0];
      // var username = String(firebase.auth().currentUser.email).split('@')[0];
      var fullGreeting = '';
      if (username != null) {
        fullGreeting = `${getGreeting()}, ${username}.`;
        setGreetingMessage(fullGreeting);
      } else {
        fullGreeting = `${getGreeting()}.`;
        setGreetingMessage(fullGreeting);
      }
      setLoading(false);
    }

    updateGreetingMessage();
  }, [userContext.userDoc]);

  function getGreeting() {
    const HOUR_MORNING_MINIMUM = 4;
    const HOUR_MORNING_MAXIMUM = 12;
    const HOUR_AFTERNOON_MINIMUM = 12;
    const HOUR_AFTERNOON_MAXIMUM = 18;
    const HOUR_EVENING_MINIMUM = 18;
    const HOUR_EVENING_MAXIMUM = 24;
    const HOUR_NIGHT_MINIMUM = 0;
    const HOUR_NIGHT_MAXIMUM = 4;

    let currentDate = new Date();
    let hour = currentDate.getHours();
    let greeting = '';
    if ((hour >= HOUR_MORNING_MINIMUM) && (hour < HOUR_MORNING_MAXIMUM)) {
      greeting = 'Good morning';
    } else if ((hour >= HOUR_AFTERNOON_MINIMUM) && (hour < HOUR_AFTERNOON_MAXIMUM)) {
      greeting = 'Good afternoon';
    } else if (((hour >= HOUR_EVENING_MINIMUM) && (hour < HOUR_EVENING_MAXIMUM)) || ((hour >= HOUR_NIGHT_MINIMUM) && (hour < HOUR_NIGHT_MAXIMUM))) {
      greeting = 'Good evening';
    } else {
      greeting = 'Welcome';
    }
    return greeting;
  }

  if (greetingMessage == '') { 
    return(
      <div style={{textAlign:'center'}}>
        <h2 style={{overflowWrap:'anywhere', wordWrap:'break-word'}}>{``}</h2>
      </div>
    );
  } else {
    return(
      <div style={{textAlign:'center'}}>
        <h2 style={{overflowWrap:'anywhere', wordWrap:'break-word'}}>{`${greetingMessage}`}</h2>
      </div>
    );
  }
  
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
)(HomePage);