import React, { useEffect, useState } from 'react';
import { withRouter, Link, useParams } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import { Alert, Button, Container, Form, FormGroup, Modal, Row, Col } from "react-bootstrap";

import { Formik } from 'formik';
import * as Yup from 'yup';

import { enforceLogin } from '../../components/Session';

import firebase from 'firebase/app';
import 'firebase/functions'
import LoadingOverlay from 'react-loading-overlay';

import { Helmet } from 'react-helmet-async';



const SignUpGroupPage = () => (
    <Container style={{padding:0}}>
        <Helmet>
          <title>Complete Group Registration | PWT</title>
          <meta name="description" content="Complete your PWT group registration."/>
          <meta name="robots" content="noindex"/>
        </Helmet> 
        <SignUpGroupBase></SignUpGroupBase>
    </Container>            
);



function SignUpGroupBase(props) {

  return(
          <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Start Your PWT Membership</h1>
      <h2>Complete Your Group Registration</h2>
      <SignUpGroupForm></SignUpGroupForm>
    </div>  
  );
}



function SignUpGroupModule(props) {
    function QueryURL() {
      let { code } = useParams();
      if (code == null) {
        code = ''
        // setCode(code)
        // props.history.push('/group/redeem');
      } else {
        // setCode(code)
      }
      return { code }
    }

    let query = QueryURL();
    
    // const [code, setCode] = useState(null)

    const [showError, setShowError] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [group, setGroup] = useState("");

    const [onboardingMessage, setOnboardingMessage] = useState(null);

    const [username, setUsername] = useState("");
    
    const [password, setPassword] = useState("");
  
    const [showCompleteModal, setShowCompleteModal] = useState(false);

    const handleClose = () => setShowCompleteModal(false);
    const handleShow = () => setShowCompleteModal(true);

    const validationSchema = Yup.object().shape({
      firstInitial: Yup.string()
        .required("First initial is required.")
        .max(1, "Your initial must be at most 1 character long."),
      lastName: Yup.string()
        .required("Last name is required.")
        .max(50, "Your last name must be at most 50 characters long."),
      password: Yup.string()
        .min(6,"Password must be at least 6 characters.")   
        .required("Password is required."),
      passwordConfirm: Yup.string()
        .required("Please confirm the password.")
        .label('Confirm password')
        .test('passwords-match', 'Passwords must match.', function(value) {
          return this.parent.password === value;
        }),
      code: Yup.string()
      .required("Access Code is required")
      .label('Access Code'),
      acceptTerms: Yup.bool()
      .oneOf([true], 'Please read and accept the ToS.'),
    });


    function CompleteSignIn() {
      setShowCompleteModal(false);
      props.firebase
      .doSignInWithEmailAndPassword(`${username}` + '@warningtemperature.health', password)
      .catch(error => {
       setErrorMessage(String('Failed to sign in and verify your status.'));
       setShowError(true);
     });
   }


    useEffect(() => {
      async function setupGroup() {
        if (query != null) {
          if (query.code == "") {
            props.history.push('/group/redeem');
          }
        }
      }
      
      setupGroup();
    }, [query.code]); 

    return(
      <div>       
        <Formik
          initialValues={{ groupName: query.code, firstInitial: "", lastName: "",password:"", passwordConfirm:"", code:query.code, acceptTerms:false}}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            setShowError(false);
            setSubmitting(true);
            var addMessage = firebase.functions().httpsCallable('registerUserWithGroupActivationCode');
            addMessage({firstInitial: values.firstInitial, lastName: values.lastName, userPassword: values.password, code: values.code})
            .then(function(result) {
              props.firebase.doLogSignUp(`Group Access Code (${values.code})`);
              setSubmitting(false);
              setUsername(result.data.username);
              setGroup(result.data.group);
              setOnboardingMessage(result.data.onboarding_message)
              setPassword(values.password)
              setShowCompleteModal(true);
            })
            .catch(function(error) {
              setSubmitting(false);
              setErrorMessage(error.message);
              setShowError(true);
            });
          }}
        >
        {( {values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            <LoadingOverlay
              active={isSubmitting}
              spinner
              text='Signing up...'
              styles={{
                overlay: (base) => ({
                  ...base,
                  borderRadius:"12.5px"
                })
              }}
            >
            <Row>
              <Col>
                {/* <Form.Group controlId="formUsername">
                <Form.Label>Email:</Form.Label>
                  <Form.Control
                    type="text"
                    name="emailAddress"
                    autoComplete={"username"}
                    placeholder="john@example.com"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emailAddress}
                    isInvalid={errors.emailAddress && touched.emailAddress}
                    isValid={!errors.emailAddress && touched.emailAddress}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.emailAddress}
                  </Form.Control.Feedback>
                </Form.Group> */}
                <Form.Group controlId="formGroupName">
                <Form.Label>Access Code:</Form.Label>
                  <Form.Control
                    type="text"
                    name="groupName"
                    disabled={true}
                    style={{textTransform:'uppercase'}}
                    // autoComplete={"username"}
                    // placeholder="john@example.com"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.groupName}
                    isInvalid={errors.groupName && touched.groupName}
                    isValid={!errors.groupName && touched.groupName}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.groupName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formFirstName">
                  <Form.Label>First Initial:</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstInitial"
                    autoComplete={"firstInitial"}
                    placeholder="J"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstInitial}
                    isInvalid={errors.firstInitial && touched.firstInitial}
                    isValid={!errors.firstInitial && touched.firstInitial}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.firstInitial}
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback type="valid">Password meets requirements.</Form.Control.Feedback> */}
                </Form.Group>
              </Col>
                <Col>
                <Form.Group controlId="formLastName">
                  <Form.Label>Last Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    autoComplete={"lastName"}
                    placeholder="Smith"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    isInvalid={errors.lastName && touched.lastName}
                    isValid={!errors.lastName && touched.lastName}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                  }}>
                  {errors.lastName}
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback type="valid">Password meets requirements.</Form.Control.Feedback> */}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formPassword">
                <Form.Label>Password:</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    autoComplete={"new-password"}
                    placeholder="********"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={errors.password && touched.password}
                    isValid={!errors.password && touched.password}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.password}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid">Password meets requirements.</Form.Control.Feedback>
                </Form.Group>
                </Col>
                <Col>
                <Form.Group controlId="formPasswordConfirm">
                <Form.Label>Confirm:</Form.Label>
                  <Form.Control
                    type="password"
                    name="passwordConfirm"
                    autoComplete={"new-password"}
                    placeholder="********"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirm}
                    isInvalid={errors.passwordConfirm && touched.passwordConfirm}
                    isValid={!errors.passwordConfirm && touched.passwordConfirm}
                  />
                  <Form.Control.Feedback style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }} type="invalid">
                    {errors.passwordConfirm}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid">Passwords match.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <FormGroup controlId="agreeToTerms">
              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                  <Form.Check 
                  label={`I agree with the`} name="acceptTerms" value={values.acceptTerms} onChange={handleChange}></Form.Check>
                  <a href='/tos' target='_blank' rel="noopener noreferrer">Terms of Service</a> 
                  {errors.acceptTerms && touched.acceptTerms ? (
                    <div style={{
                      color: '#dc3545',
                      // padding: '.5em .2em',
                      // height: '1em',
                      fontSize: '.8em',
                    }}>{errors.acceptTerms}</div>
                  ): <div style={{
                    color: '#dc3545',
                    // padding: '.5em .2em',
                    // height: '1em',
                    fontSize: '.8em',
                  }}><br/></div>}   
                </div>
            </FormGroup>
            <Alert show={showError} variant="danger">
            <Alert.Heading>Error Signing Up</Alert.Heading>
              <p>{errorMessage}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShowError(false)} variant="outline-danger">
                  Dismiss
                </Button>
              </div>
            </Alert>
            <Button className='pwt-button' block size="lg"  type="submit" disabled={isSubmitting}>
              Sign Up
            </Button>
            </LoadingOverlay>
          </Form>
            )}
        </Formik>
        <Modal
                show={showCompleteModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
              >
                <Modal.Header closeButton={false}>
                  <Modal.Title>Welcome to PWT!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <>
                <p>You've joined PWT on behalf of: <b>{group}</b>.</p>
                <p>Your username is: <b>{username}</b>. Please write this down as you will need your username to sign in.</p>
                {onboardingMessage != null &&
                <p><b>Message from {group}</b>: "{onboardingMessage}"</p>
                }
                <hr/>
                <p>For any support needs, please give PWT customer support a call at <b>888-997-0798</b>. If a representative is unavailable, please leave a voicemail and we will reach back out to you as soon as possible.</p>

                </>
                </Modal.Body>
                <Modal.Footer>
                  {/* <Button className='pwt-button-secondary' onClick={handleClose}>
                    Cancel
                  </Button> */}
                  <Button className='pwt-button' onClick={() => CompleteSignIn()}>Continue to PWT</Button>
                </Modal.Footer>
              </Modal>
      </div>         
  );
}


const SignUpGroupForm = compose(
  withRouter,
  withFirebase,
)(SignUpGroupModule);

export { SignUpGroupForm };


const condition = authUser => !!authUser;

export default enforceLogin(condition)(SignUpGroupPage);