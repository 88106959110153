import React, {useContext, useEffect, useState} from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

import { Button, Col, Modal, Row } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';

import SwiperCore, { Navigation, Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Pagination]);

var QRCode = require('qrcode.react');


function GreenScreenBannerMemberModuleBase(props) {
    let user = props.user
    let flagCallback = props.flagCallback;

    const [showBusy, setShowBusy] = useState(true);

    const [assessmentData, setAssessmentData] = useState([]);

    const [banners, setBanners] = useState([])

    const [show, setShow] = useState(false);

    const [url, setUrl] = useState('');
    const [colorQr, setColorQr] = useState('green');
    const [messageQr, setMessageQr] = useState('PASS');
    const [showQr, setShowQr] = useState('flex');

    const [timestamp, setTimestamp] = useState();

    const handleClose = () => setShow(false);

    function displayBannerCode(data) {
        setShow(true);
    }

    function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = '' + d.getFullYear(),
        hour = '' + d.getHours(),
        minutes = '' + d.getMinutes(),
        seconds = '' +  d.getSeconds();

    var timeSuffix = 'AM'
    if (hour === String(12)) {
        timeSuffix = 'PM'
    } else if (hour > 12) {
        hour = hour - 12;
        timeSuffix = 'PM'
    } else if (hour === String(0)) {
        hour = 12;
    }


    if (day.length < 2) 
        day = '0' + day;

    if (hour.length < 2) {
        hour = '0' + hour;
    }
    if (minutes.length < 2) {
        minutes = '0' + minutes;
    }
    if (seconds.length < 2) {
        seconds = '0' + seconds;
    }

    var formattedString = `${month}/${day}/${year}\n${hour}:${minutes}:${seconds} ${timeSuffix}`;
    return formattedString;
    }

    function setNoDataStates() {
        setBanners([]);
    }

    function generateBanners() {
        let pwtLastUpdate = assessmentData[0];
        let joinedGroups = user.joined_groups;
        if (joinedGroups != null){
            joinedGroups.forEach(group => {
                props.firebase.group(group).get()
                .then(function(doc) {
                    // Group information
                    let groupName = doc.data()?.public_name != null ? doc.data()?.public_name : '';
                    let groupPassMessage = doc.data()?.pass_message != null ? doc.data()?.pass_message : 'Safe to go to work!'
                    let groupFailMessage = doc.data()?.fail_message != null ? doc.data()?.fail_message : 'Contact your manager!'
                    let groupExpireMessage = doc.data()?.expire_message != null ? doc.data()?.expire_message : 'Please update your temperature!'
                    let message = '';
                    // Group rules
                    let allowExtensions = doc.data()?.allow_extensions != null ? doc.data()?.allow_extensions : false;

                    // User's data
                    let tempStatusTitle = '';
                    let tempStatusMessage = '';
                    let tempStatus = '';
                    let tempChartUnit = '';
                    let tempLastUpdateDate: Date;
                    let tempLastUpdateDateString = '';
                    let tempBannerExpirationDate: Date;
                    let tempBannerExpirationDateString = ''
                    let tempExtensionDate: Date;
                    let tempExtensionDateString = ''
                    let tempBannerType = '';

                    if (pwtLastUpdate != null) {
                        tempStatusTitle = pwtLastUpdate.health_assessment.title;
                        tempStatusMessage = pwtLastUpdate.health_assessment.message;
                        tempStatus = pwtLastUpdate.health_assessment.status;
                        tempLastUpdateDate = new Date((pwtLastUpdate.timestamp.seconds * 1000));

                        if (allowExtensions == true){
                            let extension = pwtLastUpdate.extension;
                            if (extension != null) {
                                tempLastUpdateDate = new Date((extension.seconds * 1000));
                            }
                        }
                        tempLastUpdateDateString = tempLastUpdateDate.toLocaleString(undefined, { timeZoneName: 'short' });

                        
                        // Banner time logic
                        tempBannerExpirationDate = new Date(tempLastUpdateDate.getTime() + (12 * 60 * 60 * 1000));
                        tempBannerExpirationDateString = tempBannerExpirationDate.toLocaleString(undefined, { timeZoneName: 'short' });
                        // Banner
                        if (tempStatus === 'Red' || tempStatus === 'Orange') {
                            tempBannerType = 'warning';
                            message = groupFailMessage;
                        } else if (new Date().getTime() >= tempBannerExpirationDate.getTime()) {
                            tempBannerType = 'expired';
                            message = groupExpireMessage;
                        } else {  // User is at the very least not red or orange, let's check symptoms if they have any
                            if (pwtLastUpdate.symptoms != null) {
                                if (pwtLastUpdate.symptoms.length > 0) {
                                    tempBannerType = 'warning';
                                    message = groupFailMessage;
                                } else {
                                    tempBannerType = 'pass';
                                    message = groupPassMessage;
                                } 
                            } else {
                                tempBannerType = 'pass';
                                message = groupPassMessage;
                            }
                        }
                        // let groupBanner = {groupName : groupName, groupID: group, result: tempBannerType, lastUpdate: tempLastUpdateDateString, expiration: tempBannerExpirationDateString, message: message}
                        // let tempBanners = banners;
                        // tempBanners.push(groupBanner);
                        // setBanners([...tempBanners])
                    } else {
                        tempBannerType = 'expired';
                        message = groupExpireMessage;
                        // setBanners([]);
                    }
                    let groupBanner = {groupName : groupName, groupID: group, result: tempBannerType, lastUpdate: tempLastUpdateDateString, expiration: tempBannerExpirationDateString, message: message}
                    let tempBanners = banners;
                    tempBanners.push(groupBanner);
                    setBanners([...tempBanners])
                })
            }
        );
        } else {
            setBanners([]);
        }
    }

    function AssessmentBanner(props) {
    if (props.banner.result === 'pass') {
        return (
        <div style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}>
            <div style={{display:`block`, paddingLeft:30, paddingRight:30, borderRadius:"25px", border:`2px solid green`, backgroundColor:`green`}}>
                <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                    <p style={{color:'white', fontSize: '1.8rem'}}>{props.banner.message}</p>
                    <p style={{color:'white', fontSize: '1.4rem'}}>{`${props.banner.groupName} (${props.banner.groupID})`}</p>
                </div>
                <hr style={{color:'white', backgroundColor:'white'}}/>
                <p style={{color:'white'}}>{`Based on your group's policy, you are safe to proceed to your designated place. Share this screen to prove your status.`}</p>
                <p style={{color:'white'}}>{`Valid Until: ${props.banner.expiration}`}</p>
            </div>  
        </div>
        );
    } else if (props.banner.result === 'expired') {
        return (
            <div style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}>
                <div style={{display:`block`, paddingLeft:30, paddingRight:30, borderRadius:"25px", border:`2px solid gold`, backgroundColor:`gold`, height:'100%'}}>
                    <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                            <p style={{color:'black', fontSize: '1.8rem'}}>{props.banner.message}</p>
                            <p style={{color:'black', fontSize: '1.4rem'}}>{`${props.banner.groupName} (${props.banner.groupID})`}</p>
                        </div>
                        <hr style={{color:'black', backgroundColor:'black'}}/>
                        <p style={{color:'black'}}>{`A new temperature update is required to assess your group policy compliance.`}</p>
                        { (props.banner.expiration != '')
                        ?
                        <p style={{color:'black'}}>{`Last update recorded: ${props.banner.lastUpdate}`}</p>
                        :
                        <p style={{color:'black'}}>{`No updates received.`}</p>
                        }
                </div>
        </div>
        );
    } else if (props.banner.result === 'warning') {
        return (
            <div style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}>
                <div style={{display:`block`, paddingLeft:30, paddingRight:30, borderRadius:"25px",  border:`2px solid gold`, backgroundColor:`gold`}}>
                    <div>
                        <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                            <p style={{color:'black', fontSize: '1.8rem'}}>{props.banner.message}</p>
                            <p style={{color:'black', fontSize: '1.4rem'}}>{`${props.banner.groupName} (${props.banner.groupID})`}</p>
                        </div>
                        <hr style={{color:'black', backgroundColor:'black'}}/>
                        <p style={{color:'black'}}>{`Based on your group's policy, you should contact your manager to discuss your next steps.`}</p>
                        <p style={{color:'black'}}>{`Valid Until: ${props.banner.expiration}`}</p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            null
        );
    }
  }

  function updateBanner() {
    setNoDataStates();
    generateBanners();
    setShowBusy(false); 
  }


  useEffect(() => {
    setShowBusy(true);
    // Fetch last temperature entry doc
    var entry = [];
    props.firebase.temperature_data(user.uid)
    .orderBy("timestamp", 'desc')
    .limit(1)
    .get()
    .then((temperature_docs) => {
      let docs = temperature_docs.docs;
      if (docs.length > 0) {
        let doc = docs[0];
        entry.push(doc.data());
      }
      setAssessmentData(entry);
    })
    .catch((error) => {
      console.log(error);
      setAssessmentData(entry);
    })
  }, [user]);
  
  useEffect(() => { 
    async function loadPlottingData() {
        setShowBusy(true);
        updateBanner();  
    }

    loadPlottingData();
  }, [assessmentData]);
  
  if (banners.length > 1) {
    return (
        <LoadingOverlay
                  active={showBusy}
                  spinner
                  text=''
                  styles={{
                    overlay: (base) => ({
                      ...base,
                    })
                  }}
          >
          <Swiper
          spaceBetween={50}
          slidesPerView={1}
          centeredSlides = {true}
          navigation
          pagination={{ clickable: true }}
          >
              {banners.map((banner, element) => {
                  return <SwiperSlide key={element}><AssessmentBanner banner={banner}></AssessmentBanner></SwiperSlide>;
              })}
          </Swiper> 
      </LoadingOverlay> 
      );
} else if (banners.length == 1) {
        return (
            <LoadingOverlay
                      active={showBusy}
                      spinner
                      text=''
                      styles={{
                        overlay: (base) => ({
                          ...base,
                        })
                      }}
              >
              <AssessmentBanner banner={banners[0]}></AssessmentBanner>
            </LoadingOverlay> 
        );
    } else {
        return (
           null              
        );
    }
    
  
}

const GreenScreenBannerMemberModule = compose(
  withFirebase,
)(GreenScreenBannerMemberModuleBase);

export { GreenScreenBannerMemberModule };
