import React, { useEffect } from 'react';
import { compose } from 'recompose';

import { withRouter } from "react-router";
import { withFirebase } from '../Firebase';



function FirebaseAnalytics(props) {
    useEffect(() => {
        const logCurrentPage = () => {
            const url = window.location.pathname;
            if (url.toLowerCase().indexOf("localhost") !== -1) {
                props.firebase.doLogPageVisit(window.location.pathname);
            }
        };
        logCurrentPage(); 
        props.history.listen(() => {
            logCurrentPage();
        });
    }, [props.history]);
    return null;
}


export default compose(
    withRouter,
    withFirebase
)(FirebaseAnalytics)
  