import React, { useEffect, useState } from 'react';
import { withRouter, Link, useParams } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import { Alert, Button, Container, Form, FormGroup, Modal, Row, Col } from "react-bootstrap";

import { Formik } from 'formik';
import * as Yup from 'yup';

import { enforceLogin } from '../../components/Session';

import firebase from 'firebase/app';
import 'firebase/functions'
import LoadingOverlay from 'react-loading-overlay';

import { Helmet } from 'react-helmet-async';



const SignUpTrialPage = () => (
    <Container style={{padding:0}}>
        <Helmet>
          <title>Start Trial | PWT</title>
          <meta name="description" content="Complete your PWT trial registration."/>
          <meta name="robots" content="noindex"/>
        </Helmet> 
        <SignUpTrialBase></SignUpTrialBase>
    </Container>            
);



function SignUpTrialBase(props) {

  return(
          <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Start Your PWT Trial</h1>
      <h2>Complete Your Registration</h2>
      <SignUpTrialForm></SignUpTrialForm>
    </div>  
  );
}

function SignUpTrialModule(props) {
    function QueryURL() {
      let { promo } = useParams();
      if (promo == null) {
        promo = ''
        // props.history.push('/trial/redeem');
      }
      return { promo }
    }

    let query = QueryURL();

    const [showError, setShowError] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
  
    const validationSchema = Yup.object().shape({
      emailAddress: Yup.string()
      .required("Email is required.")
      .email("Must be a valid email address."),
      name: Yup.string()
      .max(50),
      // .required("Name is required."),
      organization: Yup.string()
      // .required("Organization is required.")
      .max(50),
      password: Yup.string()
      .min(6,"Password must be at least 6 characters.")   
      .required("Password is required."),
      passwordConfirm: Yup.string()
        .required("Please confirm the password.")
        .label('Confirm password')
        .test('passwords-match', 'Passwords must match.', function(value) {
          return this.parent.password === value;
        }),
      promoCode: Yup.string()
      .required("Promo Code is required")
      .label('Promo Code'),
      acceptTerms: Yup.bool()
      .oneOf([true], 'Please read and accept the ToS.'),
    });


    useEffect(() => {
      async function setupPromo() {
        if (query != null) {
          if (query.promo == "") {
            props.history.push('/trial/redeem');
          }
        }
      }
      
      setupPromo();
    }, [query.code]); 

    return(
      <div>       
        <Formik
          initialValues={{ emailAddress:"", name: "", organization: "", password:"", passwordConfirm:"", promoCode:query.promo, acceptTerms:false}}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            setShowError(false);
            setSubmitting(true);
            var addMessage = firebase.functions().httpsCallable('registerUserWithPromo');
            addMessage({userEmail: values.emailAddress, userPassword: values.password, promoCode: values.promoCode, name: values.name, organization: values.organization})
            .then(function(result) {
              props.firebase.doLogSignUp(`Promo Code (${values.promoCode})`);
              props.firebase
              .doSignInWithEmailAndPassword(values.emailAddress, values.password)
              .catch(error => {
                setErrorMessage(String('Failed to sign in.'));
                setShowError(true);
              });
            })
            .catch(function(error) {
              setSubmitting(false);
              setErrorMessage(error.message);
              setShowError(true);
            });
          }}
        >
        {( {values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            <LoadingOverlay
              active={isSubmitting}
              spinner
              text='Signing up...'
              styles={{
                overlay: (base) => ({
                  ...base,
                  borderRadius:"12.5px"
                })
              }}
            >
            <Row>
              <Col>
                <Form.Group controlId="formUsername">
                <Form.Label>Email:</Form.Label>
                  <Form.Control
                    type="text"
                    name="emailAddress"
                    autoComplete={"username"}
                    placeholder="john@example.com"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={errors.emailAddress && touched.emailAddress}
                    isValid={!errors.emailAddress && touched.emailAddress}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.emailAddress}
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback type="valid">Be sure to write this down. Do not use personally identifiable information.</Form.Control.Feedback> */}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formName">
                <Form.Label>Name (optional):</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="John Smith"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    isInvalid={errors.name && touched.name}
                    isValid={!errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.name}
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback type="valid">Password meets requirements.</Form.Control.Feedback> */}
                </Form.Group>
                </Col>
                <Col>
                <Form.Group controlId="formOrganization">
                <Form.Label>Organization (optional):</Form.Label>
                  <Form.Control
                    type="text"
                    name="organization"
                    placeholder="Your Company"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.organization}
                    isInvalid={errors.organization && touched.organization}
                    isValid={!errors.organization && touched.organization}
                  />
                  <Form.Control.Feedback style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }} type="invalid">
                    {errors.organization}
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback type="valid">Passwords match.</Form.Control.Feedback> */}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formPassword">
                <Form.Label>Password:</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    autoComplete={"new-password"}
                    placeholder="********"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={errors.password && touched.password}
                    isValid={!errors.password && touched.password}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.password}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid">Password meets requirements.</Form.Control.Feedback>
                </Form.Group>
                </Col>
                <Col>
                <Form.Group controlId="formPasswordConfirm">
                <Form.Label>Confirm:</Form.Label>
                  <Form.Control
                    type="password"
                    name="passwordConfirm"
                    autoComplete={"new-password"}
                    placeholder="********"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirm}
                    isInvalid={errors.passwordConfirm && touched.passwordConfirm}
                    isValid={!errors.passwordConfirm && touched.passwordConfirm}
                  />
                  <Form.Control.Feedback style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }} type="invalid">
                    {errors.passwordConfirm}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid">Passwords match.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <FormGroup controlId="agreeToTerms">
              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                  <Form.Check 
                  label={`I agree with the`} name="acceptTerms" value={values.acceptTerms} onChange={handleChange}></Form.Check>
                  <a href='/tos' target='_blank' rel="noopener noreferrer">Terms of Service</a> 
                  {errors.acceptTerms && touched.acceptTerms ? (
                    <div style={{
                      color: '#dc3545',
                      // padding: '.5em .2em',
                      // height: '1em',
                      fontSize: '.8em',
                    }}>{errors.acceptTerms}</div>
                  ): <div style={{
                    color: '#dc3545',
                    // padding: '.5em .2em',
                    // height: '1em',
                    fontSize: '.8em',
                  }}><br/></div>}   
                </div>
            </FormGroup>
            <Alert show={showError} variant="danger">
            <Alert.Heading>Error Signing Up</Alert.Heading>
              <p>{errorMessage}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShowError(false)} variant="outline-danger">
                  Dismiss
                </Button>
              </div>
            </Alert>
            <Button className='pwt-button' block size="lg"  type="submit" disabled={isSubmitting}>
              Start Trial
            </Button>
            </LoadingOverlay>
          </Form>
            )}
        </Formik>
      </div>         
  );
}


const SignUpTrialForm = compose(
  withRouter,
  withFirebase,
)(SignUpTrialModule);

export { SignUpTrialForm };


const condition = authUser => !!authUser;

export default enforceLogin(condition)(SignUpTrialPage);