import React, { useEffect, useState } from 'react';

import { withAuthorization } from '../../components/Session';

import { Alert, Button, Container, ListGroup } from "react-bootstrap";

import firebase from 'firebase/app';
import LoadingOverlay from 'react-loading-overlay';
import { Helmet } from 'react-helmet-async';

function GroupPage(props) {
  return(
    <Container style={{padding:0}}>
      <Helmet>
          <title>Groups | PWT</title>
          <meta name="description" content="View your groups." />
      </Helmet>   
      <GroupPageBase></GroupPageBase>
    </Container>               
  );
}



function GroupPageBase(props) {
  
  return(
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Manage Groups</h1>
      <GroupsModule></GroupsModule>
    </div>   
  );    
}

function GroupsModule() {
  const [showError, setShowError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showBusy, setShowBusy] = useState(false);

  const [groups, setGroups] = useState([]);
  

  useEffect(() => {
    function fetchGroups() {
      setShowBusy(true);
      setShowError(false);
      setShowBusy(true);
      var addMessage = firebase.functions().httpsCallable('fetchAccountGroups');
      addMessage()
      .then(function(result) {
        let groupsObject = [];
        let joined_groups = result.data.joined_groups;
        joined_groups.sort((a, b) => (a.public_name > b.public_name) ? 1 : (a.public_name === b.public_name) ? ((a.name > b.name) ? 1 : -1) : -1 )
        let numberOfGroups = joined_groups.length;
        for (let groupCount = 0; groupCount < numberOfGroups; groupCount++) {
          groupsObject.push({name: joined_groups[groupCount].name, public_name: joined_groups[groupCount].public_name, is_manager: false})
          let managers = joined_groups[groupCount].roles?.managers;
          if (managers != null) {
            let numberOfManagers = managers.length;
            for (let managerCount = 0; managerCount < numberOfManagers; managerCount++) {
              if (firebase.auth().currentUser.uid === managers[managerCount]) {
                groupsObject[groupCount].is_manager = true;
              }
            }
          }
        }
        setGroups(groupsObject);
        setShowBusy(false);
      })
      .catch(function(error) {
        if (error.code === 'internal') {
          setErrorMessage('Unable to communicate with the PWT servers. Check your internet connection and try again.');
        } else {
          setErrorMessage(error.message);
        }
        setShowBusy(false);
        setShowError(true);
      });
    }
    fetchGroups();
  }, []);



    return(
      <LoadingOverlay
      active={showBusy}
      spinner
      text='Loading group data...'
      styles={{
        overlay: (base) => ({
          ...base,
        })
      }}
      >
      <div>       
        <div>
          <h2>Groups You're In</h2>
          <p>Managers of these groups can see general information about your temperature data such as when your updates are submitted and if you have an elevated temperature. <b>Exact temperatures and other health metrics are not shared</b>.</p>
          <ListGroup>
            {groups.map(listitem => (
              <ListGroup.Item key={listitem.name}>
                {(listitem.is_manager) 
                ?
                  <>
                  {listitem.public_name} ({listitem.name})
                  {<br/>}
                  {<a href={`https://app.warningtemperature.health/groups/view/${listitem.name}`}>View Management Report</a>}
                  </>
                :
                  <>
                  {listitem.public_name} ({listitem.name})
                  </>
                }
                
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
       
          <Alert show={showError} variant="danger">
          <div style={{paddingTop:20}}>
              <Alert.Heading>Error Fetching Groups</Alert.Heading>
              <p>{errorMessage}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShowError(false)} variant="outline-danger">
                  Dismiss
                </Button>
              </div>
          </div>
          </Alert>
    </div>
    </LoadingOverlay>
  ); 
  
}
  
const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(GroupPage);
