import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import SignUpAnonymousPage from '../../pages/SignUpAnonymous';
import SignUpAnonymousKitPage from '../../pages/SignUpAnonymousKit';
import SignUpTrialPage from '../../pages/SignUpTrial';
import RedeemTrialPage from '../../pages/RedeemTrial';
import RedeemGroupPage from '../../pages/RedeemGroup';
import SignUpGroupPage from '../../pages/SignUpGroup';

import SignInAnonymousPage from '../../pages/SignInAnonymous';
import PasswordForgetPage from '../../pages/ResetPassword';
import HomePage from '../../pages/Home';
import AccountPage from '../../pages/Account';
import GroupsPage from '../../pages/Groups';
import GroupViewPage from '../../pages/GroupView';
import GroupAdminPage from '../../pages/GroupAdmin';
import VerifyPage from '../../pages/Verify';
import VerifyViewPage from '../../pages/VerifyView';
import SharePage from '../../pages/Share';
import ShareViewPage from '../../pages/ShareView';
import AdminPage from '../../pages/Admin';
import TemperatureProfile from '../../pages/TemperaturePofile';
import NotFoundPage from '../../pages/404';
import AccountImportPage from '../../pages/TemperatureTrackImport';
import EditHistoryPage from '../../pages/History';
import ToSPage from '../../pages/ToS';
import PrivacyPolicyPage from '../../pages/PrivacyPolicy';
import AboutUsPage from '../../pages/AboutUs';

import * as ROUTES from '../../constants/routes';

import { useTransition, animated } from 'react-spring'

function MainSwitch(props) {
  const location = useLocation()
  const transitions = useTransition(location, location => location.pathname, {
    from: {
      opacity: 0,
      position: 'absolute',
      width: '100%',
    },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%, 0, 0)' }
  });


  return transitions.map(({ item, props, key }) => (
    <animated.div key={key} style={props}>
      <Switch location={item}>
        <Route exact path={ROUTES.SIGN_UP_ANONYMOUS} component={SignUpAnonymousPage} />
        <Route exact path={ROUTES.SIGN_UP_ANONYMOUS_KIT} component={SignUpAnonymousKitPage} />
        <Route exact path={ROUTES.REDEEM_TRIAL} component={RedeemTrialPage} />
        <Route exact path={ROUTES.SIGN_UP_TRIAL} component={SignUpTrialPage} />

        <Route exact path={ROUTES.REDEEM_GROUP} component={RedeemGroupPage} />
        <Route exact path={ROUTES.SIGN_UP_GROUP} component={SignUpGroupPage} />


        <Route exact path={ROUTES.SIGN_IN_ANONYMOUS} component={SignInAnonymousPage} />
        <Route exact path={ROUTES.RESET_PASSWORD} component={PasswordForgetPage} />
        <Route exact path={ROUTES.HOME} component={HomePage} />

        <Route exact path={ROUTES.PROFILE} component={TemperatureProfile} />
        <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route exact path={ROUTES.GROUPS} component={GroupsPage} />
        
        <Route exact path={ROUTES.GROUPS_VIEW} component={GroupViewPage} />

        <Route exact path={ROUTES.GROUPS_ADMIN} component={GroupAdminPage} />

        <Route exact path={ROUTES.ADMIN} component={AdminPage} />
        <Route exact path={ROUTES.ACCOUNT_IMPORT} component={AccountImportPage} />
        <Route exact path={ROUTES.VERIFY} component={VerifyPage} />
        <Route exact path={ROUTES.VERIFY_VIEW} component={VerifyViewPage} />
        <Route exact path={ROUTES.SHARE} component={SharePage} />
        <Route exact path={ROUTES.SHARE_VIEW} component={ShareViewPage} />
        <Route exact path={ROUTES.HISTORY} component={EditHistoryPage} />
        <Route exact path={ROUTES.TOS} component={ToSPage} />
        <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicyPage} />

        <Route exact path={ROUTES.ABOUT_US} component={AboutUsPage} />

        <Route component={NotFoundPage} />
      </Switch>
    </animated.div>
  ))
}

export default MainSwitch;
