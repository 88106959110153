import React, { useEffect, useState } from 'react';

import { withAuthorization } from '../../components/Session';

import { Alert, Button, Container, Form } from "react-bootstrap";

import firebase from 'firebase/app';
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/auth'
import LoadingOverlay from 'react-loading-overlay';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';

var QRCode = require('qrcode.react');

function VerifyPage(props) {
  return(
    <Container style={{padding:0}}>
      <Helmet>
        <title>Temperature Verification | PWT</title>
        <meta name="description" content="Prove a temperature measurement is below your personal warning temperature without revealing your private temperature information." />
      </Helmet>
      <VerifyPageBase></VerifyPageBase>
    </Container>               
  );
}

const validationSchema = Yup.object().shape({
  temperature: Yup.number()
  
  .test('passwords-match', 'Temperature must be in range (35 - 40 ℃ or 95 - 104 ℉).', function(value) {
    if ((value >= 35 && value <= 40) || (value >= 95 && value <= 104)) {
      return true;
    } else {
      return false;
    }
  })
  .required("Temperature is required."),
});

function VerifyPageBase(props) {
    
  return(
            <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
        <h1>Verify Temperature</h1>
        <p>Use this feature to prove a temperature measurement is below your personal warning temperature without revealing your private temperature information.</p> 
        <VerificationModule></VerificationModule>
      </div>   
    );
}


function VerificationModule(props) {
  const [showError, setShowError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showBusy, setShowBusy] = useState(false);

  const [url, setUrl] = useState('');
  const [colorQr, setColorQr] = useState('red');
  const [messageQr, setMessageQr] = useState('FAIL');
  const [showQr, setShowQr] = useState('none');

  const [timeLeft, setTimeLeft] = useState(0);
  const [expiration, setExpiration] = useState();
  const [timestamp, setTimestamp] = useState();

  const [measuredTemperature, setMeasuredTemperature] = useState(0);
  const [measuredTemperatureUnit, setMeasuredTemperatureUnit] = useState('℉');
  
  const [verificationLinks, setVerificationLinks] = useState([])

  

  
  async function PrepQrCode(secret) {
    setShowError(false);
    setShowBusy(true);
    setUrl('');
    setShowQr('none');
    var addMessage = firebase.functions().httpsCallable('checkVerification');
    addMessage({secret: secret})
    .then(function(result) {
      let didPass = result.data.result;
      let timestamp = new Date(result.data.timestamp._seconds * 1000).toLocaleString();
      setTimestamp(timestamp)
      let expirationString = new Date(result.data.expiration._seconds * 1000).toLocaleString();
      setExpiration(expirationString);
      let timeLeft = Math.round((new Date(expirationString) - Date.now()) / 1000)
      if (timeLeft < 0) {
        timeLeft = 0;
      }
      setTimeLeft(timeLeft);
      setUrl(`https://app.warningtemperature.health/verify/view/${secret}`);
      if (timeLeft > 0) {
        if (didPass) {
          setColorQr('green');
          setMessageQr('PASS');
        } else {
          setColorQr('red');
          setMessageQr('FAIL');
        }
      } else {
        setColorQr('red');
        setMessageQr('EXPIRED');
      }
      if (result.data.unit === 'fahrenheit') {
        setMeasuredTemperatureUnit('℉')
      } else {
        setMeasuredTemperatureUnit('℃')
      }
      setMeasuredTemperature(result.data.test_temperature);
      setShowQr('flex');
      setShowBusy(false);
    })
    .catch(function(error) {
      setErrorMessage(error.message);
      setShowBusy(false);
      setShowError(true);
    });


  }

  async function StartOver() {
    setShowError(false);
    setShowQr('none');
    setUrl('');
    setColorQr('red');
    setMessageQr('FAIL');
    setTimeLeft(0);
  }

  useEffect(() => {
    function assignDatabaseListener() {
      let unsubscribe = firebase.firestore().doc('users/'+firebase.auth().currentUser.uid)
      .onSnapshot(function(querySnapshot) {
        setShowBusy(true);
          var entries = [];
          entries  = (querySnapshot.data().verification_secrets);
          if (entries != null) {
            setVerificationLinks(entries);
            if (entries.length > 0) {
              PrepQrCode(entries[entries.length - 1]);
            } else {
              setShowBusy(false);
            }
          } else {
            setShowBusy(false);
          }
      });
      return unsubscribe;
    }

    return assignDatabaseListener();
  }, []);

  useEffect(() => {    
    // exit early when we reach 0
    if (!timeLeft) { 
      setColorQr('red');
      setMessageQr('EXPIRED');
      return;

    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      let timeLeft = Math.round((new Date(expiration) - Date.now()) / 1000)
      if (timeLeft < 0) {
        timeLeft = 0;
      }
      setTimeLeft(timeLeft);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]); // eslint-disable-line react-hooks/exhaustive-deps

    if (showQr === 'flex') {
      return(
        <div>       
          <h2>Verification Report</h2>
          <p><b>Created</b>: {timestamp}</p>
          <p><b>Expiration</b>: {expiration}</p>
          {(messageQr !== 'EXPIRED')
                ? <>
                    <h4><b>Measured Temperature</b>: {measuredTemperature} {measuredTemperatureUnit}</h4>
                  </>
                : 
                  null
        }
          <div style = {{display: showQr, flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
              <h2 style={{color:colorQr}}><b>{messageQr}</b></h2>
              <div onClick={() => window.open(url, '_blank')} style={{border:`10px solid ${colorQr}`, paddingBottom:0, paddingTop: 6, paddingLeft:6, paddingRight: 6}}>
                <QRCode value={url} />
              </div>
              <div style={{paddingTop:20, alignContent:'center', justifyContent:'center'}}>
                <p style={{textAlign:'center'}}>Scan (or tap) this code to verify that this report is legitimate.</p>
                {(messageQr !== 'EXPIRED')
                  ? <>
                        <p style={{textAlign:'center'}}>Expires in <b>{new Date(timeLeft * 1000).toISOString().substr(11, 8)}</b>.</p>
                    </>
                  : 
                    <p style={{textAlign:'center'}}>This code has expired.</p>

                }
                </div>
              
          </div>    
          <Button className='pwt-button' disabled={showBusy} block size="lg" type="button" onClick={() => StartOver()}>
                  Start Over
          </Button>             
        </div>   
    );
    } else {
      return(
        <div>       
          <LoadingOverlay
            active={showBusy}
            spinner
            text='Getting shareable link...'
            styles={{
              overlay: (base) => ({
                ...base,
              })
            }}
          >
          <div style={{paddingTop: 0, paddingBottom: 0}}>
            <div>
            <Formik
                initialValues={{}}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                      setSubmitting(true);
                      setShowError(false);
                      setShowBusy(true);
                      var addMessage = firebase.functions().httpsCallable('verifyTemperature');
                      
                      addMessage({temperature: '', unit: '', useLastReading: true})
                      .catch(function(error) {
                        if (error.code === 'internal') {
                          setErrorMessage('Unable to communicate with the PWT servers. Check your internet connection and try again.');
                        } else {
                          setErrorMessage(error.message);
                        }
                        setShowError(true);
                        setShowBusy(false);
                      });
                }}
              >
              {( {values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting }) => (
                <Form onSubmit={handleSubmit} className="mx-auto">
                  <h3>Use Your Last Temperature</h3>
                  <Button className='pwt-button'  disabled={showBusy} block bssize="large"  type="submit">
                    Verify Last Recorded Temperature
                  </Button>
                </Form>
              )}
              </Formik>
            </div>
            
            <div style={{paddingTop:20}}>
              <Formik
                  initialValues={{ temperature:""}}
                  validationSchema={validationSchema}
                  onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        setShowError(false);
                        setShowBusy(true);
                        setUrl('');
                        setShowQr('none');
                        var addMessage = firebase.functions().httpsCallable('verifyTemperature');
                        let unit = '';
                        if (values.temperature >= 35 && values.temperature <= 40) {
                          unit = 'celsius';
                        } else if (values.temperature >= 95 && values.temperature <= 104) {
                          unit = 'fahrenheit';
                        }
                        addMessage({temperature: values.temperature, unit: unit, useLastReading: false})
                        .then(function(result) {
                          // let resultObject = JSON.parse(result.data);
                          setShowBusy(false);
                        })
                        .catch(function(error) {
                          if (error.message === 'internal') {
                            setErrorMessage('Unable to communicate with the PWT servers. Check your internet connection and try again.');
                          } else {
                            setErrorMessage(error.message);
                          }
                          setShowError(true);
                          setShowBusy(false);
                        });
                  }}
                >
                {( {values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting }) => (
                  <Form onSubmit={handleSubmit} className="mx-auto">
                    <Form.Group controlId="formTemperature">
                    <h3>Use a New Measurement</h3>
                    <Form.Label>Measured Temperature:</Form.Label>
                      <Form.Control
                        type="number"
                        step="any"
                        name="temperature"
                        inputMode='decimal'
                        placeholder="97.7 ℉ or 36.5 ℃"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={showBusy}
                        value={values.temperature}
                        isInvalid={errors.temperature && touched.temperature}
                        isValid={!errors.temperature && touched.temperature}
                      />
                      <Form.Control.Feedback type="invalid" style={{
                              color: '#dc3545',
                              fontSize: '.8em',
                            }}>
                        {errors.temperature}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button className='pwt-button' disabled={showBusy} block bssize="large"  type="submit">
                      Verify New Measurement
                    </Button>
                  </Form>
                )}
                </Formik>
            </div>
            <div style={{paddingTop:15}}>
                <Alert show={showError} variant="danger">
                      <Alert.Heading>Error Validating Temperature</Alert.Heading>
                      <p>{errorMessage}</p>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <Button onClick={() => setShowError(false)} variant="outline-danger">
                          Dismiss
                        </Button>
                      </div>
                </Alert> 
            </div>
          </div>
          </LoadingOverlay>  
      </div>   
    );
    }   
}
  
const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(VerifyPage);
