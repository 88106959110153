import React, {useContext, useEffect, useState} from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

import { Button, Col, Row } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import ReactEcharts from 'echarts-for-react';

import firebase from 'firebase/app';

import DatePicker from "react-datepicker";

import { AuthUserContext } from '../Session';


const PROVISIONAL_TEMPERATURE_FAHRENHEIT = 99;
const PROVISIONAL_TEMPERATURE_CELSIUS = 37.2222;

const CDC_FEVER_FAHRENHEIT = 100.4;
const CDC_FEVER_CELSIUS = 38;


const CHART_DEFAULT_MAXIMUM_FAHRENHEIT = 101;
const CHART_DEFAULT_MINIMUM_FAHRENHEIT = 96;

const CHART_DEFAULT_MAXIMUM_CELSIUS = 38.5;
const CHART_DEFAULT_MINIMUM_CELSIUS = 35.5;

const CHART_DEFAULT_LOOKBACK_DAYS = 30;


function TemperatureChartModuleBase(props) {
    let userContext = useContext(AuthUserContext);
  
    const [showBusy, setShowBusy] = useState(false);
  
    const [measuredTemperature, setMeasuredTemperature] = useState(0);
  
    const [temperatureData, setTemperatureData] = useState([]);
  
    const [temperatureDataMean, setTemperatureDataMean] = useState(0);
    
    const [totalTemperatureEntries, setTotalTemperatureEntries] = useState(0);
    const [totalHealthyEntries, setTotalHealthyEntries] = useState(0);
    const [warningTemperature, setWarningTemperature] = useState('');
  
    const [provisionalWarningTemperature, setProvisionalWarningTemperature] = useState(PROVISIONAL_TEMPERATURE_FAHRENHEIT);
  
    const [healthStatus, setHealthStatus] = useState('');
    const [healthTitle, setHealthTitle] = useState('');
    const [healthMessage, setHealthMessage] = useState('');
  
    const [chartUpper, setChartUpper] = useState(CHART_DEFAULT_MAXIMUM_FAHRENHEIT);
    const [chartLower, setChartLower] = useState(CHART_DEFAULT_MINIMUM_FAHRENHEIT);
    const [chartUnit, setChartUnit] = useState('℉');
  
    const [temperatureDataMeanArray, setTemperatureDataMeanArray] = useState([]);
    const [temperatureDataChannelUpperArray, setTemperatureDataChannelUpperArray] = useState([]);
    const [temperatureDataChannelLowerArray, setTemperatureDataChannelLowerArray] = useState([]);
    const [temperatureDataPersonalWarningArray, setTemperatureDataPersonalWarningArray] = useState([]);
    const [temperatureDataProvisionalWarningArray, setTemperatureDataProvisionalWarningArray] = useState([]);
    const [temperatureDataCDCFeverArray, setTemperatureDataCDCFeverArray] = useState([]);
  
    const [showGraphSettings, setShowGraphSettings] = useState(false);
  
    const [showOverlay, setShowOverlay] = useState(false);
  
    const [hasUpdatedPlottingUnit, setHasUpdatedPlottingUnit] = useState(false);
    const [preferredPlottingUnit, setPreferredPlottingUnit] = useState('fahrenheit');
  
    const [endDate, setEndDate] = useState((new Date()));
    const [startDate, setStartDate] = useState((new Date(Date.now() - CHART_DEFAULT_LOOKBACK_DAYS * 24 * 60 * 60 * 1000)));
  
    const [assessmentData, setAssessmentData] = useState([]);
  
    const [plottingData, setPlottingData] = useState([]);
    
    const [lastUpdateString, setLastUpdateString] = useState('');
  
    const [chartHeight, setChartHeight] = useState(350);
  
    function fahrenheitToCelsius(fahrenheit: number) {
      return ((fahrenheit - 32.0) * (5.0 / 9.0 ))
    }
  
    function celsiusToFahrenheit(celsius: number) {
      return ((celsius * 9.0 / 5.0 ) + 32.0)
    }
  
    function updatePlottingUnit(unit) {
      setHasUpdatedPlottingUnit(true);
      setPreferredPlottingUnit(unit);
    }
  
    function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = '' + d.getFullYear(),
          hour = '' + d.getHours(),
          minutes = '' + d.getMinutes(),
          seconds = '' +  d.getSeconds();
    
      var timeSuffix = 'AM'
      if (hour === String(12)) {
          timeSuffix = 'PM'
      } else if (hour > 12) {
          hour = hour - 12;
          timeSuffix = 'PM'
      } else if (hour === String(0)) {
          hour = 12;
      }
      
  
      if (day.length < 2) 
          day = '0' + day;
      
      if (hour.length < 2) {
          hour = '0' + hour;
      }
      if (minutes.length < 2) {
          minutes = '0' + minutes;
      }
      if (seconds.length < 2) {
          seconds = '0' + seconds;
      }
  
      var formattedString = `${month}/${day}/${year}\n${hour}:${minutes}:${seconds} ${timeSuffix}`;
      return formattedString;
    }
  
    function setNoDataStates() {
  
      setWarningTemperature(0);
      setProvisionalWarningTemperature(PROVISIONAL_TEMPERATURE_FAHRENHEIT);
      setChartUpper(CHART_DEFAULT_MAXIMUM_FAHRENHEIT);
      setChartLower(CHART_DEFAULT_MINIMUM_FAHRENHEIT);
      setChartUnit('℉');
  
      setTemperatureData([]);
      setTemperatureDataCDCFeverArray([]);
      setTemperatureDataPersonalWarningArray([]); 
      setTemperatureDataProvisionalWarningArray([]); 
      setTemperatureDataChannelUpperArray([]); 
      setTemperatureDataChannelLowerArray([]); 
      setTemperatureDataMeanArray([]); 
    }
  
    function ProvisionalChart() {
      return (
        <ReactEcharts style={{minHeight:chartHeight, width:'99%'}}
          option={{
                  grid: {
                  containLabel: true
                  },
                  title: {
                  text: `Temperature Chart (Provisional)`,
                  subtextStyle:{color: 'black'},
                  },
                  tooltip: {
                  show: showOverlay,
                  trigger: 'axis',
                  axisPointer:{
                      label:{
                      formatter: function (value, index) {
                          // Formatted to be month/day; display year only in the first label
                          var date = new Date(value.value);
                          return date.toLocaleString(undefined, { timeZoneName: 'short' });
                        }
                      }
                  }
                  },
                  legend: {
                  type: 'scroll',
                  orient: 'horizontal',
                  right: 10,
                  top: 20,
                  bottom: 20,
                  icon:'rect'
                  },
              xAxis: {
                  nameLocation: 'center',
                  nameTextStyle: {padding: [0, 0, 0, 0]}, //[top, right, bottom, left]
                  nameGap: 40,
                  type: 'time',
                  axisLabel: {
                  show: true,
                  rotate: 45,
                  padding: [20,0,0,0],
                  formatter: function (value, index) {
                      var date = new Date(value);                        
                      return formatDate(date.toString());
                  }
                  }
              },
                  yAxis: {
                  name: `Temperature (${chartUnit})`,
                  nameLocation: 'center',
                  nameGap: 40,
                  type: 'value',
                  max: chartUpper,
                  min: chartLower,
                  axisLabel: {
                      show: true
                  },
                  minorTick: {
                      show: true
                  }
                  },
                  series: [
                  { 
                    data: temperatureData,
                    type: 'line',
                    color: 'blue',
                    name: 'Temperature',
                  },
                  { 
                  data: temperatureDataProvisionalWarningArray,
                  type: 'line',
                  color:'orange',
                  name: 'PWT',
                  showSymbol: false,
                  showLegendSymbol: true,
                  },
                  { 
                  data: temperatureDataCDCFeverArray,
                  type: 'line',
                  color:'red',
                  name: 'CDC Fever',
                  showSymbol: false,
                  showLegendSymbol: true,
                  },
              ],
          }}
      />);
  }
  
    function ProfileChart() {
    return (
      <ReactEcharts style={{minHeight:chartHeight, width:'99%'}}
          option={{
              grid: {
              containLabel: true
              },
              title: {
              text: `Temperature Chart`,
              subtextStyle:{color: 'black'},
              },
              tooltip: {
              show: showOverlay,
              trigger: 'axis',
              axisPointer:{
                  label:{
                  formatter: function (value, index) {
                      // Formatted to be month/day; display year only in the first label
                      var date = new Date(value.value);
                      return date.toLocaleString(undefined, { timeZoneName: 'short' });
                    }
                  }
              }
              },
              legend: {
              type: 'scroll',
              orient: 'horizontal',
              right: 10,
              top: 20,
              bottom: 20,
              icon:'rect'
          },
              xAxis: {
              nameLocation: 'center',
              nameTextStyle: {padding: [0, 0, 0, 0]}, //[top, right, bottom, left]
              nameGap: 40,
              type: 'time',
              axisLabel: {
                  show: true,
                  rotate: 45,
                  padding: [20,0,0,0],
                  formatter: function (value, index) {
                  var date = new Date(value);                        
                  return formatDate(date.toString());
                  }
              }
              },
              yAxis: {
              name: `Temperature (${chartUnit})`,
              nameLocation: 'center',
              nameTextStyle: {padding: [0, 0, 0, 0]}, //[top, right, bottom, left]
              nameGap: 40,
              type: 'value',
              max: chartUpper,
              min: chartLower,
              axisLabel: {
                  show: true
              },
              minorTick: {
                  show: true
              }
              },
              series: [
              { 
                  data: temperatureData,
                  type: 'line',
                  color: 'blue',
                  name: 'Temperature',                    
              },
              { 
                  data: temperatureDataPersonalWarningArray,
                  type: 'line',
                  color:'orange',
                  name: 'PWT',
                  showSymbol: false
              },
              { 
                  data: temperatureDataCDCFeverArray,
                  type: 'line',
                  color:'red',
                  name: 'CDC Fever',
                  showSymbol: false,
                  
              },
              { 
                  data: temperatureDataChannelUpperArray,
                  type: 'line',
                  color:'green',
                  name: 'Channel',
                  showSymbol: false,
                  showLegendSymbol: false,
                  lineStyle: {
                    type: "dashed"
                  }
              },
              { 
                  data: temperatureDataChannelLowerArray,
                  type: 'line',
                  color:'green',
                  name: 'Channel',
                  showSymbol: false,
                  showLegendSymbol: false,
                  lineStyle: {
                    type: "dashed"
                  } 
              },
              { 
                  data: temperatureDataMeanArray,
                  type: 'line',
                  color:'black',
                  name: 'Average',
                  showSymbol: false,
                  lineStyle: {
                  type: "dotted"
                  }
              },
          ],
                    
          }}
        />
  
    );
  }
  
  
    useEffect(() => {
    //   console.log(userContext);
      setShowBusy(true);
      var entry = [];
      if (userContext.assessmentDoc != null) {
          entry.push(userContext.assessmentDoc);
      }
      setAssessmentData(entry);
    }, [userContext]);
    
    useEffect(() => { 
      async function loadPlottingData() {
        setShowBusy(true);
        // Fetch up-to-date health metrics from the latest temperature entry
        if (assessmentData.length < 1) {
            setNoDataStates();
            if (showBusy) {
              setShowBusy(false);
            }
          return;
        } else {
            var localStartDate = startDate;
            var localEndDate = endDate;
            localStartDate.setHours(0,0,0,0);
            localEndDate.setHours(23,59,59,999);
            var plottingEntries = [];
            var plottingQuery = await firebase.firestore().collection('users/'+firebase.auth().currentUser.uid+"/temperature_data/")
            .where("timestamp", ">=", firebase.firestore.Timestamp.fromDate(localStartDate))
            .where("timestamp", "<=", firebase.firestore.Timestamp.fromDate(localEndDate))
            .orderBy("timestamp", 'desc')
            .get()
            await Promise.all(plottingQuery.docs.map(async (doc) => {
              plottingEntries.push(doc.data());
            }));
            setPlottingData(plottingEntries);
        }
        
      }
  
      loadPlottingData();
    }, [assessmentData, startDate, endDate]);
    
    useEffect(() => {
      function makeGraph() {
        if (assessmentData.length < 1) {
            setNoDataStates();
            if (showBusy) {
              setShowBusy(false);
            }
          return;
        } else {
            const parsedTemperatureData: { temperature: string; unit: string; timestamp: string; docID: string}[] = [];
            let temperatures: any[] = [];
            let pwtLastUpdate = assessmentData[0];
            let plottingUnit = ''
            if (hasUpdatedPlottingUnit === true) {
              plottingUnit = preferredPlottingUnit;
            } else {
              plottingUnit = pwtLastUpdate.unit;
            }
             
            let plottingEntries = plottingData;
            // Prep all data for the user
            plottingEntries.forEach(function(doc) {
                var currentTemp = doc.temperature;
                var currentUnit = doc.unit;
                if (plottingUnit === 'fahrenheit') {
                    if (currentUnit === 'celsius') {
                      currentTemp = Number(Math.round(parseFloat(celsiusToFahrenheit(parseFloat(currentTemp)) + 'e' + 2)) + 'e-' + 2)
                    }
                } else if (plottingUnit === 'celsius') {  
                    if (currentUnit === 'fahrenheit') {
                        currentTemp = Number(Math.round(parseFloat(fahrenheitToCelsius(parseFloat(currentTemp)) + 'e' + 2)) + 'e-' + 2)
    
                    }
                }
                temperatures.push(currentTemp);
                parsedTemperatureData.push({
                    temperature: currentTemp,
                    unit: currentUnit,
                    timestamp: doc.timestamp,
                    docID: doc.id
                });
            });
    
            let tempMeasuredTemperature = 0;
            let tempCDCFever = 0;
            let tempChannelUpper = 0;
            let tempChannelLower = 0;
            let tempChartUpper = 0;
            let tempChartLower = 0;
            let tempPersonalWarningTemperature = 0;
            let tempTemperatureDataMeanPlotting = 0;
            let tempProvisionalWarningTemperaturePlotting = 0;
            let tempPersonalWarningTemperaturePlotting = 0;
            let tempChartUnit = '';
            let tempLastUpdateDate: Date;
            let tempLastUpdateDateString = '';
  
            tempMeasuredTemperature = pwtLastUpdate.temperature;
            tempPersonalWarningTemperature = pwtLastUpdate.health_assessment.personal_warning_temperature
            tempTemperatureDataMeanPlotting = pwtLastUpdate.health_assessment.mean;
            tempPersonalWarningTemperaturePlotting = pwtLastUpdate.health_assessment.personal_warning_temperature
            
            tempChannelUpper = pwtLastUpdate.health_assessment.channel_upper;
            tempChannelLower = pwtLastUpdate.health_assessment.channel_lower;
  
            tempLastUpdateDate = new Date((assessmentData[0].timestamp.seconds * 1000));
            tempLastUpdateDateString = tempLastUpdateDate.toLocaleString(undefined, { timeZoneName: 'short' });
  
            // Conversions
            if (pwtLastUpdate.unit === 'fahrenheit') {
              tempProvisionalWarningTemperaturePlotting = PROVISIONAL_TEMPERATURE_FAHRENHEIT;
            } else if (pwtLastUpdate.unit === 'celsius') {
              tempProvisionalWarningTemperaturePlotting = PROVISIONAL_TEMPERATURE_CELSIUS;
            }
    
            if (plottingUnit === 'fahrenheit') {
              tempChartUnit = '℉';
              if (pwtLastUpdate.unit === 'fahrenheit') {
                // do nothing
              } else if (pwtLastUpdate.unit === 'celsius') {
                tempMeasuredTemperature = celsiusToFahrenheit(parseFloat(tempMeasuredTemperature));
                tempProvisionalWarningTemperaturePlotting = celsiusToFahrenheit(parseFloat(tempProvisionalWarningTemperaturePlotting));
                if (tempPersonalWarningTemperaturePlotting !== 0) {
                  tempTemperatureDataMeanPlotting = celsiusToFahrenheit(parseFloat(tempTemperatureDataMeanPlotting));
                  tempPersonalWarningTemperaturePlotting = celsiusToFahrenheit(parseFloat(tempPersonalWarningTemperaturePlotting));
                  tempChannelUpper = celsiusToFahrenheit(parseFloat(tempChannelUpper));
                  tempChannelLower = celsiusToFahrenheit(parseFloat(tempChannelLower));
                }
              }
            } else if (plottingUnit === 'celsius') {
              tempChartUnit = '℃';
              if (pwtLastUpdate.unit === 'fahrenheit') {  
                tempMeasuredTemperature = fahrenheitToCelsius(parseFloat(tempMeasuredTemperature));
                tempProvisionalWarningTemperaturePlotting = fahrenheitToCelsius(parseFloat(tempProvisionalWarningTemperaturePlotting));
                if (tempPersonalWarningTemperaturePlotting !== 0) {
                  tempTemperatureDataMeanPlotting = fahrenheitToCelsius(parseFloat(tempTemperatureDataMeanPlotting));
                  tempPersonalWarningTemperaturePlotting = fahrenheitToCelsius(parseFloat(tempPersonalWarningTemperaturePlotting));
                  tempChannelUpper = fahrenheitToCelsius(parseFloat(tempChannelUpper));
                  tempChannelLower = fahrenheitToCelsius(parseFloat(tempChannelLower));
                }
              } else if (pwtLastUpdate.unit === 'celsius') {
                // do nothing
              }
            }
    
            // Calculate bounds for the plot
            let max = Math.max(...temperatures);
            let min = Math.min(...temperatures);
    
            if (tempPersonalWarningTemperature !== 0) {
              max = Math.max(max, tempPersonalWarningTemperaturePlotting, tempChannelUpper);
              min = Math.min(min, tempChannelLower);
            }
            
            if (plottingUnit === 'fahrenheit') {
              if (max >= CDC_FEVER_FAHRENHEIT) {
                tempChartUpper = Math.round(max) + 1;
              } else {
                tempChartUpper = CHART_DEFAULT_MAXIMUM_FAHRENHEIT;
              }
              if (min <= (CHART_DEFAULT_MINIMUM_FAHRENHEIT + 0.8)) {
                tempChartLower = Math.round(min) - 1;
              } else {
                tempChartLower = CHART_DEFAULT_MINIMUM_FAHRENHEIT;
              }
              tempCDCFever = CDC_FEVER_FAHRENHEIT;
            } else {
              if (max >= CDC_FEVER_CELSIUS) {
                tempChartUpper = Math.round(max) + 1;
              } else {
                tempChartUpper = CHART_DEFAULT_MAXIMUM_CELSIUS;
              }
              if (min <= (CHART_DEFAULT_MINIMUM_CELSIUS + 0.5)) {
                tempChartLower = Math.round(min) - 1;
              } else {
                tempChartLower = CHART_DEFAULT_MINIMUM_CELSIUS;
              }
              tempCDCFever = CDC_FEVER_CELSIUS;
            }
    
            
    
            // Rounding
            tempMeasuredTemperature = Number(Math.round(parseFloat(tempMeasuredTemperature + 'e' + 2)) + 'e-' + 2);
            tempPersonalWarningTemperaturePlotting = Number(Math.round(parseFloat(tempPersonalWarningTemperaturePlotting + 'e' + 2)) + 'e-' + 2);
            tempProvisionalWarningTemperaturePlotting = Number(Math.round(parseFloat(tempProvisionalWarningTemperaturePlotting + 'e' + 2)) + 'e-' + 2);
            tempTemperatureDataMeanPlotting = Number(Math.round(parseFloat(tempTemperatureDataMeanPlotting + 'e' + 2)) + 'e-' + 2);
            tempChannelUpper = Number(Math.round(parseFloat(tempChannelUpper + 'e' + 2)) + 'e-' + 2);
            tempChannelLower = Number(Math.round(parseFloat(tempChannelLower + 'e' + 2)) + 'e-' + 2);
            tempCDCFever = Number(Math.round(parseFloat(tempCDCFever + 'e' + 2)) + 'e-' + 2);
    
            // Building Arrays
            let temperatureDataArray = [];
            let tempTemperatureDataMeanArray = [];
            let tempTemperatureDataChannelUpperArray = [];
            let tempTemperatureDataChannelLowerArray = [];
            let tempTemperatureDataPersonalWarningArray = [];
            let tempTemperatureDataProvisionalWarningArray = [];
            let tempTemperatureDataCDCFeverArray = [];
    
            parsedTemperatureData.forEach(temperatureEntry => {
              temperatureDataArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, temperatureEntry.temperature]);
              tempTemperatureDataMeanArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempTemperatureDataMeanPlotting]);
              tempTemperatureDataChannelUpperArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempChannelUpper]);
              tempTemperatureDataChannelLowerArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempChannelLower]);
              tempTemperatureDataPersonalWarningArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempPersonalWarningTemperaturePlotting]);
              tempTemperatureDataProvisionalWarningArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempProvisionalWarningTemperaturePlotting]);
              tempTemperatureDataCDCFeverArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempCDCFever]);
            });
  
    
  
            setMeasuredTemperature(tempMeasuredTemperature);
            setTemperatureDataMean(tempTemperatureDataMeanPlotting);
            setWarningTemperature(tempPersonalWarningTemperaturePlotting);
            setProvisionalWarningTemperature(tempProvisionalWarningTemperaturePlotting);
            
            // Plotting
            setChartUnit(tempChartUnit);
            setProvisionalWarningTemperature(tempProvisionalWarningTemperaturePlotting);
            setChartUpper(tempChartUpper);
            setChartLower(tempChartLower);
            setTemperatureData(temperatureDataArray.reverse());
            
            setTemperatureDataCDCFeverArray(tempTemperatureDataCDCFeverArray.reverse());
            setTemperatureDataPersonalWarningArray(tempTemperatureDataPersonalWarningArray.reverse()); 
            setTemperatureDataProvisionalWarningArray(tempTemperatureDataProvisionalWarningArray.reverse()); 
    
            setTemperatureDataChannelUpperArray(tempTemperatureDataChannelUpperArray.reverse()); 
            setTemperatureDataChannelLowerArray(tempTemperatureDataChannelLowerArray.reverse()); 
            setTemperatureDataMeanArray(tempTemperatureDataMeanArray.reverse()); 
    
            if (plottingUnit !== preferredPlottingUnit) {
              setPreferredPlottingUnit(plottingUnit);
            }
    
            setShowBusy(false); 
        }
      }
      makeGraph();
    }, [plottingData, preferredPlottingUnit, hasUpdatedPlottingUnit]); // eslint-disable-line react-hooks/exhaustive-deps
  
      return (
        <LoadingOverlay
                  active={showBusy}
                  spinner
                  text='Refreshing...'
                  styles={{
                    overlay: (base) => ({
                      ...base,
                    })
                  }}
          >
        <div>     
          {(assessmentData.length < 1 && showBusy === false)
            ? 
              null
            : 
            <div>
              
              <div>
                
                <div>
                {(plottingData.length < 1)
                    ? <p>No data found to plot for the specified window.</p>
                    : 
                    <div>
                      {(warningTemperature !== 0)
                        ? 
                        <div>
                          <ProfileChart></ProfileChart>
                        </div>
                        : 
                        <div>
                          <ProvisionalChart></ProvisionalChart>
                        </div> 
                      }
                    </div>
                  }
                  <hr/>
                    <div> 
                      {/* <h4>Options:</h4> */}
                      <p style={{fontSize: '1.4rem'}}>{`Options:`}</p>
                      <div style={{padding:5}}>
                      <Row>
                        <Col style={{backgroundColor:'whitesmoke'}}>
                            <div style={{padding:5}}>
                              <label><b>From</b>:</label>
                                <div>
                                <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                                </div>
                            </div>
                            <div style={{padding:5}}>
                              <label><b>To</b>:</label>
                              <div>
                                <DatePicker selected={endDate} onChange={date => setEndDate(date)}/>
                              </div>
                            </div>
                        </Col>
                        <Col sm = {true}>
                          <div style={{padding:5}}>
                            <label>Detail Overlay:</label>
                            <Button className='pwt-button' block size="sm"  type="button" onClick={() => setShowOverlay(!showOverlay)}>
                              {(showOverlay)
                              ? 'Disable'
                              : 'Enable'
                              }
                            </Button>
                          </div>
                          <div style={{padding:5}}>
                            <label>Unit:</label>
                            <select className="form-control" id="plottingUnitSelector" value={preferredPlottingUnit} onChange={ (e) => updatePlottingUnit(e.target.value)}>
                              <option value="fahrenheit">Fahrenheit</option>
                              <option value="celsius">Celsius</option>
                            </select>
                          </div>
                          
                        </Col>
                      </Row>
                      </div>
                      
                    </div>
                </div>
  
                  
              </div>
            </div>
          }
          
      </div>
      </LoadingOverlay> 
                    
      );
    
  }
  
  const TemperatureChartModule = compose(
    withFirebase,
  )(TemperatureChartModuleBase);
  
  export { TemperatureChartModule };
