import React, { useEffect, useState, useContext } from 'react';
import { compose } from 'recompose';

import { AuthUserContext, withAuthentication } from '../../components/Session';

import { withAuthorization, withAuthUser } from '../../components/Session';
// import { withAuthentication } from '../Session';
import { withFirebase } from '../../components/Firebase';


import { Button, Container, Row, Col } from "react-bootstrap";

import firebase from 'firebase/app';

import LoadingOverlay from 'react-loading-overlay';
import DatePicker from "react-datepicker";

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';

import { Helmet } from 'react-helmet-async';


function HistoryPage(props) {
  return(
    <Container style={{padding:0}}>
      <Helmet>
        <title>History | PWT</title>
        <meta name="description" content="View your temperature history."/>
      </Helmet>   
        <HistoryPageBase></HistoryPageBase>
    </Container>         
  );
}


function HistoryPageBase(props) {
  
    return(
      <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>       
        <h1>Temperature History</h1>
        <p>Use this menu to filter and view your temperature history. You may also export your data to a spreadsheet.</p>
        <HistoryModule></HistoryModule>
    </div>   
  );
    
}


function HistoryModuleBase(props) {
  const userContext = useContext(AuthUserContext)

  const [showBusy, setShowBusy] = useState(true);

  const [temperatureData, setTemperatureData] = useState([]);

  const [endDate, setEndDate] = useState(new Date());

  const [startDate, setStartDate] = useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));  // 30 days

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
    {
      (size === 0)
      ?
      ` No results found.`
      :
      ` Showing ${ from } to ${ to } of ${ size } entries.`
    }
    </span>
  );
  
  function timestampFormatter(cell, row) {
      return (
        cell.formatted
      );
  }
  
  const columns = [{
    dataField: 'id',
    text: '#',
    sort: true
  },{
    dataField: 'temperature',
    text: 'Temperature',
    sort: true
  }, {
    dataField: 'unit',
    text: 'Unit',
    sort: true
  }, {
    dataField: 'timestamp',
    text: 'Timestamp',
    sort: true,
    sortFunc: (a, b, order, dataField) => {
      if (order === 'asc') {
        return b.seconds - a.seconds;
      }
      return a.seconds - b.seconds; // desc
    },
    formatter: timestampFormatter,
    csvFormatter: timestampFormatter
  }];

  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    let buttonClass = 'pwt-button';
    if (active) {
      buttonClass = 'pwt-button-secondary'
    }
    return (
      <div key={ page } style={{paddingLeft:1, paddingRight:1}}>
        <Button className={buttonClass} key={ page } onClick={ handleClick }>{ page }</Button>
      </div>
    );
  };

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
    size
  }) => (
    <div className="btn-group" role="group">
      {
        options.map((option) => {
          const active = currSizePerPage === `${option.page}`;
          let buttonClass = 'pwt-button';
          if (active) {
            buttonClass = 'pwt-button-secondary'
          }
          return (
            <div key={ option.text } style={{paddingLeft:1, paddingRight:1}}>
              <Button className={buttonClass} onClick={ () => onSizePerPageChange(option.page) } >{ option.text }</Button>
            </div>
          );
         
        })
      }
    </div>
  );

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport(temperatureData);
    };
    return (
      <div>
        <Button className='pwt-button' block size='sm' type='button' disabled={(temperatureData.length < 1)} onClick={ handleClick }>Export Spreadsheet</Button>
      </div>
    );
  };

  const options = {
    pageButtonRenderer,
    sizePerPageRenderer,
    paginationTotalRenderer: customTotal,
    custom: true,
    paginationSize: 3,
    pageStartIndex: 1,
    totalSize: temperatureData.length,
    showTotal: true,
    hideSizePerPage: (temperatureData.length > 1) ? false : true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '25', value: 25
    },{
      text: '50', value: 50
    }, {
      text: 'All', value: temperatureData.length
    }]
  };

  const historyTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="id"
        columns={ columns }
        data={ temperatureData }
        exportCSV = { {
          fileName: `PWT_History-(${new Date().toLocaleString(undefined, { timeZoneName: 'short' })}).csv`,
        } }
      >    
        {
          toolkitprops => (
            <div>
              <MyExportCSV { ...toolkitprops.csvProps }></MyExportCSV>
              <hr/>
              <h2>Temperature History</h2>
              <LoadingOverlay
                    active={showBusy}
                    spinner
                    text='Loading history...'
                    styles={{
                      overlay: (base) => ({
                        ...base,
                      })
                    }}
              >
              <BootstrapTable
                bootstrap4
                wrapperClasses="table-responsive"
                striped
                hover
                condensed
                { ...toolkitprops.baseProps }
                { ...paginationTableProps }
              />
              <Row>
                <Col>
                  <div style={{display: 'flex', flexDirection:'column'}}>
                    <SizePerPageDropdownStandalone { ...paginationProps } />
                    <PaginationTotalStandalone { ...paginationProps } />
                  </div>
                </Col>
                <Col sm={1}>
                  <div>
                    <PaginationListStandalone { ...paginationProps } />
                  </div>
                </Col>
              </Row>
              </LoadingOverlay>
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  );

  useEffect(() => {
    function assignDatabaseListener() {
      var localStartDate = startDate;
      var localEndDate = endDate;
      localStartDate.setHours(0,0,0,0);
      localEndDate.setHours(23,59,59,999);
      let unsubscribe = firebase.firestore().collection('users/'+firebase.auth().currentUser.uid+"/temperature_data/")
      .where("timestamp", ">=", firebase.firestore.Timestamp.fromDate(localStartDate))
      .where("timestamp", "<=", firebase.firestore.Timestamp.fromDate(localEndDate))
      .orderBy("timestamp", 'desc')
      .onSnapshot(function(querySnapshot) {
        setShowBusy(true);
          var entries = [];
          querySnapshot.forEach(function(doc) {
              entries.push(doc.data());
              entries[entries.length - 1].timestamp.formatted = (new Date(doc.data().timestamp.seconds * 1000)).toLocaleString(undefined, { timeZoneName: 'short' });
              entries[entries.length - 1].id = (querySnapshot.docs.length - entries.length + 1);
          });
          setTemperatureData(entries);
          setShowBusy(false);
      });
      return unsubscribe;
    }

    return assignDatabaseListener();
  }, [startDate, endDate]);


    return(
      <div>       
        <h2>Filter History:</h2>       
        <Row>
          <Col style={{backgroundColor:'whitesmoke'}}>
              <div style={{padding:5}}>
                <label><b>From</b>:</label>
                  <div>
                  <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                  </div>
              </div>
              <div style={{padding:5}}>
                <label><b>To</b>:</label>
                <div>
                  <DatePicker selected={endDate} onChange={date => setEndDate(date)}/>
                </div>
              </div>
          </Col>
        </Row>
    <div style={{paddingTop:20}}>
      <PaginationProvider
            pagination={
              paginationFactory(options)
            }
          >
            { historyTable }
      </PaginationProvider>
    </div >
  </div>   
  );
    
}

const HistoryModule = compose(
  // withFirebase,
  // withAuthUser
)(HistoryModuleBase);

export { HistoryModule };

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(HistoryPage);
