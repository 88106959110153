import React, {useState} from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";

import { Formik } from 'formik';
import * as Yup from 'yup';

import { enforceLogin } from '../../components/Session';

import firebase from 'firebase/app';
import 'firebase/functions'
import LoadingOverlay from 'react-loading-overlay';

import { Helmet } from 'react-helmet-async';


const PasswordForgetPage = (props) => (
    <Container style={{padding:0}}>
      <Helmet>
        <title>Reset Password | PWT</title>
        <meta name="description" content="Trouble signing in? Reset your password here."/>
      </Helmet> 
      <PasswordForgetFormBase></PasswordForgetFormBase>
    </Container>
);

const validationSchema = Yup.object().shape({
  username: Yup.string()
  .min(2, "Username must have at least 2 characters.")
  .max(64, "Username can't be longer than 64 characters.")
  .required("Username is required."),
  // .matches(
  //   /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/,
  //   "Username may contain only letters and numbers, optionally separated by underscores (_) or dashes (-)."
  // ),
  licenseKey: Yup.string()
  .required("License Key is required.")
  .label('License Key')
  .matches(
    /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/,
    "Must conform to the specified license key format."
  ),
  password: Yup.string()
  .min(6,"Password must be at least 6 characters.")   
  .required("Password is required."),
  passwordConfirm: Yup.string()
  .required("Please confirm the password.")
  .label('Confirm password')
  .test('passwords-match', 'Passwords must match.', function(value) {
    return this.parent.password === value;
  }),
});

function PasswordForgetFormBase(props) {
  return(
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, padding:15}}>     
      <h1>Reset Password</h1>
      <p>Use this form to reset your password. Please provide your username and most recently activated license key.</p>
      <ResetPasswordForm></ResetPasswordForm>
      <div style={{paddingTop:10}}>
        <Link to={ROUTES.SIGN_IN_ANONYMOUS}>Sign in.</Link>
      </div>
    </div>
  );
}

function ResetPasswordForm(){
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  return(
    
    <div>     
      <Formik
          initialValues={{ username:"", licenseKey:"", password:"", passwordConfirm:""}}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting, resetForm}) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);
              setShowError(false);
              var addMessage = firebase.functions().httpsCallable('resetPassword');
              addMessage({username: values.username, userPassword: values.password, licenseKey: values.licenseKey})
              .then(function(result) {
                setSubmitting(false);
                setSuccessMessage(`Successfully updated the account's password. You may now sign in.`);
                setShowSuccess(true);
              })
              .catch(error => {
                if (error.code === 'auth/too-many-requests') {
                  setErrorMessage(String('Too many unsuccessful login attempts. Please try again later.'));
                } else if (error.code === 'auth/user-disabled') {
                  setErrorMessage(String('Account is disabled. Contact support for more information.'));
                } else if (error.code === 'auth/network-request-failed') {
                  setErrorMessage('Unable to communicate with the PWT servers. Check your internet connection and try again.');
                }  else if (error.code === 'internal') {
                  setErrorMessage('Unable to communicate with the PWT servers. Check your internet connection and try again.');
                } else {
                  setErrorMessage(error.message);
                }
                setSubmitting(false);
                setShowError(true);
              });
          }}
        >
        {( {values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            <LoadingOverlay
              active={isSubmitting}
              spinner
              text='Updating password...'
              styles={{
                overlay: (base) => ({
                  ...base,
                  borderRadius:"12.5px"
                })
              }}
            >
            <Row>
              <Col>
                <Form.Group controlId="formUsername">
                <Form.Label>Username:</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    autoComplete={"username"}
                    placeholder="example-user-42"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={errors.username && touched.username}
                    isValid={!errors.username && touched.username}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.username}
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback type="valid">Usernames are not case-sensitive.</Form.Control.Feedback> */}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formLicenseKey">
                <Form.Label>License Key:</Form.Label>
                <Form.Control
                  type="text"
                  name="licenseKey"
                  placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.licenseKey}
                  isInvalid={errors.licenseKey && touched.licenseKey}
                  isValid={!errors.licenseKey && touched.licenseKey}
                  style={{textTransform:'uppercase'}}
                />
                <Form.Control.Feedback type="invalid" style={{
                        color: '#dc3545',
                        fontSize: '.8em',
                      }}>
                  {errors.licenseKey}
                </Form.Control.Feedback>
                {/* <Form.Control.Feedback type="valid">License key looks legitimate.</Form.Control.Feedback> */}
            </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formPassword">
                <Form.Label>New Password:</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    autoComplete={"new-password"}
                    placeholder="********"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={errors.password && touched.password}
                    isValid={!errors.password && touched.password}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.password}
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback type="valid">Password meets requirements.</Form.Control.Feedback> */}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formPasswordConfirm">
                <Form.Label>Confirm:</Form.Label>
                  <Form.Control
                    type="password"
                    name="passwordConfirm"
                    autoComplete={"new-password"}
                    placeholder="********"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirm}
                    isInvalid={errors.passwordConfirm && touched.passwordConfirm}
                    isValid={!errors.passwordConfirm && touched.passwordConfirm}
                  />
                  <Form.Control.Feedback style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }} type="invalid">
                    {errors.passwordConfirm}
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback type="valid">Passwords match.</Form.Control.Feedback> */}
                </Form.Group>
              </Col>
            </Row>
            <Alert show={showSuccess} variant="success">
              <Alert.Heading>Password Updated</Alert.Heading>
                  <p>{successMessage}</p>
                  <hr />
                  <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowSuccess(false)} variant="outline-success">
                    Dismiss
                  </Button>
              </div>
            </Alert>
            <Alert show={showError} variant="danger">
              <Alert.Heading>Failed to Update Password</Alert.Heading>
              <p>{errorMessage}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShowError(false)} variant="outline-danger">
                  Dismiss
                </Button>
              </div>
            </Alert>
          <Button className='pwt-button' block size="lg" type="submit" disabled={isSubmitting}>
            Update Password
          </Button>
          </LoadingOverlay>
      </Form>
      )}
      </Formik>
    </div>
  );
}


const condition = authUser => !!authUser;

export default enforceLogin(condition)(PasswordForgetPage);