import React, {useEffect, useState} from 'react';

import { withAuthorization } from '../../components/Session';

import { Alert, Button, Col, Container, Form, FormGroup, Row, Table } from 'react-bootstrap';

import firebase from 'firebase/app';
import 'firebase/functions'

import Dots from 'react-activity/lib/Dots';

import ReactEcharts from 'echarts-for-react';


import 'firebase/firestore'
import 'firebase/auth'


import { Formik } from 'formik';
import * as Yup from 'yup';

import DatePicker from "react-datepicker";

import {mean, std} from 'mathjs';

import { CSVLink } from "react-csv";

import { Helmet } from 'react-helmet-async';


let headers = [
  { label: "Count", key: "index" },
  { label: "Temperature", key: "temperature" },
  { label: "Unit", key: "unit" },
  { label: "Timestamp", key: "timestamp" }
];

const usernameValidationSchema = Yup.object().shape({
  username: Yup.string()
  .required("Username is required."),
});


const PROVISIONAL_TEMPERATURE_FAHRENHEIT = 99;
const PROVISIONAL_TEMPERATURE_CELSIUS = 37.2222;

const CDC_FEVER_FAHRENHEIT = 100.4;
const CDC_FEVER_CELSIUS = 38;


const CHART_DEFAULT_MAXIMUM_FAHRENHEIT = 101;
const CHART_DEFAULT_MINIMUM_FAHRENHEIT = 96;

const CHART_DEFAULT_MAXIMUM_CELSIUS = 38.5;
const CHART_DEFAULT_MINIMUM_CELSIUS = 35.5;

const CHART_DEFAULT_LOOKBACK_DAYS = 30;

function AdminPage(props) {
  const [uid, setUID] = useState("");

  const [usernameUIDPairs, setUsernameUIDPairs] = useState([]);
  
  const [showError, setShowError] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [sortingField, setSortingField] = useState('username');

  const [sortingOrder, setSortingOrder] = useState('asc');

  const [noPermission, setNoPermission] = useState(false);

  const [showUsersTable, setShowUsersTable] = useState(false);

  useEffect(() => {
    async function fetchUsers() {
      var username_uid_pairs = [];
      var userQuery = await firebase.firestore().collection('users/')
      .orderBy("username", 'asc')
      .get() 
      .catch(function(error) {
        if (error.code === 'permission-denied') {
          console.log('No permission.')
          setErrorMessage('Insufficient permissions to use this feature.')
          setShowError(true);
          setNoPermission(true);
        }
      });
      if (userQuery == null) {
        return;
      }
      await Promise.all(userQuery.docs.map(async (doc) => {
        var userObject = doc.data();       
          userObject.last_update = {seconds: 0, milliseconds: 0};
          userObject.status = '';
          userObject.statusMeaning = '';
          var query = await firebase.firestore().collection('users/'+userObject.uid+"/temperature_data/")
          .orderBy("timestamp", 'desc')
          .limit(1)
          .get()
          Promise.all(query.docs.map(async (userDoc) => {
            userObject.last_update = userDoc.data().timestamp;
            var status = userDoc.data().health_assessment.status
            var statusMeaning = '';  
            if (status === 'Red') {
              statusMeaning = 'High Temperature'
            } else if (status === 'Orange') {
              statusMeaning = 'Elevated Temperature'
            } else if (status === 'Yellow') {
              statusMeaning = 'Above Channel'
            } else if (status === 'Green') {
              statusMeaning = 'Normal Temperature'
            } else if (status === 'Blue') {
              statusMeaning = 'Below Channel'
            } else if (status === 'White') {
              statusMeaning = 'Error'
            }
            userObject.status = status;
            userObject.statusMeaning = statusMeaning;
  
          }));
          username_uid_pairs.push(userObject);
        }));
        setUsernameUIDPairs(username_uid_pairs);
    }

    fetchUsers();
  }, []);


  useEffect(() => {
    function sortUsers() {
      if (usernameUIDPairs.length < 1) {
        return;
      }
      var newSortingField = sortingField;
      var users = usernameUIDPairs;
      if (newSortingField === 'username') {
        if (sortingOrder === 'asc') {
          users = users.sort((a, b) => (a.username > b.username) ? 1 : (a.username === b.username) ? ((a.last_update.seconds > b.last_update.seconds) ? 1 : -1) : -1 )
        } else if (sortingOrder === 'desc') {
          users = users.sort((a, b) => (a.username < b.username) ? 1 : (a.username === b.username) ? ((a.last_update.seconds < b.last_update.seconds) ? 1 : -1) : -1 )
        }
      } else if (newSortingField === 'dateJoined') {
        if (sortingOrder === 'asc') {
          users = users.sort((a, b) => (a.creation_date.seconds > b.creation_date.seconds) ? 1 : (a.creation_date.seconds === b.creation_date.seconds) ? ((a.last_update.seconds > b.last_update.seconds) ? 1 : -1) : -1 )
        } else if (sortingOrder === 'desc') {
          users = users.sort((a, b) => (a.creation_date.seconds < b.creation_date.seconds) ? 1 : (a.creation_date.seconds === b.creation_date.seconds) ? ((a.last_update.seconds < b.last_update.seconds) ? 1 : -1) : -1 )
        }
      } else if (newSortingField === 'lastUpdate') {
        if (sortingOrder === 'asc') {
          users = users.sort((a, b) => (a.last_update.seconds > b.last_update.seconds) ? 1 : (a.username === b.username) ? ((a.username > b.username) ? 1 : -1) : -1 )
        } else if (sortingOrder === 'desc') {
          users = users.sort((a, b) => (a.last_update.seconds < b.last_update.seconds) ? 1 : (a.username === b.username) ? ((a.username < b.username) ? 1 : -1) : -1 )
        }
      } else if (newSortingField === 'status') {
        if (sortingOrder === 'asc') {
          users = users.sort((a, b) => (a.status > b.status) ? 1 : (a.status === b.status) ? ((a.last_update.seconds > b.last_update.seconds) ? 1 : -1) : -1 )
        } else if (sortingOrder === 'desc') {
          users = users.sort((a, b) => (a.status < b.status) ? 1 : (a.status === b.status) ? ((a.last_update.seconds < b.last_update.seconds) ? 1 : -1) : -1 )
        }
      }
      setUsernameUIDPairs([...users]);    
    }

    sortUsers();
  }, [sortingOrder, sortingField]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (noPermission === true) {
      props.history.push('/home');
    }
  }, [noPermission]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container style={{padding:0}}>  
      <Helmet>
            <title>Admin | PWT</title>
            <meta name="description" content="Administrative panel." />
            <meta name="robots" content="noindex"/>
      </Helmet>           
            <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
          <Formik
                initialValues={{ username:""}}
                validationSchema={usernameValidationSchema}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                      setSubmitting(true);
                      setShowError(false);
                      const found = usernameUIDPairs.find(el => el.username === values.username);
                      if (found) {
                        setUID(found.uid);
                      } else {
                        setErrorMessage('The specified username does not exist.')
                        setShowError(true);
                      }
                      setSubmitting(false);
                }}
              >
              {( {values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting }) => (
                <Form onSubmit={handleSubmit} className="mx-auto">
                  <h1>Load User Data</h1>
                  <Form.Group controlId="formTemperature">
                  <Form.Label>Username:</Form.Label>
                    <Form.Control
                      type="text"
                      step="any"
                      name="username"
                      placeholder="user-42"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                      isInvalid={errors.username && touched.username}
                      isValid={!errors.username && touched.username}
                    />
                    <Form.Control.Feedback type="invalid" style={{
                            color: '#dc3545',
                            fontSize: '.8em',
                          }}>
                      {errors.username}
                    </Form.Control.Feedback>
                  </Form.Group>
                  
                  <Button className='pwt-button' disabled={isSubmitting} block size="lg"  type="submit">
                    Load Data
                  </Button>
                </Form>
              )}
              </Formik>
              <div style={{paddingTop:20}}>
                <Alert show={showError} variant="danger">
                    <Alert.Heading>Error Fetching Data</Alert.Heading>
                    <p>{errorMessage}</p>
                    <hr />
                    <div className="d-flex justify-content-end">
                      <Button onClick={() => setShowError(false)} variant="outline-danger">
                        Dismiss
                      </Button>
                    </div>
                </Alert>
              </div>
              <div style={{paddingTop: 10, paddingBottom:20}}>
        <Button className='pwt-button' block bssize="large" type="submit" onClick={() => setShowUsersTable(!showUsersTable)}>
            {(showUsersTable)
            ? 'Hide User Table'
            : 'Show User Table'
            }
          </Button>
      </div>
      {(showUsersTable === false)
        ? null
        : 
        <div style={{borderRadius:"25px", padding:20}}> 
        {(usernameUIDPairs.length < 1)
        ? <p>No users found.</p>
        : 
        <div>
          <FormGroup controlId="filter" bssize="large">
            <label>Sort Users: </label>
              <select className="form-control" id="filterField" value={sortingField} onChange={ (e) => setSortingField(e.target.value)}>
                <option value="username">Username</option>
                <option value="dateJoined">Date Joined</option>
                <option value="lastUpdate">Last Update</option>
                <option value="status">Status</option>
              </select>
            <label style={{paddingTop:5}}>Sorting Order: </label>
              <select className="form-control" id="filterOrder" value={sortingOrder} onChange={ (e) => setSortingOrder(e.target.value)}>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </FormGroup>
          <Table style={{width:'100%', borderCollapse:'collapse', border: '1px solid black'}}>         
                <thead style={{display:'table', width: 'calc(100% - 17px)' }}>
                    <tr>
                      <th scope='col' style={{textAlign:'center', width: '20%', padding: '5px', wordBreak: 'break-all'}}>#</th>
                      <th scope='col' style={{textAlign:'center', width: '20%', padding: '5px', wordBreak: 'break-all'}}>Username</th>
                      <th scope='col' style={{textAlign:'center', width: '20%', padding: '5px', wordBreak: 'break-all'}}>Date Joined</th>
                      <th scope='col' style={{textAlign:'center', width: '20%', padding: '5px', wordBreak: 'break-all'}}>Last Update</th>
                      <th scope='col' style={{textAlign:'center', width: '20%', padding: '5px', wordBreak: 'break-all'}}>Status</th>
                    </tr>
                </thead>
                <tbody style={{display:'block', maxHeight:'300px',overflowY:'scroll'}}>
                {usernameUIDPairs.map((user, key) => {
                    return (
                    <tr key={key} style={{display: 'table', width: '100%', boxSizing: 'border-box'}}>
                      <td style={{borderCollapse:'collapse', border:'1px solid black', borderLeft: 'none', borderBottom: 'none', textAlign:'center', width: '20%', padding: '5px', wordBreak: 'break-all'}}>
                        {(sortingOrder === 'asc')
                        ?
                          <div>
                          {key + 1}
                          </div>
                        :
                          <div>
                          {usernameUIDPairs.length - key}
                          </div>
                        }
                      </td>
                      <td style={{borderCollapse:'collapse', border:'1px solid black', borderLeft: 'none', borderBottom: 'none', textAlign:'center', width: '20%', padding: '5px', wordBreak: 'break-all'}}>{user.username}</td>
                      <td style={{borderCollapse:'collapse', border:'1px solid black', borderLeft: 'none', borderBottom: 'none', textAlign:'center', width: '20%', padding: '5px', wordBreak: 'break-all'}}>{new Date(user.creation_date.seconds * 1000).toLocaleString(undefined, { timeZoneName: 'short' })}</td>
                      <td style={{borderCollapse:'collapse', border:'1px solid black', borderLeft: 'none', borderBottom: 'none', textAlign:'center', width: '20%', padding: '5px', wordBreak: 'break-all'}}>
                        {(user.last_update.seconds === '0')
                        ?
                          `N/A`
                        :
                          <div>
                          {new Date(user.last_update.seconds * 1000).toLocaleString(undefined, { timeZoneName: 'short' })}
                          </div>
                        }
                      </td>
                      <td style={{borderCollapse:'collapse', border:'1px solid black', borderLeft: 'none', borderBottom: 'none', borderRight: 'none', textAlign:'center', width: '20%', padding: '5px', wordBreak: 'break-all'}}>
                        {(user.status === '')
                        ?
                          `N/A`
                        :
                          <div>
                          {user.status}
                          <br/>
                          {user.statusMeaning}
                          </div>
                        }
                      </td>
                    </tr>
                    )
                })}
                </tbody>
          </Table>
          </div>
              }
        </div>
      }
              
    </div>
      <LastUpdateAuth uid={uid}></LastUpdateAuth>
      <HistoryPageBase uid={uid}></HistoryPageBase>
  </Container>
    );  
}

function LastUpdateAuth(props) {
  const [showBusy, setShowBusy] = useState(true);

  const [measuredTemperature, setMeasuredTemperature] = useState(0);

  const [temperatureData, setTemperatureData] = useState([]);

  const [temperatureDataMean, setTemperatureDataMean] = useState(0);

  const [totalTemperatureEntries, setTotalTemperatureEntries] = useState(0);
  const [totalHealthyEntries, setTotalHealthyEntries] = useState(0);
  const [warningTemperature, setWarningTemperature] = useState(0);

  const [recalculatedTemperatureDataMean, setRecalculatedTemperatureDataMean] = useState(0);
  const [recalculatedWarningTemperature, setRecalculatedWarningTemperature] = useState(0);

  const [provisionalWarningTemperature, setProvisionalWarningTemperature] = useState(PROVISIONAL_TEMPERATURE_FAHRENHEIT);

  const [healthStatus, setHealthStatus] = useState('');
  const [healthTitle, setHealthTitle] = useState('');
  const [healthMessage, setHealthMessage] = useState('');

  const [chartUpper, setChartUpper] = useState(96);
  const [chartLower, setChartLower] = useState(101);
  const [chartUnit, setChartUnit] = useState('℉');

  const [temperatureDataMeanArray, setTemperatureDataMeanArray] = useState([]);
  const [temperatureDataChannelUpperArray, setTemperatureDataChannelUpperArray] = useState([]);
  const [temperatureDataChannelLowerArray, setTemperatureDataChannelLowerArray] = useState([]);
  const [temperatureDataPersonalWarningArray, setTemperatureDataPersonalWarningArray] = useState([]);
  const [temperatureDataPersonalWarningArrayOld, setTemperatureDataPersonalWarningArrayOld] = useState([]);

  const [temperatureDataProvisionalWarningArray, setTemperatureDataProvisionalWarningArray] = useState([]);
  const [temperatureDataCDCFeverArray, setTemperatureDataCDCFeverArray] = useState([]);

  const [showGraphSettings, setShowGraphSettings] = useState(false);

  const [showOverlay, setShowOverlay] = useState(true);
  
  const [hasUpdatedPlottingUnit, setHasUpdatedPlottingUnit] = useState(false);
  const [preferredPlottingUnit, setPreferredPlottingUnit] = useState('fahrenheit');

  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));  // 30 days

  const [assessmentData, setAssessmentData] = useState([]);
  
  const [plottingData, setPlottingData] = useState([]);

  const [lastUpdateString, setLastUpdateString] = useState('');

  const [recalculationWindow, setRecalculationWindow] = useState('morning');

  const [chartHeight, setChartHeight] = useState(350);

  const [recalculatePWT, setRecalculatePWT] = useState(false);
  
  const [recalculationDescription, setRecalculationDescription] = useState('');


  function fahrenheitToCelsius(fahrenheit: number) {
    return ((fahrenheit - 32.0) * (5.0 / 9.0 ))
  }

  function celsiusToFahrenheit(celsius: number) {
    return ((celsius * 9.0 / 5.0 ) + 32.0)
  }

  function updatePlottingUnit(unit) {
    setHasUpdatedPlottingUnit(true);
    setPreferredPlottingUnit(unit);
  }

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = '' + d.getFullYear(),
        hour = '' + d.getHours(),
        minutes = '' + d.getMinutes(),
        seconds = '' +  d.getSeconds();
  
    var timeSuffix = 'AM'
    if (hour === String(12)) {
        timeSuffix = 'PM'
    } else if (hour > 12) {
        hour = hour - 12;
        timeSuffix = 'PM'
    } else if (hour === String(0)) {
        hour = 12;
    }

    if (day.length < 2) 
        day = '0' + day;
    
    if (hour.length < 2) {
        hour = '0' + hour;
    }
    if (minutes.length < 2) {
        minutes = '0' + minutes;
    }
    if (seconds.length < 2) {
        seconds = '0' + seconds;
    }

    var formattedString = `${month}/${day}/${year}\n${hour}:${minutes}:${seconds} ${timeSuffix}`;
    return formattedString;
  }

  function resetData() {
    setMeasuredTemperature(0);
    setWarningTemperature(0);
    setRecalculatedWarningTemperature(0);
    setTemperatureDataMean(0);
    setRecalculatedTemperatureDataMean(0);
    setProvisionalWarningTemperature(PROVISIONAL_TEMPERATURE_FAHRENHEIT);
    setChartUpper(101);
    setChartLower(96);
    setChartUnit('℉');
    setLastUpdateString('');

    setTemperatureData([]);
    setTemperatureDataCDCFeverArray([]);
    setTemperatureDataPersonalWarningArray([]); 
    setTemperatureDataProvisionalWarningArray([]); 
    setTemperatureDataChannelUpperArray([]); 
    setTemperatureDataChannelLowerArray([]); 
    setTemperatureDataMeanArray([]); 
  }
  
  function ProvisionalChart() {
    return (
      <ReactEcharts style={{minHeight:chartHeight, width:'99%'}}
        option={{
                grid: {
                containLabel: true
                },
                title: {
                text: `Temperature Chart (Provisional)`,
                subtextStyle:{color: 'black'},
                },
                tooltip: {
                show: showOverlay,
                trigger: 'axis',
                axisPointer:{
                    label:{
                    formatter: function (value, index) {
                        // Formatted to be month/day; display year only in the first label
                        var date = new Date(value.value);
                        return date.toLocaleString(undefined, { timeZoneName: 'short' });
                      }
                    }
                }
                },
                legend: {
                type: 'scroll',
                orient: 'horizontal',
                right: 10,
                top: 20,
                bottom: 20,
                icon:'rect'
                },
            xAxis: {
                nameLocation: 'center',
                nameTextStyle: {padding: [0, 0, 0, 0]}, //[top, right, bottom, left]
                nameGap: 40,
                type: 'time',
                axisLabel: {
                show: true,
                rotate: 45,
                padding: [20,0,0,0],
                formatter: function (value, index) {
                    var date = new Date(value);                        
                    return formatDate(date.toString());
                }
                }
            },
                yAxis: {
                name: `Temperature (${chartUnit})`,
                nameLocation: 'center',
                nameGap: 40,
                type: 'value',
                max: chartUpper,
                min: chartLower,
                axisLabel: {
                    show: true
                },
                minorTick: {
                    show: true
                }
                },
                series: [
                { 
                  data: temperatureData,
                  type: 'line',
                  color: 'blue',
                  name: 'Temperature',
                },
                { 
                data: temperatureDataProvisionalWarningArray,
                type: 'line',
                color:'orange',
                name: 'PWT',
                showSymbol: false,
                showLegendSymbol: true,
                },
                { 
                data: temperatureDataCDCFeverArray,
                type: 'line',
                color:'red',
                name: 'CDC Fever',
                showSymbol: false,
                showLegendSymbol: true,
                },
            ],
        }}
    />);
}

  function ProfileChart() {
  return (
    <ReactEcharts style={{minHeight:chartHeight, width:'99%'}}
        option={{
            grid: {
            containLabel: true
            },
            title: {
            text: `Temperature Chart`,
            subtextStyle:{color: 'black'},
            },
            tooltip: {
            show: showOverlay,
            trigger: 'axis',
            axisPointer:{
                label:{
                formatter: function (value, index) {
                    // Formatted to be month/day; display year only in the first label
                    var date = new Date(value.value);
                    return date.toLocaleString(undefined, { timeZoneName: 'short' });
                  }
                }
            }
            },
            legend: {
            type: 'scroll',
            orient: 'horizontal',
            right: 10,
            top: 20,
            bottom: 20,
            icon:'rect'
        },
            xAxis: {
            nameLocation: 'center',
            nameTextStyle: {padding: [0, 0, 0, 0]}, //[top, right, bottom, left]
            nameGap: 40,
            type: 'time',
            axisLabel: {
                show: true,
                rotate: 45,
                padding: [20,0,0,0],
                formatter: function (value, index) {
                var date = new Date(value);                        
                return formatDate(date.toString());
                }
            }
            },
            yAxis: {
            name: `Temperature (${chartUnit})`,
            nameLocation: 'center',
            nameTextStyle: {padding: [0, 0, 0, 0]}, //[top, right, bottom, left]
            nameGap: 40,
            type: 'value',
            max: chartUpper,
            min: chartLower,
            axisLabel: {
                show: true
            },
            minorTick: {
                show: true
            }
            },
            series: [
            { 
                data: temperatureData,
                type: 'line',
                color: 'blue',
                name: 'Temperature',                    
            },
            { 
                data: temperatureDataPersonalWarningArray,
                type: 'line',
                color:'orange',
                name: 'PWT',
                showSymbol: false
            },
            { 
                data: temperatureDataCDCFeverArray,
                type: 'line',
                color:'red',
                name: 'CDC Fever',
                showSymbol: false,
                
            },
            { 
                data: temperatureDataChannelUpperArray,
                type: 'line',
                color:'green',
                name: 'Channel',
                showSymbol: false,
                showLegendSymbol: false,
                lineStyle: {
                  type: "dashed"
                }
            },
            { 
                data: temperatureDataChannelLowerArray,
                type: 'line',
                color:'green',
                name: 'Channel',
                showSymbol: false,
                showLegendSymbol: false,
                lineStyle: {
                  type: "dashed"
                } 
            },
            { 
                data: temperatureDataMeanArray,
                type: 'line',
                color:'black',
                name: 'Average',
                showSymbol: false,
                lineStyle: {
                type: "dotted"
                }
            },
        ],
                  
        }}
      />

  );
}

useEffect(() => { 
  function loadAssessmentData() {
    setShowBusy(true);
    if (props.uid === "") {
      setShowBusy(false);
      return;
    }
    firebase.firestore().collection('users/'+props.uid+"/temperature_data/")
    .orderBy("timestamp", 'desc')
    .limit(1)
    .get()
    .then(function(querySnapshot) {
        var entry = [];
        querySnapshot.forEach(function(doc) {
          entry.push(doc.data());
        });
        setAssessmentData(entry);
        setShowBusy(false);
    })
    .catch(function(error){
      setShowBusy(false);
    });
  }

  loadAssessmentData();
}, [props.uid]);

useEffect(() => { 
  async function loadPlottingData() {
    setShowBusy(true);
    // Fetch up-to-date health metrics from the latest temperature entry
    if (assessmentData.length < 1) {
      resetData();
      setShowBusy(false);
      return;
    } else {
      var localStartDate = startDate;
      var localEndDate = endDate;
      localStartDate.setHours(0,0,0,0);
      localEndDate.setHours(23,59,59,999);
      var plottingEntries = [];
      if (props.uid === "") {
        setShowBusy(false);
        return;
      }
      var plottingQuery = await firebase.firestore().collection('users/'+props.uid+"/temperature_data/")
      .where("timestamp", ">=", firebase.firestore.Timestamp.fromDate(localStartDate))
      .where("timestamp", "<=", firebase.firestore.Timestamp.fromDate(localEndDate))
      .orderBy("timestamp", 'desc')
      .get()
      await Promise.all(plottingQuery.docs.map(async (doc) => {
        plottingEntries.push(doc.data());
      }));
      setPlottingData(plottingEntries);
      setShowBusy(false);
    }
    
  }

  async function loadPlottingDataRecalculatePWT() {
    setShowBusy(true);
    // Fetch up-to-date health metrics from the latest temperature entry
    if (assessmentData.length < 1) {
      setShowBusy(false);
      return;
    } else {
      var plottingEntries = [];

      if (props.uid === "") {
        setShowBusy(false);
        return;
      }
      var plottingQuery = await firebase.firestore().collection('users/'+props.uid+"/temperature_data/")
      .where("timestamp", "<=", firebase.firestore.Timestamp.fromDate(endDate))
      .orderBy("timestamp", 'desc')
      .get()
      await Promise.all(plottingQuery.docs.map(async (doc) => {
        // Code for time splice experiment
        var testDate = new Date(doc.data().timestamp.seconds * 1000);
        var windowLower = 0;  // Hour
        var windowUpper = 0;  // Hour
        var windowLowerMin = 0;  // Minute
        var windowUpperMin = 0;  // Minute
        if (recalculationWindow === 'morning') {
          windowLower = 5;
          windowLowerMin = 30;

          windowUpper = 7;
          windowUpperMin = 0;

          setRecalculationDescription(`Morning Window: includes data recorded between ${windowLower}:30 - ${windowUpper}:00 AM.`)
        } else if (recalculationWindow === 'afternoon') {
          setRecalculationDescription('Afternoon Window: includes data recorded between 12 AM - 4 PM.')
          windowLower = 12;
          windowUpper = 16;
        } else if (recalculationWindow === 'evening') {
          setRecalculationDescription('Evening Window: includes data recorded between 7 PM - 12 AM.')
          windowLower = 19;
          windowUpper = 24;
        }
        else if (recalculationWindow === 'all') {
          setRecalculationDescription('Full Window: includes all recorded data.')
          windowLower = 0;
          windowUpper = 24;
        }
        // console.log(testDate);
        var compMinDate = new Date(testDate);
        compMinDate.setMilliseconds(0);
        compMinDate.setSeconds(0);
        compMinDate.setMinutes(windowLowerMin);
        compMinDate.setHours(windowLower);
        

        // console.log(compMinDate)

        var compMaxDate = new Date(testDate);
        compMaxDate.setMilliseconds(0);
        compMaxDate.setSeconds(0);
        compMaxDate.setMinutes(windowUpperMin);
        compMaxDate.setHours(windowUpper);
        // console.log(compMaxDate)

        if ((testDate >= compMinDate) && (testDate <= compMaxDate)) {
          plottingEntries.push(doc.data());
        }


        // if (((testDate.getHours() >= windowLower) && (testDate.getMinutes() >= windowLowerMin) ) && ((testDate.getHours() <= windowUpper) && (testDate.getMinutes() <= windowUpperMin))) {
        //   plottingEntries.push(doc.data());
        // }
      }));
      setPlottingData(plottingEntries);
      setShowBusy(false);
    }
    
  }
  if (recalculatePWT === true) {
    loadPlottingDataRecalculatePWT();
  } else {
    loadPlottingData();
  }
}, [assessmentData, startDate, endDate, recalculatePWT, recalculationWindow]); // eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
  function makeGraph() {
    setShowBusy(true);
    if (assessmentData.length < 1) {
      resetData();
      setShowBusy(false);
      return;
    } else {
        const parsedTemperatureData: { temperature: string; unit: string; timestamp: string; docID: string}[] = [];
        let temperatures: any[] = [];
        let pwtLastUpdate = assessmentData[0];
        let plottingUnit = ''
        if (hasUpdatedPlottingUnit === true) {
          plottingUnit = preferredPlottingUnit;
        } else {
          plottingUnit = pwtLastUpdate.unit;
        }
         
        let plottingEntries = plottingData;
        // Prep all data for the user
        plottingEntries.forEach(function(doc) {
            var currentTemp = doc.temperature;
            var currentUnit = doc.unit;
            if (plottingUnit === 'fahrenheit') {
                if (currentUnit === 'celsius') {
                  currentTemp = Number(Math.round(parseFloat(celsiusToFahrenheit(parseFloat(currentTemp)) + 'e' + 2)) + 'e-' + 2)
                }
            } else if (plottingUnit === 'celsius') {  
                if (currentUnit === 'fahrenheit') {
                    currentTemp = Number(Math.round(parseFloat(fahrenheitToCelsius(parseFloat(currentTemp)) + 'e' + 2)) + 'e-' + 2)
                }
            }
            temperatures.push(currentTemp);
            parsedTemperatureData.push({
                temperature: currentTemp,
                unit: currentUnit,
                timestamp: doc.timestamp,
                docID: doc.id
            });
        });

        let tempMeasuredTemperature = 0;
        let tempCDCFever = 0;
        let tempChannelUpper = 0;
        let tempChannelLower = 0;
        let tempChartUpper = 0;
        let tempChartLower = 0;
        let tempProvisionalWarningTemperature = 0;
        let tempPersonalWarningTemperature = 0;
        let tempTemperatureDataMeanPlotting = 0;
        let tempProvisionalWarningTemperaturePlotting = 0;
        let tempPersonalWarningTemperaturePlotting = 0;
        let tempTotalEntries = 0;
        let tempHealthyEntries = 0;
        let tempStatusTitle = '';
        let tempStatusMessage = '';
        let tempStatus = '';
        let tempChartUnit = '';
        let tempLastUpdateDate = '';

        tempMeasuredTemperature = pwtLastUpdate.temperature;
        tempPersonalWarningTemperature = pwtLastUpdate.health_assessment.personal_warning_temperature
        tempTemperatureDataMeanPlotting = pwtLastUpdate.health_assessment.mean;
        tempPersonalWarningTemperaturePlotting = pwtLastUpdate.health_assessment.personal_warning_temperature
        
        tempChannelUpper = pwtLastUpdate.health_assessment.channel_upper;
        tempChannelLower = pwtLastUpdate.health_assessment.channel_lower;
        tempTotalEntries = pwtLastUpdate.health_assessment.total_temperature_entries;
        tempHealthyEntries = pwtLastUpdate.health_assessment.healthy_entries;
        tempStatusTitle = pwtLastUpdate.health_assessment.title;
        tempStatusMessage = pwtLastUpdate.health_assessment.message;
        tempStatus = pwtLastUpdate.health_assessment.status;
        tempLastUpdateDate = new Date((assessmentData[0].timestamp.seconds * 1000)).toLocaleString(undefined, { timeZoneName: 'short' });

        // Conversions
        if (pwtLastUpdate.unit === 'fahrenheit') {
          tempProvisionalWarningTemperature = PROVISIONAL_TEMPERATURE_FAHRENHEIT;
          tempProvisionalWarningTemperaturePlotting = PROVISIONAL_TEMPERATURE_FAHRENHEIT;
        } else if (pwtLastUpdate.unit === 'celsius') {
          tempProvisionalWarningTemperature = PROVISIONAL_TEMPERATURE_CELSIUS;
          tempProvisionalWarningTemperaturePlotting = PROVISIONAL_TEMPERATURE_CELSIUS;
        }

        if (plottingUnit === 'fahrenheit') {
          tempChartUnit = '℉';
          if (pwtLastUpdate.unit === 'fahrenheit') {
            // do nothing
          } else if (pwtLastUpdate.unit === 'celsius') {
            tempMeasuredTemperature = celsiusToFahrenheit(parseFloat(tempMeasuredTemperature));
            tempTemperatureDataMeanPlotting = celsiusToFahrenheit(parseFloat(tempTemperatureDataMeanPlotting));
            tempPersonalWarningTemperaturePlotting = celsiusToFahrenheit(parseFloat(tempPersonalWarningTemperaturePlotting));
            tempProvisionalWarningTemperaturePlotting = celsiusToFahrenheit(parseFloat(tempProvisionalWarningTemperaturePlotting));
            tempChannelUpper = celsiusToFahrenheit(parseFloat(tempChannelUpper));
            tempChannelLower = celsiusToFahrenheit(parseFloat(tempChannelLower));
          }
        } else if (plottingUnit === 'celsius') {
          tempChartUnit = '℃';
          if (pwtLastUpdate.unit === 'fahrenheit') {
            tempMeasuredTemperature = fahrenheitToCelsius(parseFloat(tempMeasuredTemperature));
            tempTemperatureDataMeanPlotting = fahrenheitToCelsius(parseFloat(tempTemperatureDataMeanPlotting));
            tempPersonalWarningTemperaturePlotting = fahrenheitToCelsius(parseFloat(tempPersonalWarningTemperaturePlotting));
            tempProvisionalWarningTemperaturePlotting = fahrenheitToCelsius(parseFloat(tempProvisionalWarningTemperaturePlotting));
            tempChannelUpper = fahrenheitToCelsius(parseFloat(tempChannelUpper));
            tempChannelLower = fahrenheitToCelsius(parseFloat(tempChannelLower));
          } else if (pwtLastUpdate.unit === 'celsius') {
            // do nothing
          }
        }

        // Calculate bounds for the plot
        let max = Math.max(...temperatures);
        let min = Math.min(...temperatures);

        if (tempPersonalWarningTemperature !== 0) {
          max = Math.max(max, tempPersonalWarningTemperaturePlotting, tempChannelUpper);
          min = Math.min(min, tempChannelLower);
        }
        
        if (plottingUnit === 'fahrenheit') {
          if (max >= CDC_FEVER_FAHRENHEIT) {
            tempChartUpper = Math.round(max) + 1;
          } else {
            tempChartUpper = CHART_DEFAULT_MAXIMUM_FAHRENHEIT;
          }
          if (min <= (CHART_DEFAULT_MINIMUM_FAHRENHEIT + 0.8)) {
            tempChartLower = Math.round(min) - 1;
          } else {
            tempChartLower = CHART_DEFAULT_MINIMUM_FAHRENHEIT;
          }
          tempCDCFever = CDC_FEVER_FAHRENHEIT;
        } else {
          if (max >= CDC_FEVER_CELSIUS) {
            tempChartUpper = Math.round(max) + 1;
          } else {
            tempChartUpper = CHART_DEFAULT_MAXIMUM_CELSIUS;
          }
          if (min <= (CHART_DEFAULT_MINIMUM_CELSIUS + 0.5)) {
            tempChartLower = Math.round(min) - 1;
          } else {
            tempChartLower = CHART_DEFAULT_MINIMUM_CELSIUS;
          }
          tempCDCFever = CDC_FEVER_CELSIUS;
        }

        // Rounding
        tempMeasuredTemperature = Number(Math.round(parseFloat(tempMeasuredTemperature + 'e' + 2)) + 'e-' + 2);
        tempPersonalWarningTemperaturePlotting = Number(Math.round(parseFloat(tempPersonalWarningTemperaturePlotting + 'e' + 2)) + 'e-' + 2);
        tempProvisionalWarningTemperaturePlotting = Number(Math.round(parseFloat(tempProvisionalWarningTemperaturePlotting + 'e' + 2)) + 'e-' + 2);
        tempTemperatureDataMeanPlotting = Number(Math.round(parseFloat(tempTemperatureDataMeanPlotting + 'e' + 2)) + 'e-' + 2);
        tempChannelUpper = Number(Math.round(parseFloat(tempChannelUpper + 'e' + 2)) + 'e-' + 2);
        tempChannelLower = Number(Math.round(parseFloat(tempChannelLower + 'e' + 2)) + 'e-' + 2);
        tempCDCFever = Number(Math.round(parseFloat(tempCDCFever + 'e' + 2)) + 'e-' + 2);


        let temperatureDataArray = [];
        let tempTemperatureDataMeanArray = [];
        let tempTemperatureDataChannelUpperArray = [];
        let tempTemperatureDataChannelLowerArray = [];
        let tempTemperatureDataPersonalWarningArray = [];
        let tempTemperatureDataProvisionalWarningArray = [];
        let tempTemperatureDataCDCFeverArray = [];

        parsedTemperatureData.forEach(temperatureEntry => {
          temperatureDataArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, temperatureEntry.temperature]);
          tempTemperatureDataMeanArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempTemperatureDataMeanPlotting]);
          tempTemperatureDataChannelUpperArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempChannelUpper]);
          tempTemperatureDataChannelLowerArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempChannelLower]);
          tempTemperatureDataPersonalWarningArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempPersonalWarningTemperaturePlotting]);
          tempTemperatureDataProvisionalWarningArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempProvisionalWarningTemperaturePlotting]);
          tempTemperatureDataCDCFeverArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempCDCFever]);
        });

        // Messages
        setMeasuredTemperature(tempMeasuredTemperature);
        setTemperatureDataMean(tempTemperatureDataMeanPlotting);
        setWarningTemperature(tempPersonalWarningTemperaturePlotting);
        setTotalTemperatureEntries(tempTotalEntries);
        setTotalHealthyEntries(tempHealthyEntries);
        setHealthTitle(tempStatusTitle);
        setHealthMessage(tempStatusMessage);
        setHealthStatus(tempStatus);
        setLastUpdateString(tempLastUpdateDate);
        
        // Plotting
        setChartUnit(tempChartUnit);
        setProvisionalWarningTemperature(tempProvisionalWarningTemperature);
        setChartUpper(tempChartUpper);
        setChartLower(tempChartLower);
        setTemperatureData(temperatureDataArray.reverse());
        
        setTemperatureDataCDCFeverArray(tempTemperatureDataCDCFeverArray.reverse());
        setTemperatureDataPersonalWarningArray(tempTemperatureDataPersonalWarningArray.reverse()); 
        setTemperatureDataProvisionalWarningArray(tempTemperatureDataProvisionalWarningArray.reverse()); 

        setTemperatureDataChannelUpperArray(tempTemperatureDataChannelUpperArray.reverse()); 
        setTemperatureDataChannelLowerArray(tempTemperatureDataChannelLowerArray.reverse()); 
        setTemperatureDataMeanArray(tempTemperatureDataMeanArray.reverse()); 

        if (plottingUnit !== preferredPlottingUnit) {
          setPreferredPlottingUnit(plottingUnit);
        }
        setShowBusy(false);
    }
  }


  function makeGraphRecalculatePWT() {
    setShowBusy(true);
    if (assessmentData.length < 1) {
      resetData();
      setShowBusy(false);
      return;
    } else {
        const parsedTemperatureData: { temperature: string; unit: string; timestamp: string; docID: string}[] = [];
        let temperatures: any[] = [];
        let pwtLastUpdate = assessmentData[0];
        let plottingUnit = preferredPlottingUnit;
        let plottingEntries = plottingData;
        
        // Prep all data for the user
        plottingEntries.forEach(function(doc) {
          var currentTemp = doc.temperature;
          var currentUnit = doc.unit;
          if (plottingUnit === 'fahrenheit') {
              if (currentUnit === 'celsius') {
                currentTemp = Number(Math.round(parseFloat(celsiusToFahrenheit(parseFloat(currentTemp)) + 'e' + 2)) + 'e-' + 2)
              }
          } else if (plottingUnit === 'celsius') {  
              if (currentUnit === 'fahrenheit') {
                currentTemp = Number(Math.round(parseFloat(fahrenheitToCelsius(parseFloat(currentTemp)) + 'e' + 2)) + 'e-' + 2)
              }
          }
          temperatures.push(currentTemp);
          parsedTemperatureData.push({
              temperature: currentTemp,
              unit: currentUnit,
              timestamp: doc.timestamp,
              docID: doc.id
          });
        });

        let newMean = 0;
        let newStd = 0;
        let newWarningTemperature = 0;
        let newChannelUpper = 0;
        let newChannelLower = 0;

        // Recalculations
        if (parsedTemperatureData.length > 1) {
          newMean = mean(temperatures);
          newStd = std(temperatures);
          newWarningTemperature = (newMean + (2.5 * newStd));
          newChannelUpper = (newMean + (newStd * 2.0));
          newChannelLower = (newMean - (newStd * 2.0));
        }
        
        let tempMeasuredTemperature = 0;
        let tempCDCFever = 0;
        let tempChannelUpper = 0;
        let tempChannelLower = 0;
        let tempChartUpper = 0;
        let tempChartLower = 0;
        let tempTemperatureDataMean = 0;
        let tempTemperatureDataMeanOld = 0;
        let tempProvisionalWarningTemperature = 0;
        let tempPersonalWarningTemperature = 0;
        let tempPersonalWarningTemperatureOld = 0;
        let tempTemperatureDataMeanPlotting = 0;
        let tempProvisionalWarningTemperaturePlotting = 0;
        let tempPersonalWarningTemperaturePlotting = 0;
        let tempTotalEntries = 0;
        let tempHealthyEntries = 0;
        let tempStatusTitle = '';
        let tempStatusMessage = '';
        let tempStatus = '';
        let tempChartUnit = '';
        let tempLastUpdateDate = '';

        tempTemperatureDataMean = newMean;
        tempTemperatureDataMeanOld = pwtLastUpdate.health_assessment.mean;

        tempPersonalWarningTemperature = newWarningTemperature;
        tempPersonalWarningTemperatureOld = pwtLastUpdate.health_assessment.personal_warning_temperature

        tempTemperatureDataMeanPlotting = newMean;
        tempPersonalWarningTemperaturePlotting = newWarningTemperature;
        
        tempChannelUpper = newChannelUpper;
        tempChannelLower = newChannelLower;

        // Conversions
        if (plottingUnit === 'fahrenheit') {
          tempProvisionalWarningTemperaturePlotting = PROVISIONAL_TEMPERATURE_FAHRENHEIT;
          tempChartUnit = '℉';
          if (pwtLastUpdate.unit === 'celsius') {
            tempTemperatureDataMeanOld = celsiusToFahrenheit(parseFloat(tempTemperatureDataMeanOld));
            tempPersonalWarningTemperatureOld = celsiusToFahrenheit(parseFloat(tempPersonalWarningTemperatureOld));
          }
        } else if (plottingUnit === 'celsius') {
          tempProvisionalWarningTemperaturePlotting = PROVISIONAL_TEMPERATURE_CELSIUS;
          tempChartUnit = '℃';
          if (pwtLastUpdate.unit === 'fahrenheit') {
            tempTemperatureDataMeanOld = fahrenheitToCelsius(parseFloat(tempTemperatureDataMeanOld));
            tempPersonalWarningTemperatureOld = fahrenheitToCelsius(parseFloat(tempPersonalWarningTemperatureOld));
          }
        }

        // Calculate bounds for the plot
        let max = Math.max(...temperatures);
        let min = Math.min(...temperatures);

        if (tempPersonalWarningTemperature !== 0) {
          max = Math.max(max, tempPersonalWarningTemperaturePlotting, tempChannelUpper);
          min = Math.min(min, tempChannelLower);
        }
        
        if (plottingUnit === 'fahrenheit') {
          if (max >= CDC_FEVER_FAHRENHEIT) {
            tempChartUpper = Math.round(max) + 1;
          } else {
            tempChartUpper = CHART_DEFAULT_MAXIMUM_FAHRENHEIT;
          }
          if (min <= (CHART_DEFAULT_MINIMUM_FAHRENHEIT + 0.8)) {
            tempChartLower = Math.round(min) - 1;
          } else {
            tempChartLower = CHART_DEFAULT_MINIMUM_FAHRENHEIT;
          }
          tempCDCFever = CDC_FEVER_FAHRENHEIT;
        } else {
          if (max >= CDC_FEVER_CELSIUS) {
            tempChartUpper = Math.round(max) + 1;
          } else {
            tempChartUpper = CHART_DEFAULT_MAXIMUM_CELSIUS;
          }
          if (min <= (CHART_DEFAULT_MINIMUM_CELSIUS + 0.5)) {
            tempChartLower = Math.round(min) - 1;
          } else {
            tempChartLower = CHART_DEFAULT_MINIMUM_CELSIUS;
          }
          tempCDCFever = CDC_FEVER_CELSIUS;
        }

        // Rounding
        tempMeasuredTemperature = Number(Math.round(parseFloat(tempMeasuredTemperature + 'e' + 2)) + 'e-' + 2);
        tempPersonalWarningTemperaturePlotting = Number(Math.round(parseFloat(tempPersonalWarningTemperaturePlotting + 'e' + 2)) + 'e-' + 2);
        tempProvisionalWarningTemperaturePlotting = Number(Math.round(parseFloat(tempProvisionalWarningTemperaturePlotting + 'e' + 2)) + 'e-' + 2);
        tempTemperatureDataMeanPlotting = Number(Math.round(parseFloat(tempTemperatureDataMeanPlotting + 'e' + 2)) + 'e-' + 2);
        tempChannelUpper = Number(Math.round(parseFloat(tempChannelUpper + 'e' + 2)) + 'e-' + 2);
        tempChannelLower = Number(Math.round(parseFloat(tempChannelLower + 'e' + 2)) + 'e-' + 2);
        tempCDCFever = Number(Math.round(parseFloat(tempCDCFever + 'e' + 2)) + 'e-' + 2);
        tempTemperatureDataMeanOld = Number(Math.round(parseFloat(tempTemperatureDataMeanOld + 'e' + 2)) + 'e-' + 2);
        tempPersonalWarningTemperatureOld = Number(Math.round(parseFloat(tempPersonalWarningTemperatureOld + 'e' + 2)) + 'e-' + 2);

          // Building Arrays
        let temperatureDataArray = [];
        let tempTemperatureDataMeanArray = [];
        let tempTemperatureDataChannelUpperArray = [];
        let tempTemperatureDataChannelLowerArray = [];
        let tempTemperatureDataPersonalWarningArray = [];
        let tempTemperatureDataPersonalWarningArrayOld = [];
        let tempTemperatureDataProvisionalWarningArray = [];
        let tempTemperatureDataCDCFeverArray = [];


        parsedTemperatureData.forEach(temperatureEntry => {
          temperatureDataArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, temperatureEntry.temperature]);
          tempTemperatureDataMeanArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempTemperatureDataMeanPlotting]);
          tempTemperatureDataChannelUpperArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempChannelUpper]);
          tempTemperatureDataChannelLowerArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempChannelLower]);
          tempTemperatureDataPersonalWarningArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempPersonalWarningTemperaturePlotting]);
          tempTemperatureDataPersonalWarningArrayOld.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempPersonalWarningTemperatureOld]);
          tempTemperatureDataProvisionalWarningArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempProvisionalWarningTemperaturePlotting]);
          tempTemperatureDataCDCFeverArray.push([new Date(temperatureEntry.timestamp.seconds) * 1000, tempCDCFever]);
        });

        // Messages
        setTemperatureDataMean(tempTemperatureDataMeanOld);
        setRecalculatedTemperatureDataMean(tempTemperatureDataMeanPlotting);
        setWarningTemperature(tempPersonalWarningTemperatureOld);
        setRecalculatedWarningTemperature(tempPersonalWarningTemperaturePlotting)
        setTotalTemperatureEntries(tempTotalEntries);
        setTotalHealthyEntries(tempHealthyEntries);
        setHealthTitle(tempStatusTitle);
        setHealthMessage(tempStatusMessage);
        setHealthStatus(tempStatus);
        setLastUpdateString(tempLastUpdateDate);
        
        // Plotting
        setChartUnit(tempChartUnit);
        setProvisionalWarningTemperature(tempProvisionalWarningTemperature);
        setChartUpper(tempChartUpper);
        setChartLower(tempChartLower);
        setTemperatureData(temperatureDataArray.reverse());
        
        setTemperatureDataCDCFeverArray(tempTemperatureDataCDCFeverArray.reverse());
        setTemperatureDataPersonalWarningArray(tempTemperatureDataPersonalWarningArray.reverse()); 
        setTemperatureDataPersonalWarningArrayOld(tempTemperatureDataPersonalWarningArrayOld.reverse()); 

        setTemperatureDataProvisionalWarningArray(tempTemperatureDataProvisionalWarningArray.reverse()); 

        setTemperatureDataChannelUpperArray(tempTemperatureDataChannelUpperArray.reverse()); 
        setTemperatureDataChannelLowerArray(tempTemperatureDataChannelLowerArray.reverse()); 
        setTemperatureDataMeanArray(tempTemperatureDataMeanArray.reverse()); 

        if (plottingUnit !== preferredPlottingUnit) {
          setPreferredPlottingUnit(plottingUnit);
        }

        setShowBusy(false);
      }
  }
  if (recalculatePWT === true) {
    makeGraphRecalculatePWT();
  } else {
    makeGraph();
  }
}, [plottingData, preferredPlottingUnit, hasUpdatedPlottingUnit]);  // eslint-disable-line react-hooks/exhaustive-deps

  if (showBusy) {
    return (
            <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
          <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
            <h4>Loading assessment...</h4>
              <Dots color="#21436D" size={32} speed={1} animating={showBusy} />
          </div>
      </div>
      );
  } else {
    return (
            <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
        <h2>Health Assessment</h2>
        {(assessmentData.length < 1)
          ? <p>No data found.</p>
          : 
          <div>
            {(recalculatePWT === true)
              ? 
              <div>
                <h4>{`${"PWT Recalculation"}`}</h4>
                <p><b>{`Details`}</b>{`: ${recalculationDescription}`}</p>
                <p><b>{`New Personal Warning Temperature`}</b>{`: ${recalculatedWarningTemperature} ${chartUnit}`}</p>
                <p><b>{`Old Personal Warning Temperature`}</b>{`: ${warningTemperature} ${chartUnit}`}</p>
                <p><b>{`New Average Temperature`}</b>{`: ${recalculatedTemperatureDataMean} ${chartUnit}`}</p> 
                <p><b>{`Old Average Temperature`}</b>{`: ${temperatureDataMean} ${chartUnit}`}</p> 
              </div>
              : 
              <div>
                {(warningTemperature !== 0)
                  ? <div>
                    <h4>{`${healthTitle}`}</h4>
                    <p><b>{`Measured Temperature`}</b>{`: ${measuredTemperature} ${chartUnit}`}</p>
                    <p><b>{`Details`}</b>{`: ${healthMessage}`}</p>
                    <p><b>{`Personal Warning Temperature`}</b>{`: ${warningTemperature} ${chartUnit}`}</p>
                    <p><b>{`Average Temperature`}</b>{`: ${temperatureDataMean} ${chartUnit}`}</p> 
                    <p><b>{`Updated`}</b>{`: ${lastUpdateString}`}</p>
                    </div>
                  : <div>
                    <h4>{`${healthTitle}`}</h4>
                    <p><b>{`Measured Temperature`}</b>{`: ${measuredTemperature} ${chartUnit}`}</p>
                    <p><b>{`Details`}</b>{`: ${healthMessage}`}</p>
                    <p><b>{`Provisional Warning Temperature`}</b>{`: ${provisionalWarningTemperature} ${chartUnit}`}</p>
                    <p>Use this provisional temperature as a guide until you have enough temperature entries to create your own Personal Warning Temperature.</p>
                    <p><b>{`Updated`}</b>{`: ${lastUpdateString}`}</p>
                    </div> 
                }
              </div> 
            }
          
            {(plottingData.length < 1)
              ? 
              <p>No data found to plot for the specified window.</p>
              : 
              <div>
                {(warningTemperature !== 0)
                  ? 
                    <ProfileChart></ProfileChart>
                  : 
                    <ProvisionalChart></ProvisionalChart>
                }
              </div>
            }
            <div style={{paddingTop: 10, paddingBottom:20}}>
                  <Button className='pwt-button' block size="sm" type="button" onClick={() => setShowGraphSettings(!showGraphSettings)}>
                  {(showGraphSettings)
                      ? 'Hide Settings'
                      : 'Show Settings'
                  }
                  </Button>
            </div>
            {(!showGraphSettings)
                ? null
                : 
                <div style={{borderRadius:"25px", padding:20}}> 
                  <h4>Configuration</h4>
                  
                <Row>
                  <Col>
                    <Row>
                      <div style={{padding: 10}}>
                        <label>Plotting Start Date:</label>
                        <div style={{paddingTop:4}}>
                          <DatePicker disabled={recalculatePWT} selected={startDate} onChange={date => setStartDate(date)} />
                          </div>
                      </div>
                    </Row>
                    <Row>  
                      <div style={{padding: 10}}>
                        <label>Plotting End Date:</label>
                        <div style={{paddingTop:4}}>
                          <DatePicker disabled={recalculatePWT} selected={endDate} onChange={date => setEndDate(date)}/>
                        </div>
                      </div>
                    </Row>
                  </Col>
                  <Col sm = {true}>
                    <Row>
                        <div style={{padding: 10}}>
                          <label>Detail Overlay:</label>
                          <Button className='pwt-button' block size="sm"  type="button" onClick={() => setShowOverlay(!showOverlay)}>
                            {(showOverlay)
                            ? 'Disable'
                            : 'Enable'
                            }
                          </Button>
                        </div>
                    </Row>
                    <Row >
                      <div style={{padding: 10}}>
                        <label>Plotting Unit:</label>
                        <select className="form-control" id="plottingUnitSelector" value={preferredPlottingUnit} onChange={ (e) => updatePlottingUnit(e.target.value)}>
                          <option value="fahrenheit">Fahrenheit</option>
                          <option value="celsius">Celsius</option>
                        </select>
                      </div>
                    </Row>
                  </Col>
                  <Col sm = {true}>
                    <Row>
                        <div style={{padding: 10}}>
                          <label>Recalculate PWT:</label>
                          <select className="form-control" id="recalculatePWTSelector" value={recalculatePWT} onChange={ (e) => setRecalculatePWT(e.target.value === 'true')}>
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </select>
                        </div>
                    </Row>
                    <Row>
                        <div style={{padding: 10}}>
                          <label>Recalculation Window:</label>
                          <select className="form-control" disabled={!recalculatePWT} id="recalculationWindowSelector" value={recalculationWindow} onChange={ (e) => setRecalculationWindow(e.target.value)}>
                            <option value='morning'>Morning</option>
                            <option value='afternoon'>Afternoon</option>
                            <option value='evening'>Evening</option>
                            <option value='all'>All</option>
                          </select>
                        </div>
                    </Row>
                  </Col>
                </Row>
              </div>
            }
          </div>
        }   
    </div>
    );
  }
}

function HistoryPageBase(props) {
  const [showError, setShowError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showBusy, setShowBusy] = useState(true);

  const [temperatureData, setTemperatureData] = useState([]);

  

  useEffect(() => { 
    function updateHistory() {
      setShowBusy(true);
  
      if (props.uid === "") {
        setShowBusy(false);
        return;
      }
  
      firebase.firestore().collection('users/'+props.uid+"/temperature_data/")
      .orderBy("timestamp", 'desc')
      .get()
      .then(function(querySnapshot) {
          setShowBusy(true);
          var entries = [];
          querySnapshot.forEach(function(doc) {
            entries.push(doc.data());
            entries[entries.length - 1].timestamp = (new Date(doc.data().timestamp.seconds * 1000).toString());
            Object.assign(entries[entries.length - 1], {index: querySnapshot.docs.length - entries.length + 1});
          });
          setTemperatureData(entries);
          setShowBusy(false);
      })
      .catch(function(error){
        setShowBusy(false);
      });
    }


    updateHistory();
    setShowBusy(false);
  }, [props]);

  if (showBusy) {
    return (
            <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
              <h4>Loading history...</h4>
              <Dots color="#21436D" size={32} speed={1} animating={showBusy} />
          </div>
        </div>
      );
  } else {
    return(
            <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h2>Temperature History</h2>
          {(temperatureData.length < 1)
                ? <p>No data found.</p>
                : 
                <div>
                    <table style={{width:'100%', borderCollapse:'collapse', border: '1px solid black'}}>         
                        <thead style={{display:'table', width: 'calc(100% - 17px)' }}>
                            <tr>
                              <th scope='col' style={{textAlign:'center', width: '33.33%', padding: '5px', wordBreak: 'break-all'}}>#</th>
                              <th scope='col' style={{textAlign:'center', width: '33.33%', padding: '5px', wordBreak: 'break-all'}}>Temperature</th>
                              <th scope='col' style={{textAlign:'center', width: '33.33%', padding: '5px', wordBreak: 'break-all'}}>Timestamp</th>
                            </tr>
                        </thead>
                        <tbody style={{display:'block', maxHeight:'300px',overflowY:'scroll'}}>
                        {temperatureData.map((temperatureEntry, key) => {
                            return (
                            <tr key={key} style={{display: 'table', width: '100%', boxSizing: 'border-box'}}>
                              <td style={{borderCollapse:'collapse', border:'1px solid black', borderLeft: 'none', borderBottom: 'none', textAlign:'center', width: '33.33%', padding: '5px', wordBreak: 'break-all'}}>{temperatureEntry.index}</td>
                              <td style={{borderCollapse:'collapse', border:'1px solid black', borderLeft: 'none', borderBottom: 'none', textAlign:'center', width: '33.33%', padding: '5px', wordBreak: 'break-all'}}>{temperatureEntry.temperature} {temperatureEntry.unit === 'fahrenheit' ? '℉' : '℃'}</td>
                              <td style={{borderCollapse:'collapse', border:'1px solid black', borderLeft: 'none', borderBottom: 'none', borderRight: 'none', textAlign:'center', width: '33.33%', padding: '5px', wordBreak: 'break-all'}}>{temperatureEntry.timestamp}</td>
                            </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    <div style={{display:"flex",flexDirection:"column", alignItems:'center', justifyContent:"center", minWidth:'50%'}}>
                    <CSVLink 
                      data={temperatureData} 
                      headers={headers} 
                      filename={"PWT_Temperature_History.csv"}
                      className="btn btn-primary btn-lg"
                      target="_blank">
                      Download as CSV
                    </CSVLink>
                    </div>
                  </div>
            }
            <div style={{paddingTop:20}}>
                <Alert show={showError} variant="danger">
                    <Alert.Heading>Error Fetching History</Alert.Heading>
                    <p>{errorMessage}</p>
                    <hr />
                    <div className="d-flex justify-content-end">
                      <Button onClick={() => setShowError(false)} variant="outline-danger">
                        Dismiss
                      </Button>
                    </div>
                </Alert>
            </div>
        </div>   
  );
  }
    
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(AdminPage);
