import React, { useEffect, useState } from 'react';

import { withAuthorization } from '../../components/Session';

import { Alert, Button, Container,  Form } from "react-bootstrap";

import firebase from 'firebase/app';

import LoadingOverlay from 'react-loading-overlay';
import { Formik } from 'formik';

import { Helmet } from 'react-helmet-async';

var QRCode = require('qrcode.react');

function SharePage(props) {
  return(
    <Container style={{padding:0}}>
        <Helmet>
          <title>Share Profile | PWT</title>
          <meta name="description" content="Generate a temporary shareable link to share your profile anonymously with others."/>
        </Helmet> 
        <SharePageBase></SharePageBase>
    </Container>             
  );
}

function SharePageBase(props) {
    return(
      <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
        <h1>Share Anonymously</h1>
        <p>Generate a temporary shareable link to share your profile anonymously with others. PWT kiosks may use your profile to verify that you satisfy requirements for access control purposes.</p>
        <ShareModule></ShareModule>
      </div>   
  );
    
}

function ShareModule(props) {
  const [showError, setShowError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showBusy, setShowBusy] = useState(false);

  const [url, setUrl] = useState('');
  const [colorQr, setColorQr] = useState('black');
  const [messageQr, setMessageQr] = useState('SCAN ME');
  const [showQr, setShowQr] = useState('none');

  const [timeLeft, setTimeLeft] = useState(0);
  const [expiration, setExpiration] = useState();
  const [timestamp, setTimestamp] = useState();
  const [lastUpdate, setLastUpdate] = useState();

  const [totalEntries, setTotalEntries] = useState(0);
  
  const [verificationLinks, setVerificationLinks] = useState([])

  async function PrepQrCode(secret) {
    setShowError(false);
    setShowBusy(true);
    setUrl('');
    setShowQr('none');
    var addMessage = firebase.functions().httpsCallable('checkProfile');
    addMessage({secret: secret})
    .then(function(result) {
      let timestamp = new Date(result.data.timestamp._seconds * 1000).toLocaleString();
      setTimestamp(timestamp)
      let expirationString = new Date(result.data.expiration._seconds * 1000).toLocaleString();
      setExpiration(expirationString);
      let timeLeft = Math.round((new Date(expirationString) - Date.now()) / 1000)
      if (timeLeft < 0) {
        timeLeft = 0;
      }
      setTimeLeft(timeLeft);
      setUrl(`https://app.warningtemperature.health/share/view/${secret}`);
      if (timeLeft > 0) {
        setLastUpdate(new Date(result.data.last_update._seconds * 1000).toLocaleString());
        setTotalEntries(result.data.total_entries)
        setColorQr('black');
        setMessageQr('SCAN ME');
      } else {
        setColorQr('red');
        setMessageQr('EXPIRED');
      }
      setShowQr('flex');
      setShowBusy(false);
    })
    .catch(function(error) {
      setErrorMessage(error.message);
      setShowBusy(false);
      setShowError(true);
    });


  }

  async function StartOver() {
    setShowError(false);
    setShowQr('none');
    setUrl('');
    setColorQr('red');
    setMessageQr('FAIL');
    setTimeLeft(0);
  }

  useEffect(() => {
    function assignDatabaseListener() {
      let unsubscribe = firebase.firestore().doc('users/'+firebase.auth().currentUser.uid)
      .onSnapshot(function(querySnapshot) {
        setShowBusy(true);
          var entries = [];
          entries  = (querySnapshot.data().profile_secrets);
          if (entries != null) {
            setVerificationLinks(entries);
            if (entries.length > 0) {
              PrepQrCode(entries[entries.length - 1]);
            } else {
              setShowBusy(false);
            }
          } else {
            setShowBusy(false);
          }
      });
      return unsubscribe;
    }

    return assignDatabaseListener();
  }, []);

  useEffect(() => {    
    // exit early when we reach 0
    if (!timeLeft) { 
      setColorQr('red');
      setMessageQr('EXPIRED');
      return;

    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      let timeLeft = Math.round((new Date(expiration) - Date.now()) / 1000)
      if (timeLeft < 0) {
        timeLeft = 0;
      }
      setTimeLeft(timeLeft);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]); // eslint-disable-line react-hooks/exhaustive-deps

  if (showQr === 'flex') {
    return(
      <div>       
        <h2>Anonymous Profile</h2>
        <p><b>Shared</b>: {timestamp}</p>
        <p><b>Expiration</b>: {expiration}</p>
        {(messageQr !== 'EXPIRED')
          ? <>
              <p><b>Total Entries</b>: {totalEntries}</p>
              <p><b>Last Update Recorded</b>: {lastUpdate}</p>
            </>
          : 
            null
        }
        
        <Container style = {{display: showQr, flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            <h2 style={{color:colorQr}}><b>{messageQr}</b></h2>
            <div onClick={() => window.open(url, '_blank')} style={{border:`10px solid ${colorQr}`, paddingBottom:0, paddingTop: 6, paddingLeft:6, paddingRight: 6}}>
              <QRCode value={url} />
            </div>
            <div style={{paddingTop:20, alignContent:'center', justifyContent:'center'}}>
              <p style={{textAlign:'center'}}>Scan (or tap) this code to view information about this anonymous profile.</p>
              {(messageQr !== 'EXPIRED')
                ? <>
                  <p style={{textAlign:'center'}}>Expires in <b>{new Date(timeLeft * 1000).toISOString().substr(11, 8)}</b>.</p>
                  </>
                : 
                  <p style={{textAlign:'center'}}>This code has expired.</p>

              }
              </div>
            
        </Container>    
        <Button className='pwt-button' disabled={showBusy} block bssize="large"  type="submit" onClick={() => StartOver()}>
          Start Over
        </Button>             
      </div>   
  );
  } else {
    return(
      <div>       
        <LoadingOverlay
          active={showBusy}
          spinner
          text='Getting shareable link...'
          styles={{
            overlay: (base) => ({
              ...base,
            })
          }}
        >
        <div>
        <Formik
            initialValues={{expiration: 10}}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                  setSubmitting(true);
                  setShowError(false);
                  setShowBusy(true);
                  var addMessage = firebase.functions().httpsCallable('shareProfile');
                  
                  addMessage({expiration: values.expiration})
                  .catch(function(error) {
                    if (error.code === 'internal') {
                      setErrorMessage('Unable to communicate with the PWT servers. Check your internet connection and try again.');
                    } else {
                      setErrorMessage(error.message);
                    }
                    setShowError(true);
                    setShowBusy(false);
                  });
            }}
          >
          {( {values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting }) => (
            <Form onSubmit={handleSubmit} className="mx-auto">
              <Form.Group controlId="formEmail" style={{paddingTop:20}}>
              <Form.Label>Profile Link Expiration: {values.expiration} {values.expiration > 1 ? 'minutes' : 'minute'}</Form.Label>
                        <Form.Control
                          type="range"
                          min='1'
                          max='60'
                          value={values.expiration}
                          name="expiration"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={showBusy}
                        />
              </Form.Group>
              <Alert show={showError} variant="danger">
                <Alert.Heading>Error Sharing Profile</Alert.Heading>
                <p>{errorMessage}</p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowError(false)} variant="outline-danger">
                    Dismiss
                  </Button>
                </div>
              </Alert>   
              <Button className='pwt-button' disabled={showBusy} block bssize="large"  type="submit">
                Create Temporary Shareable Link
              </Button>
            </Form>
          )}
          </Formik>
        </div>
        </LoadingOverlay>
      </div>   
  );
  }
    
}
  
const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(SharePage);
