import React, { useState } from 'react';
import { withRouter, Link, useParams } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import { Alert, Button, Container, Form, FormGroup, Modal, Row, Col } from "react-bootstrap";

import { Formik } from 'formik';
import * as Yup from 'yup';

import { enforceLogin } from '../../components/Session';

import firebase from 'firebase/app';
import 'firebase/functions'
import LoadingOverlay from 'react-loading-overlay';

import { Helmet } from 'react-helmet-async';



const RedeemGroupPage = () => (
    <Container style={{padding:0}}>
      <Helmet>
        <title>Group Sign Up | PWT</title>
        <meta name="description" content="Redeem your PWT Group Access Code to unlock your membership."/>
      </Helmet> 
        <RedeemGroupBase></RedeemGroupBase>
    </Container>            
);

function QueryURL() {
  let { code } = useParams();
  if (code == null) {
    code = ''
  }
  return {code}
}

function RedeemGroupBase(props) {

  return(
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Start Your PWT Membership</h1>
      <h2>Redeem Your Group Access Code</h2>
      <p>If your organization is using PWT, you should have been provided a Group Access Code to register.</p>
      <RedeemGroupForm></RedeemGroupForm>
    </div>  
  );
}

function RedeemGroupModule(props) {
  let query = QueryURL();

  const [showError, setShowError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showSuccessPrompt, setShowSuccessPrompt] = useState(false);

  const [trialLengthDays, setTrialLengthDays]  = useState(0);

  const [validPromoCode, setValidPromoCode] = useState('');

  const validationSchema = Yup.object().shape({
    code: Yup.string()
    .required("Access Code is required")
    .label('Access Code'),
  });

  const handleClose = () => setShowSuccessPrompt(false);
  const handleShow = () => setShowSuccessPrompt(true);


  return(
    <div>    
      {/* <Modal
        show={showSuccessPrompt}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Valid Promo Code!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            (trialLengthDays < 1000)
            ?
              <p>The specified promo code is valid for a <b>{trialLengthDays}-day trial</b>. Click "Sign Up" to complete your registration.</p>
            :
              <p>The specified promo code is valid for a <b>lifetime membership</b>. Click "Sign Up" to complete your registration.</p>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button className='pwt-button-secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button className='pwt-button' onClick={() => props.history.push(`/trial/signup/${validPromoCode}`)}>Sign Up</Button>
        </Modal.Footer>
      </Modal>    */}
        <Formik
        initialValues={{ code: query.code }}
        validationSchema={validationSchema}
        onSubmit={(values, {setSubmitting, resetForm}) => {
          setSubmitting(true);
          setShowError(false);
          setSubmitting(true);
          var addMessage = firebase.functions().httpsCallable('checkGroupActivationCode');
          addMessage({code: values.code})
          .then(function(result) {
            // setValidPromoCode(values.code);
            // setTrialLengthDays(5);
            setSubmitting(false);
            // setShowSuccessPrompt(true);
            props.history.push(`/group/signup/${values.code}`)
          })
          .catch(function(error) {
            setSubmitting(false);
            setErrorMessage(error.message);
            setShowError(true);
          });
        }}
      >
      {( {values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="mx-auto">
          <LoadingOverlay
            active={isSubmitting}
            spinner
            text='Checking access code...'
            styles={{
              overlay: (base) => ({
                ...base,
                borderRadius:"12.5px"
              })
            }}
          >

          <Form.Group controlId="formAccessCode">
              <Form.Label>Access Code:</Form.Label>
              <Form.Control
                type="text"
                name="code"
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.code.toUpperCase()}
                isInvalid={errors.code && touched.code}
                isValid={!errors.code && touched.code}
              />
              <Form.Control.Feedback type="invalid" style={{
                      color: '#dc3545',
                      fontSize: '.8em',
                    }}>
                {errors.code}
              </Form.Control.Feedback>
          </Form.Group>
          <Alert show={showError} variant="danger">
          <Alert.Heading>Could Not Redeem</Alert.Heading>
            <p>{errorMessage}</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShowError(false)} variant="outline-danger">
                Dismiss
              </Button>
            </div>
          </Alert>
          <Button className='pwt-button' block size="lg"  type="submit" disabled={isSubmitting}>
            Redeem Access Code
          </Button>
          </LoadingOverlay>
        </Form>
          )}
      </Formik>
    </div>         
);
}


const RedeemGroupForm = compose(
  withRouter,
  withFirebase,
)(RedeemGroupModule);

export { RedeemGroupForm };

const condition = authUser => !!authUser;

export default enforceLogin(condition)(RedeemGroupPage);