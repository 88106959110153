import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        userDoc: null,
        assessmentDoc: null
      };
      // this.setUserDoc = this.setUserDoc.bind(this);

    }
    // 
    // setUserDoc(data) {
    //   if (data != null) {
    //     this.setState({ userDoc: data })
    //   }
    // }

    componentDidMount() {
     
      
      this.userDocListener = null;

      


      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          let tempUserDocListener = null;
          if(authUser) {


              this.setState({ authUser: authUser })
              let tempUserDoc = null;
              let that = this;
              this.userDocListener = this.props.firebase.user(authUser.uid).onSnapshot(function(doc) {
                  if (doc.exists) {
                    // console.log("Fetching user data: ", doc.data());
                    tempUserDoc = doc.data();
                    // that.setUserDoc(tempUserDoc);
                    that.setState({ userDoc: doc.data() });

                  }
              }, function(error) {
                  // ...
                  // console.log("Could not fetch user data.");
                  // console.log(error);
              });
              this.assessmentDocListener = this.props.firebase.temperature_data(authUser.uid)
                .orderBy("timestamp", 'desc')
                .limit(1)
                .onSnapshot(function(snapshot) {
                    let docs = snapshot.docs;
                    if (docs.length > 0) {
                      let doc = docs[0];
                      // console.log("Assessment data: ", doc.data());
                      that.setState({ assessmentDoc: doc.data() });
                    }
                }, function(error) {
                  // ...
                  // console.log("Could not fetch assessment data.");
                  // console.log(error);
                });
          } else {
            // console.log('Should unsub');
            if (this.userDocListener != null) {
              // console.log('Unsubbing');
              this.userDocListener();
            }
            if (this.assessmentDocListener != null) {
              // console.log('Unsubbing');
              this.assessmentDocListener();
            }
            this.setState({ authUser: null, userDoc: null, assessmentDoc: null });
          }
        },
      );

      
    }

    componentWillUnmount() {
      this.listener();
      if (this.userDocListener != null) {
        // console.log('Unsubbing');
        this.userDocListener();
      }
    }

    render() {
      return (
        <AuthUserContext.Provider value={{authUser: this.state.authUser, userDoc: this.state.userDoc, assessmentDoc: this.state.assessmentDoc}}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
