import React from 'react';

import { Container } from 'react-bootstrap';

import { Helmet } from 'react-helmet-async';

export default function NotFound() {    

  return(
    <Container style={{padding:0}}>
        <Helmet>
            <title>404 Not Found | PWT</title>
            <meta name="description" content="Update your temperature." />
            <meta name="robots" content="noindex"/>
        </Helmet>
        <div style={{marginTop:20}}>
          <NotFound404></NotFound404>
        </div>
    </Container>
  );
}


function NotFound404(props) {

  return (
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", padding:15}}>          
      <h1>Page Not Found</h1>
      <p>The requested page does not exist. It may have been removed or relocated.</p> 
      <p> Contact support if you believe this to be an issue.</p>
      <div style={{paddingTop:10}}>
        <a href="/home">Return home.</a>
        <br></br>
      </div>
    </div>   
  );
}
