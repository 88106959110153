import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { withAuthorization } from '../../components/Session';

import { Alert, Button, Container } from "react-bootstrap";

import firebase from 'firebase/app';
import Dots from 'react-activity/lib/Dots';

import { Helmet } from 'react-helmet-async';

function ShareViewPage(props) {
  return(
    <Container style={{padding:0}}>    
      <Helmet>
        <title>View Profile | PWT</title>
        <meta name="description" content="View a shareable profile link."/>
        <meta name="robots" content="noindex"/>
      </Helmet>          
      <ShareViewPageBase></ShareViewPageBase>
    </Container>               
  );
}



function ShareViewPageBase(props) {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showBusy, setShowBusy] = useState(false);

  const [secret, setSecret] = useState(useParams())

  const [isValid, setIsValid] = useState(false);

  const [isExpired, setIsExpired] = useState(false);

  const [expiration, setExpiration] = useState();

  const [timestamp, setTimestamp] = useState();

  const [lastUpdate, setLastUpdate] = useState();

  const [totalEntries, setTotalEntries] = useState(0);

  

  

  useEffect(() => {
    function checkLegitimacy() {
      setShowError(false);
      setShowBusy(true);
      var addMessage = firebase.functions().httpsCallable('checkProfile');
      addMessage({secret: secret.id})
      .then(function(result) {
        setTimestamp(new Date(result.data.timestamp._seconds * 1000).toLocaleString());
        if (result.data.expired === true) {
          setIsExpired(true);
        }  else {
          setIsExpired(false);
          setLastUpdate(new Date(result.data.last_update._seconds * 1000).toLocaleString());
          setTotalEntries(result.data.total_entries)
        }
        setExpiration(new Date(result.data.expiration._seconds * 1000).toLocaleString());
        setShowBusy(false);
        setIsValid(true);
      })
      .catch(function(error) {
        setErrorMessage(error.message);
        setShowBusy(false);
        setIsValid(false);
      });
    }

    checkLegitimacy();
  }, [secret.id]);

  if (isValid === true) {
    return (
            <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
        <h1>Anonymous Profile</h1>
        {(showBusy === true)
        ? 
          <div style={{display:"flex",flexDirection:"column", alignItems:'center', justifyContent:"center", paddingTop:20}}>
              <Dots color="#21436D" size={32} speed={1} animating={showBusy} />
          </div>
          
        : 
        <div>
          {(isExpired === true)
            ? 
              <div>
                <h2>Expired Profile Link</h2>
                <p>The specified profile link is expired.</p>
                <p><b>Shared</b>: {timestamp}</p>
                <p><b>Expiration</b>: {expiration}</p>
              </div>
            : 
              <div>
                <h2>Valid Profile</h2>
                <p><b>Shared</b>: {timestamp}</p>
                <p><b>Expiration</b>: {expiration}</p>
                <p><b>Total Temperature Entries</b>: {totalEntries}</p>
                <p><b>Last Update Recorded</b>: {lastUpdate}</p>
                <br/>
                <h3>Fraudulent Profile Notice:</h3>
                <p>To help prevent anyone from attempting to spoof a profile, always follow the below guidelines:</p>
                <ul>
                  <li>Ensure your browser is connected to our official domain of '<a href='https://app.warningtemperature.health' target='_blank' rel="noopener noreferrer">https://app.warningtemperature.health</a>.'</li>
                  <li>Check for the padlock icon in your address bar to confirm that you are connected securely.</li>
                  <li>Never check a report on a device you do not trust.</li>
                </ul>
              </div>
              }
            <div style={{paddingTop:20}}>
                <Alert show={showError} variant="danger">
                          <Alert.Heading>Error Validating Temperature</Alert.Heading>
                          <p>{errorMessage}</p>
                          <hr />
                          <div className="d-flex justify-content-end">
                            <Button onClick={() => setShowError(false)} variant="outline-danger">
                              Dismiss
                            </Button>
                          </div>
                </Alert>
            </div>
          </div>
          }
      </div>
    );
  } else {
    return (
            <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
        <h1>Anonymous Profile</h1>
        {(showBusy === true)
          ?  
            <div style={{display:"flex",flexDirection:"column", alignItems:'center', justifyContent:"center", paddingTop:20}}>
              <Dots color="#21436D" size={32} speed={1} animating={showBusy} />
            </div>
            
          : 
            <div>
              <h3>Invalid Profile Link</h3>
              <p>The specified profile was not found and is therefore invalid. Please try again with a new profile code.</p>
              <p>Note that expired profile codes are removed after several days.</p>
              <div style={{paddingTop:20}}>
                <Alert show={showError} variant="danger">
                          <Alert.Heading>Error Validating Temperature</Alert.Heading>
                          <p>{errorMessage}</p>
                          <hr />
                          <div className="d-flex justify-content-end">
                            <Button onClick={() => setShowError(false)} variant="outline-danger">
                              Dismiss
                            </Button>
                          </div>
                </Alert>
              </div>
            </div>
        }     
      </div>   
    );
  } 
}
  
const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(ShareViewPage);
