import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/firestore'

const config = {
  apiKey: "AIzaSyC-q1KiBEKY69TFyqAfH3LjFzNYqstTK6w",
  authDomain: "tempature-track.firebaseapp.com",
  databaseURL: "https://tempature-track.firebaseio.com",
  projectId: "tempature-track",
  storageBucket: "tempature-track.appspot.com",
  messagingSenderId: "200687845573",
  appId: "1:200687845573:web:7cf4cc7eec995834fc70ac",
  measurementId: "G-BZKX56KZTZ"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.analytics = app.analytics();
    this.auth = app.auth();
    this.db = app.firestore();
  }

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () =>
    this.auth.signOut();


  doLogPageVisit = (url) => {
    this.analytics.setCurrentScreen(url)
    this.analytics.logEvent('screen_view')
  }

  doLogSignUp = (method) => {
    this.analytics.logEvent('sign_up', {method: method})
  }
    

  user = uid => this.db.doc(`users/${uid}`);

  temperature_data = uid => this.db.collection(`users/${uid}/temperature_data`);

  group = groupID => this.db.doc(`groups/${groupID}`);

}

export default Firebase;
