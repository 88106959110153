import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import { Alert, Button, Container, Form } from "react-bootstrap";

import { Formik } from 'formik';

import * as Yup from 'yup';

import { enforceLogin } from '../../components/Session';

import { Helmet } from 'react-helmet-async';

const SignInAnonymousPage = (props) => (
    <Container style={{padding:0}}>
      <Helmet>
        <title>Sign In | PWT</title>
        <meta name="description" content="Sign in to your PWT account."/>
      </Helmet>       
      <SignInAnonymousPageBase></SignInAnonymousPageBase>
    </Container>               
);

const validationSchema = Yup.object().shape({
  username: Yup.string()
  .min(2, "Username must have at least 2 characters.")
  .max(64, "Username can't be longer than 64 characters.")
  .required("Username is required."),
  password: Yup.string()
  .min(6,"Passwords must be at least 6 characters.")  
  .required("Password is required."),
});


function SignInAnonymousPageBase(props) {
  return(
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Welcome to Personal Warning Temperature!</h1>
      <LandingHeader></LandingHeader>
      <h2>Please sign in:</h2>
      <SignInAnonymousForm></SignInAnonymousForm>
    </div>         
  );
}

function LandingHeader(props) {
  return(
    <div>
      <p>PWT is a personal body temperature recording and analysis system for detecting potential illness before traditional symptoms appear. 
      {' '}
      <a href="https://warningtemperature.health" target="_blank" rel="noopener noreferrer">Learn more</a>
      {'.'}
      </p>
    </div>
  );
}


function SignInAnonymousFormBase(props) {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return(
    <div>       
        <Formik
          initialValues={{ username:"", password:""}}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting, resetForm}) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);
              setShowError(false);
              var entryUsername = values.username;
              if (!entryUsername.includes('@')) {
                entryUsername += '@warningtemperature.health';
              }
              props.firebase
              .doSignInWithEmailAndPassword((entryUsername), values.password)
              .catch(error => {
                if (error.code === 'auth/too-many-requests') {
                  setErrorMessage(String('Too many unsuccessful login attempts. Please try again later.'));
                } else if (error.code === 'auth/user-disabled') {
                  setErrorMessage(String('Account is disabled. Contact support for more information.'));
                } else if (error.code === 'auth/network-request-failed') {
                  setErrorMessage('Unable to communicate with the PWT servers. Check your internet connection and try again.');
                } else {
                  setErrorMessage(String('Invalid username and password combination.'));
                }
                setShowError(true);
              });
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
        {( {values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            <Form.Group controlId="formUsername">
            <Form.Label>Username:</Form.Label>
              <Form.Control
                type="text"
                name="username"
                autoComplete={"username"}
                placeholder="example-user-42"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username}
                isInvalid={errors.username && touched.username}
                isValid={!errors.username && touched.username}
              />
              <Form.Control.Feedback type="invalid" style={{
                      color: '#dc3545',
                      fontSize: '.8em',
                    }}>
                {errors.username}
              </Form.Control.Feedback>
              {/* <Form.Control.Feedback type="valid">Username looks valid.</Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group controlId="formPassword">
            <Form.Label>Password:</Form.Label>
              <Form.Control
                type="password"
                name="password"
                autoComplete={"current-password"}
                placeholder="********"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                isInvalid={errors.password && touched.password}
                isValid={!errors.password && touched.password}
              />
              <Form.Control.Feedback type="invalid" style={{
                      color: '#dc3545',
                      fontSize: '.8em',
                    }}>
                {errors.password}
              </Form.Control.Feedback>
              {/* <Form.Control.Feedback type="valid">Password meets requirements.</Form.Control.Feedback> */}
            </Form.Group>
            <Alert show={showError} variant="danger">
                <Alert.Heading>Error Signing In</Alert.Heading>
                <p>{errorMessage}</p>
                <hr/>
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowError(false)} variant="outline-danger">
                    Dismiss
                  </Button>
                </div>
            </Alert>
            <Button className='pwt-button' block size='lg' type="submit">
              Sign In
            </Button>
          </Form>
        )}
        </Formik>
        <div style={{paddingTop:10}}>
            <Link to={ROUTES.RESET_PASSWORD}>Trouble signing in?</Link>
            <br/>
            <Link to={ROUTES.SIGN_UP_ANONYMOUS}>Don't have an account? Sign up.</Link>
        </div>
    </div>         
  );
}

const SignInAnonymousForm = compose(
  withRouter,
  withFirebase,
)(SignInAnonymousFormBase);


export { SignInAnonymousForm };


// export default SignInPage;

const condition = authUser => !!authUser;

export default enforceLogin(condition)(SignInAnonymousPage);