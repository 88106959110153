import React, {useContext, useState} from 'react';
import { compose } from 'recompose';

import { withRouter } from "react-router";

import * as ROUTES from '../../constants/routes';

import LoadingOverlay from 'react-loading-overlay';

import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";

import firebase from 'firebase/app';
import 'firebase/functions'

import { Formik } from 'formik';
import * as Yup from 'yup';

import { withFirebase } from '../Firebase';

import { TemperatureMemberAssessmentModule } from '../TemperatureMemberAssessment'
import { GreenScreenBannerMemberModule } from '../GreenScreenBannerMember'

function UpdateMemberTemperatureResultsModule(props) {
    let user = props.user;
    let temperatureCallback = props.temperatureCallback;
    let flagCallback = props.flagCallback;
      return (
        <div>       
          <GreenScreenBannerMemberModule user={user} flagCallback = {flagCallback}></GreenScreenBannerMemberModule>
          <hr/>
          <TemperatureMemberAssessmentModule user={user} temperatureCallback = {temperatureCallback} ></TemperatureMemberAssessmentModule>
        </div>         
      );
    
  }
  
  
  export default compose(
    withRouter,
    withFirebase
  )(UpdateMemberTemperatureResultsModule)