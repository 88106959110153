import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import { Button, Modal } from "react-bootstrap";


const enforceLogin = condition => Component => {

  class EnforceLogin extends React.Component {

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (condition(authUser)) {
            this.props.history.push(ROUTES.HOME);
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            condition(authUser[0]) ? null : 
            <Component {...this.props}/> // Allows the user to traverse the non-authed pages (sign in/out screens)
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(
    withRouter,
    withFirebase,
  )(EnforceLogin);
};

export default enforceLogin;
