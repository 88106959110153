import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { withAuthorization } from '../../components/Session';

import { Alert, Button, Container, Row, Col } from "react-bootstrap";

import firebase from 'firebase/app';
import LoadingOverlay from 'react-loading-overlay';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';

import { Helmet } from 'react-helmet-async';



function GroupViewPage(props) {
  return(
    <Container style={{padding:0}}>
      <Helmet>
        <title>Group Management Report | PWT</title>
        <meta name="description" content="View your group's activity."/>
        <meta name="robots" content="noindex"/>
      </Helmet>   
      <GroupViewPageBase></GroupViewPageBase>
    </Container>
  );
}

function GroupViewPageBase(props) {
  return(
          <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Management Report</h1>
      <ManagementReportModule></ManagementReportModule>
    </div>   
  );      
}


function ManagementReportModule(props) {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showBusy, setShowBusy] = useState(false);

  const [group, setGroup] = useState(useParams())

  const [groupMembers, setGroupMembers] = useState([]);
  const [groupInformation, setGroupInformation] = useState();

  const [totalUpdates, setTotalUpdates] = useState(0);
  const [flaggedUpdates, setFlaggedUpdates] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);

  const [isValid, setIsValid] = useState(false);


  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
    {
      (size === 0)
      ?
      ` No results found.`
      :
      ` Showing ${ from } to ${ to } of ${ size } entries.`
    }
    </span>
  );
  
  function timestampFormatter(cell, row) {
      return (
        cell.formatted
      );
  }
  
  function uppercaseFormatter(cell, row) {
    let value = String(cell);
    if (value.length < 2) {
      return (value);
    } else {
      return (value.charAt(0).toUpperCase() + value.slice(1));
    }
   
  }
  const columns = [{
    dataField: 'id',
    text: '#',
    sort: true
  },{
    dataField: 'username',
    text: 'Username',
    sort: true
  }, {
    dataField: 'has_pwt',
    text: 'Has PWT',
    sort: true,
    formatter: uppercaseFormatter
  }, {
    dataField: 'last_update',
    text: 'Last Update',
    sort: true,
    sortFunc: (a, b, order, dataField) => {
      if (order === 'asc') {
        return b._seconds - a._seconds;
      }
      return a._seconds - b._seconds; // desc
    },
    formatter: timestampFormatter,
    csvFormatter: timestampFormatter
  }, {
    dataField: 'status_meaning',
    text: 'Assessment',
    sort: true,
  }, {
    dataField: 'has_symptoms',
    text: 'Reported Symptoms',
    sort: true,
    formatter: uppercaseFormatter
  }, {
    dataField: 'is_flagged',
    text: 'Is Flagged',
    sort: true,
    formatter: uppercaseFormatter
  }];
  
  function sortUsers(groupUsersArray, field, sortingOrder) {
    if (groupUsersArray.length < 1) {
      return;
    }
    var newSortingField = field;
    var users = groupUsersArray;
    if (newSortingField === 'username') {
      if (sortingOrder === 'asc') {
        users = users.sort((a, b) => (a.username > b.username) ? 1 : (a.username === b.username) ? ((a.last_update._seconds > b.last_update._seconds) ? 1 : -1) : -1 )
      } else if (sortingOrder === 'desc') {
        users = users.sort((a, b) => (a.username < b.username) ? 1 : (a.username === b.username) ? ((a.last_update._seconds < b.last_update._seconds) ? 1 : -1) : -1 )
      }
    } else if (newSortingField === 'lastUpdate') {
      if (sortingOrder === 'asc') {
        users = users.sort((a, b) => (a.last_update._seconds > b.last_update._seconds) ? 1 : (a.username === b.username) ? ((a.username > b.username) ? 1 : -1) : -1 )
      } else if (sortingOrder === 'desc') {
        users = users.sort((a, b) => (a.last_update._seconds < b.last_update._seconds) ? 1 : (a.username === b.username) ? ((a.username < b.username) ? 1 : -1) : -1 )
      }
    } else if (newSortingField === 'status') {
      if (sortingOrder === 'asc') {
        users = users.sort((a, b) => (a.status_meaning > b.status_meaning) ? 1 : (a.status_meaning === b.status_meaning) ? ((a.last_update._seconds > b.last_update._seconds) ? 1 : -1) : -1 )
      } else if (sortingOrder === 'desc') {
        users = users.sort((a, b) => (a.status_meaning < b.status_meaning) ? 1 : (a.status_meaning === b.status_meaning) ? ((a.last_update._seconds < b.last_update._seconds) ? 1 : -1) : -1 )
      }
    } else if (newSortingField === 'has_symptoms') {
      if (sortingOrder === 'asc') {
        users = users.sort((a, b) => (a.has_symptoms > b.has_symptoms) ? 1 : (a.has_symptoms === b.has_symptoms) ? ((a.last_update._seconds > b.last_update._seconds) ? 1 : -1) : -1 )
      } else if (sortingOrder === 'desc') {
        users = users.sort((a, b) => (a.has_symptoms < b.has_symptoms) ? 1 : (a.has_symptoms === b.has_symptoms) ? ((a.last_update._seconds < b.last_update._seconds) ? 1 : -1) : -1 )
      }
    } else if (newSortingField === 'has_pwt') {
      if (sortingOrder === 'asc') {
        users = users.sort((a, b) => (a.has_pwt > b.has_pwt) ? 1 : (a.has_pwt === b.has_pwt) ? ((a.last_update._seconds > b.last_update._seconds) ? 1 : -1) : -1 )
      } else if (sortingOrder === 'desc') {
        users = users.sort((a, b) => (a.has_pwt < b.has_pwt) ? 1 : (a.has_pwt === b.has_pwt) ? ((a.last_update._seconds < b.last_update._seconds) ? 1 : -1) : -1 )
      }
    } else if (newSortingField === 'is_flagged') {
      if (sortingOrder === 'asc') {
        users = users.sort((a, b) => (a.is_flagged > b.is_flagged) ? 1 : (a.is_flagged === b.is_flagged) ? ((a.last_update._seconds > b.last_update._seconds) ? 1 : -1) : -1 )
      } else if (sortingOrder === 'desc') {
        users = users.sort((a, b) => (a.is_flagged < b.is_flagged) ? 1 : (a.is_flagged === b.is_flagged) ? ((a.last_update._seconds < b.last_update._seconds) ? 1 : -1) : -1 )
      }
    }
    return users;
  }

  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    let buttonClass = 'pwt-button';
    if (active) {
      buttonClass = 'pwt-button-secondary'
    }
    return (
      <div key={ page } style={{paddingLeft:1, paddingRight:1}}>
        <Button className={buttonClass} key={ page } onClick={ handleClick }>{ page }</Button>
      </div>
    );
  };

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
    size
  }) => (
    <div className="btn-group" role="group">
      {
        options.map((option) => {
          const active = currSizePerPage === `${option.page}`;
          let buttonClass = 'pwt-button';
          if (active) {
            buttonClass = 'pwt-button-secondary'
          }
          return (
            <div key={ option.text } style={{paddingLeft:1, paddingRight:1}}>
              <Button className={buttonClass} onClick={ () => onSizePerPageChange(option.page) } >{ option.text }</Button>
            </div>
          );
         
        })
      }
    </div>
  );

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport(groupMembers);
    };
    return (
      <div>
        <Button className='pwt-button' block size='sm' type='button' disabled={(groupMembers.length < 1)} onClick={ handleClick }>Export Spreadsheet</Button>
      </div>
    );
  };

  const options = {
    pageButtonRenderer,
    sizePerPageRenderer,
    paginationTotalRenderer: customTotal,
    custom: true,
    paginationSize: 3,
    pageStartIndex: 1,
    totalSize: groupMembers.length,
    showTotal: true,
    hideSizePerPage: (groupMembers.length > 1) ? false : true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '25', value: 25
    },{
      text: '50', value: 50
    }, {
      text: 'All', value: groupMembers.length
    }]
  };

  const historyTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="id"
        columns={ columns }
        data={ groupMembers }
        exportCSV = { {
          fileName: `PWT_Group_Report-(${group.name})-(${new Date().toLocaleString(undefined, { timeZoneName: 'short' })}).csv`,
        } }
  >
        {
          toolkitprops => (
            <div>
              <MyExportCSV { ...toolkitprops.csvProps }></MyExportCSV>
              <hr/>
              <h2>Members</h2>
              <BootstrapTable
                bootstrap4
                wrapperClasses="table-responsive"
                striped
                hover
                condensed
                { ...toolkitprops.baseProps }
                { ...paginationTableProps }
              />
              <Row>
                <Col>
                  <div style={{display: 'flex', flexDirection:'column'}}>
                    <SizePerPageDropdownStandalone { ...paginationProps } />
                    <PaginationTotalStandalone { ...paginationProps } />
                  </div>
                </Col>
                <Col sm={1}>
                  <div>
                    <PaginationListStandalone { ...paginationProps } />
                  </div>
                </Col>
              </Row>
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  );
  
  useEffect(() => {
    function checkLegitimacy() {
      setShowError(false);
      setShowBusy(true);
      var addMessage = firebase.functions().httpsCallable('checkGroupStatus');
      addMessage({groupName: group.name})
      .then(function(result) {
        let information = result.data.group_information;
        let statuses = result.data.group_statuses;
        let metrics = result.data.group_metrics;
        let tempTotalUpdates = metrics.total_updates_last_12_hours;
        let tempflaggedUpdates = metrics.total_flagged_updates;
        let tempTotalMembers = statuses.length;
        // console.log(statuses);
        statuses = sortUsers(statuses, 'is_flagged', 'desc');
        var formattedStatuses = [];
        statuses.forEach(function (status) {
          formattedStatuses.push(status)
          if (formattedStatuses[formattedStatuses.length - 1].last_update._seconds !== 0) {
            formattedStatuses[formattedStatuses.length - 1].last_update.formatted = new Date(formattedStatuses[formattedStatuses.length - 1].last_update._seconds * 1000).toLocaleString(undefined, { timeZoneName: 'short' })
          } else {
            formattedStatuses[formattedStatuses.length - 1].last_update.formatted = '';
          }
          formattedStatuses[formattedStatuses.length - 1].id = (statuses.length - formattedStatuses.length + 1);
        });

        setGroupInformation(information)
        setFlaggedUpdates(tempflaggedUpdates)
        setGroupMembers(formattedStatuses);    
        setTotalMembers(tempTotalMembers);    
        setTotalUpdates(tempTotalUpdates);
        setShowBusy(false);
        setIsValid(true);
      })
      .catch(function(error) {
        setErrorMessage(error.message);
        setShowBusy(false);
        setIsValid(false);
      });
    }

    checkLegitimacy();
  }, [group.name]);  // eslint-disable-line react-hooks/exhaustive-deps

    if (showBusy) {
      return (
        <LoadingOverlay
                active={showBusy}
                spinner
                text='Loading management report...'
                styles={{
                  overlay: (base) => ({
                    ...base,
                  })
                }}
          >
        <div style={{minHeight:150}}>       
          <h2>{`Group ID: ${group.name}`}</h2>
        </div>
        </LoadingOverlay>
      );
    } else {
      if (isValid === true) {
        return (
          <LoadingOverlay
                  active={showBusy}
                  spinner
                  text='Loading management report...'
                  styles={{
                    overlay: (base) => ({
                      ...base,
                    })
                  }}
            >
          <div>       
            <h2>{`${groupInformation.public_name}`}</h2>
            <p><b>{`Updates Within Last 12 Hours`}</b>{`: ${totalUpdates}`}</p>
            <p><b>{`Flagged Updates`}</b>{`: ${flaggedUpdates}`}</p>
            <p><b>{`Total Group Members`}</b>{`: ${totalMembers}`}</p>
            <div style={{paddingTop:20}}>
              <PaginationProvider
                    pagination={
                      paginationFactory(options)
                    }
                  >
                    { historyTable }
              </PaginationProvider>
            </div >
            
          </div>
          </LoadingOverlay>
        );
      } else {
        return (
          <LoadingOverlay
                  active={showBusy}
                  spinner
                  text='Loading management report...'
                  styles={{
                    overlay: (base) => ({
                      ...base,
                    })
                  }}
            >
          <div>       
            <h2>Invalid Group: {group.name}</h2>
            <p>The specified group was not found or you do not possess the necessary permissions to view it.</p>
            <Alert show={showError} variant="danger">
                <Alert.Heading>Error Validating Temperature</Alert.Heading>
                <p>{errorMessage}</p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowError(false)} variant="outline-danger">
                    Dismiss
                  </Button>
                </div>
            </Alert>
          </div>   
          </LoadingOverlay>
        );
      } 
    }
}
  
  
const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(GroupViewPage);
