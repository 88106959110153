import React, { useState } from 'react';
import { withRouter, Link, useParams } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import { Alert, Button, Container, Form, FormGroup, Row, Col } from "react-bootstrap";

import { Formik } from 'formik';
import * as Yup from 'yup';

import { enforceLogin } from '../../components/Session';

import firebase from 'firebase/app';
import 'firebase/functions'
import LoadingOverlay from 'react-loading-overlay';

import { Helmet } from 'react-helmet-async';

const validationSchema = Yup.object().shape({
  username: Yup.string()
  .min(2, "Username must have at least 2 characters.")
  .max(64, "Username can't be longer than 64 characters.")
  .required("Username is required.")
  .matches(
    /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/,
    "Username may contain only letters and numbers, optionally separated by underscores (_) or dashes (-)."
  ),
  password: Yup.string()
  .min(6,"Password must be at least 6 characters.")   
  .required("Password is required."),
  passwordConfirm: Yup.string()
    .required("Please confirm the password.")
    .label('Confirm password')
    .test('passwords-match', 'Passwords must match.', function(value) {
      return this.parent.password === value;
    }),
  licenseKey: Yup.string()
  .required("License Key is required.")
  .label('License Key')
  .matches(
    /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/,
    "Must conform to the specified license key format."
  ),
  acceptTerms: Yup.bool()
  .oneOf([true], 'Please read and accept the ToS.'),
});


const SignUpAnonymousKitPage = () => (
    <Container style={{padding:0}}>
        <Helmet>
          <title>Sign Up (Activation Kit) | PWT</title>
          <meta name="description" content="Sign up using an Activation Card from a PWT Activation Kit."/>
        </Helmet>   
        <SignUpAnonymousKitBase></SignUpAnonymousKitBase>
    </Container>            
);

function QueryURL() {
  let { key } = useParams();
  if (key == null) {
    key = ''
  }
  return {key}
}

function SignUpAnonymousKitBase(props) {

  return(
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Sign Up</h1>
      <h2>Activation Card</h2>
      <p>Sign up using an Activation Card from a PWT Activation Kit.</p>
      <SignUpAnonymousKitForm></SignUpAnonymousKitForm>
      <div style={{paddingTop:10}}>
        <Link to={ROUTES.SIGN_IN_ANONYMOUS}>Already have an account? Sign in.</Link>
        <br></br>
        <a href='https://warningtemperature.health/shop' target='_blank' rel="noopener noreferrer">Need an Activation Kit? Purchase one today.</a>
        {/* <br></br> */}
        {/* <Link to={ROUTES.LANDING}>Return home.</Link> */}
      </div>
    </div>  
  );
}

function SignUpAnonymousKitModule(props) {
    let query = QueryURL();

    const [showError, setShowError] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    
    return(
      <div>       
        <Formik
          initialValues={{ username:"", password:"", passwordConfirm:"", licenseKey:query.key, acceptTerms:false}}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            setShowError(false);
            setSubmitting(true);
            var addMessage = firebase.functions().httpsCallable('registerUserWithLicense');
            addMessage({userEmail: values.username, userPassword: values.password, licenseEmail: values.licenseEmail, licenseKey: values.licenseKey, isKit: true})
            .then(function(result) {
              props.firebase.doLogSignUp('Activation Card');
              props.firebase
              .doSignInWithEmailAndPassword(values.username + '@warningtemperature.health', values.password)
              .catch(error => {
                setErrorMessage(String('Failed to sign in.'));
                setShowError(true);
              });
            })
            .catch(function(error) {
              setSubmitting(false);
              setErrorMessage(error.message);
              setShowError(true);
            });
          }}
        >
        {( {values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            <LoadingOverlay
              active={isSubmitting}
              spinner
              text='Signing up...'
              styles={{
                overlay: (base) => ({
                  ...base,
                  borderRadius:"12.5px"
                })
              }}
            >
            <Row>
              <Col>
                <Form.Group controlId="formUsername">
                <Form.Label>Username:</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    autoComplete={"username"}
                    placeholder="example-user-42"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={errors.username && touched.username}
                    isValid={!errors.username && touched.username}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.username}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid">Be sure to write this down. Do not use personally identifiable information.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formPassword">
                <Form.Label>Password:</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    autoComplete={"new-password"}
                    placeholder="********"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={errors.password && touched.password}
                    isValid={!errors.password && touched.password}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.password}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid">Password meets requirements.</Form.Control.Feedback>
                </Form.Group>
                </Col>
                <Col>
                <Form.Group controlId="formPasswordConfirm">
                <Form.Label>Confirm:</Form.Label>
                  <Form.Control
                    type="password"
                    name="passwordConfirm"
                    autoComplete={"new-password"}
                    placeholder="********"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirm}
                    isInvalid={errors.passwordConfirm && touched.passwordConfirm}
                    isValid={!errors.passwordConfirm && touched.passwordConfirm}
                  />
                  <Form.Control.Feedback style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }} type="invalid">
                    {errors.passwordConfirm}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid">Passwords match.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="formLicenseKey">
                <Form.Label>License Key:</Form.Label>
                  <Form.Control
                    type="text"
                    name="licenseKey"
                    placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.licenseKey}
                    isInvalid={errors.licenseKey && touched.licenseKey}
                    isValid={!errors.licenseKey && touched.licenseKey}
                    style={{textTransform:'uppercase'}}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.licenseKey}
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback type="valid">License key looks legitimate.</Form.Control.Feedback> */}
                </Form.Group>
            <FormGroup controlId="agreeToTerms">
              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                  <Form.Check 
                  label={`I agree with the`} name="acceptTerms" value={values.acceptTerms} onChange={handleChange}></Form.Check>
                  <a href='/tos' target='_blank' rel="noopener noreferrer">Terms of Service</a> 
                  {errors.acceptTerms && touched.acceptTerms ? (
                    <div style={{
                      color: '#dc3545',
                      // padding: '.5em .2em',
                      // height: '1em',
                      fontSize: '.8em',
                    }}>{errors.acceptTerms}</div>
                  ): <div style={{
                    color: '#dc3545',
                    // padding: '.5em .2em',
                    // height: '1em',
                    fontSize: '.8em',
                  }}><br/></div>}   
                </div>
            </FormGroup>
            <Alert show={showError} variant="danger">
            <Alert.Heading>Error Signing Up</Alert.Heading>
              <p>{errorMessage}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShowError(false)} variant="outline-danger">
                  Dismiss
                </Button>
              </div>
            </Alert>
            <Button className='pwt-button' block size="lg"  type="submit" disabled={isSubmitting}>
              Sign Up
            </Button>
            </LoadingOverlay>
          </Form>
            )}
        </Formik>
      </div>         
  );
}


const SignUpAnonymousKitForm = compose(
  withRouter,
  withFirebase,
)(SignUpAnonymousKitModule);

export { SignUpAnonymousKitForm };

const condition = authUser => !!authUser;

export default enforceLogin(condition)(SignUpAnonymousKitPage);