import React, {useContext, useEffect, useState} from 'react';
import { compose } from 'recompose';

import { withRouter } from "react-router";

import * as ROUTES from '../../constants/routes';

import LoadingOverlay from 'react-loading-overlay';

import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";

import firebase from 'firebase/app';
import 'firebase/functions'

import { Formik } from 'formik';
import * as Yup from 'yup';

import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';


function UpdateTemperatureModule(props) {
  let userContext = useContext(AuthUserContext);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showBusy, setShowBusy] = useState(false);
  const [showSymptomsMenu, setShowSymptomsMenu] = useState(false);
  
  const [displaySkipOption, setDisplaySkipOption] = useState(false);
  const [showSkipModal, setShowSkipModal] = useState(false);

  const handleClose = () => setShowSkipModal(false);
  const handleShow = () => setShowSkipModal(true);

  function skipUpdate() {
    handleClose();
    setShowError(false);
    setShowBusy(true);
    var addMessage = firebase.functions().httpsCallable('skipTemperatureUpload');

    addMessage({})
    .then(function(result) {
      props.history.push(ROUTES.PROFILE);
    })
    .catch(function(error) {
      if (error.message === 'internal') {
        setErrorMessage('Unable to communicate with the PWT servers. Check your internet connection and try again.');
      } else {
        setErrorMessage(error.message);
      }
      setShowError(true);
      setShowBusy(false);
    });
  }

  const validationSchema = Yup.object().shape({
    temperature: Yup.string()
    .test('passwords-match', 'Temperature must be in range (35 - 40 ℃ or 95 - 104 ℉).', function(value) {
      if ((Number(value) >= 35 && Number(value) <= 40) || (Number(value) >= 95 && Number(value) <= 104)) {
        return true;
      } else {
        return false;
      }
    })
    .required("Temperature is required.")
    .matches(/^\d+(\.\d{1,2})?$/,
      "Must be a valid number with no more than two decimal places."
    ),
    confirm: Yup.bool()
    .oneOf([true], 'Please confirm to continue.'),
  });

    
  useEffect(() => {
    let groups = userContext.userDoc.joined_groups;
    if (groups) {
      if (groups.length > 0) {
        setDisplaySkipOption(true)
      }
    } 
  }, []);


  return (
    <div>       
        <LoadingOverlay
          active={showBusy}
          spinner
          text='Submitting update...'
          styles={{
            overlay: (base) => ({
              ...base,
            })
          }}
        >
        <Formik
          initialValues={{
            temperature:"", chills: false, fatigue: false, 
            aches: false, congestion: false, 
            soreThroat: false, cough: false,
            shortnessOfBreath: false, nausea: false,
            diarrhea: false, headache: false,
            lossOfTaste: false, householdSymptoms: false,
            householdTesting: false, isolate: false,
            confirm: false}}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                setShowError(false);
                setShowBusy(true);
                var addMessage = firebase.functions().httpsCallable('uploadTemperature');
                let unit = '';
                if (values.temperature >= 35 && values.temperature <= 40) {
                  unit = 'celsius';
                } else if (values.temperature >= 95 && values.temperature <= 104) {
                  unit = 'fahrenheit';
                }
                let symptomsArray = []
                if (values.chills) {
                  symptomsArray.push('chills')
                }
                if (values.fatigue) {
                  symptomsArray.push('fatigue')
                }
                if (values.aches) {
                  symptomsArray.push('aches')
                }
                if (values.congestion) {
                  symptomsArray.push('congestion')
                }
                if (values.soreThroat) {
                  symptomsArray.push('sore_throat')
                }
                if (values.cough) {
                  symptomsArray.push('cough')
                }
                if (values.shortnessOfBreath) {
                  symptomsArray.push('shortness_of_breath')
                }
                if (values.nausea) {
                  symptomsArray.push('nausea')
                }
                if (values.diarrhea) {
                  symptomsArray.push('diarrhea')
                }
                if (values.headache) {
                  symptomsArray.push('headache')
                }
                if (values.lossOfTaste) {
                  symptomsArray.push('loss_of_taste')
                }
                if (values.householdSymptoms) {
                  symptomsArray.push('household_symptoms')
                }
                if (values.householdTesting) {
                  symptomsArray.push('household_testing')
                }
                if (values.isolate) {
                  symptomsArray.push('isolate')
                }
                addMessage({temperature: values.temperature, unit: unit, symptoms: symptomsArray, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone})
                .then(function(result) {
                  setShowBusy(false);
                  props.history.push(ROUTES.PROFILE);
                })
                .catch(function(error) {
                  if (error.message === 'internal') {
                    setErrorMessage('Unable to communicate with the PWT servers. Check your internet connection and try again.');
                  } else {
                    setErrorMessage(error.message);
                  }
                  setShowError(true);
                  setShowBusy(false);
                });

          }}
        >
        {( {values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            
            
            <Form.Group controlId="formTemperature">
              <Form.Label>Current Temperature:</Form.Label>
              <Form.Control
                type="number"
                step="any"
                name="temperature"
                inputMode='decimal'
                placeholder="97.7 ℉ or 36.5 ℃"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.temperature}
                isInvalid={errors.temperature && touched.temperature}
                isValid={!errors.temperature && touched.temperature}
              />
              <Form.Control.Feedback type="invalid" style={{
                      color: '#dc3545',
                      fontSize: '.8em',
                    }}>
                {errors.temperature}
              </Form.Control.Feedback>
            </Form.Group>
            
            
            <Form.Group controlId="formSymptoms">
              {(!showSymptomsMenu)
              ? <div>
                <Button className='pwt-button' disabled={showBusy} block size="sm"  type="button" onClick={() => setShowSymptomsMenu(true)}>
                  Report Symptoms
                </Button>
                </div>
              :
              <div>
                <Button className='pwt-button' disabled={showBusy} block size="sm"  type="button" onClick={() => setShowSymptomsMenu(false)}>
                  Hide Symptoms Menu
                </Button>
                <Form.Label style={{paddingTop: 10}}>Are you currently experiencing, or have you experienced within the past 10 days any of the following symptoms?</Form.Label>
                <Row style={{paddingBottom:10}}>
                    <Col>
                      <Form.Check
                        name="chills"
                        label="Chills with shaking or teeth chattering"
                        disabled={showBusy}
                        onChange={handleChange}
                        id="checkChills"
                      />
                      <Form.Check
                        name="fatigue"
                        label="Fatigue"
                        disabled={showBusy}
                        onChange={handleChange}
                        id="checkFatigue"
                      />
                      <Form.Check
                        name="aches"
                        label="Muscle or body aches"
                        disabled={showBusy}
                        onChange={handleChange}
                        id="checkAches"
                      />
                      <Form.Check
                        name="congestion"
                        label="Congestion or runny nose"
                        disabled={showBusy}
                        onChange={handleChange}
                        id="checkCongestion"
                      />
                      <Form.Check
                          name="soreThroat"
                          label="Sore throat"
                          disabled={showBusy}
                          onChange={handleChange}
                          id="checkSoreThroat"
                      />
                    </Col>
                    <Col>
                      <Form.Check
                            name="cough"
                            label="Frequent cough"
                            disabled={showBusy}
                            onChange={handleChange}
                            id="checkCough"
                      />
                      <Form.Check
                          name="shortnessOfBreath"
                          label="Shortness of breath at rest"
                          disabled={showBusy}
                          onChange={handleChange}
                          id="checkShortnessOfBreath"
                      />
                      <Form.Check
                          name="nausea"
                          label="Nausea or vomiting"
                          disabled={showBusy}
                          onChange={handleChange}
                          id="checkNausea"
                      />
                      <Form.Check
                          name="diarrhea"
                          label="Diarrhea"
                          disabled={showBusy}
                          onChange={handleChange}
                          id="checkDiarrhea"
                      />
                      <Form.Check
                          name="headache"
                          label="Headache"
                          disabled={showBusy}
                          onChange={handleChange}
                          id="checkHeadache"
                      />
                      <Form.Check
                          name="lossOfTaste"
                          label="Loss of ability to taste or smell"
                          disabled={showBusy}
                          onChange={handleChange}
                          id="checkLostOfTaste"
                      />
                    </Col>
                  </Row>
                <Form.Label>Check all that apply:</Form.Label>
                <Row style={{paddingTop:0}}>
                  <Col>
                    <Form.Check
                          name="householdSymptoms"
                          label="A member of my household is sick at home with bronchitis-like or cold symptoms."
                          disabled={showBusy}
                          onChange={handleChange}
                          id="checkFamilySymptoms"
                      />
                      <Form.Check
                          name="householdTesting"
                          label="I or a member of my household is awaiting COVID-19 test results."
                          disabled={showBusy}
                          onChange={handleChange}
                          id="checkFamilyTesting"
                      />
                      <Form.Check
                          name="isolate"
                          label="I have been told to self-isolate."
                          disabled={showBusy}
                          onChange={handleChange}
                          id="checkIsolate"
                      />
                  </Col>
                </Row>
              </div>

              }
              <Row style={{paddingTop:10}}>
                <Col>
                    <hr/>
                    <Form.Check
                        name="confirm"
                        label="I have provided all information to the best of my ability."
                        disabled={showBusy}
                        onChange={handleChange}
                        isInvalid={errors.confirm && touched.confirm}
                        feedback={errors.confirm}
                    />
                    <Form.Control.Feedback type="invalid" style={{
                      color: '#dc3545',
                      fontSize: '.8em',
                    }}>
                      {errors.confirm}
                    </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
            <Alert show={showError} variant="danger">
              <Alert.Heading>Error Updating Temperature</Alert.Heading>
              <p>{errorMessage}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShowError(false)} variant="outline-danger">
                  Dismiss
                </Button>
              </div>
            </Alert>
            <Button className='pwt-button' disabled={showBusy} block size="lg" type="submit">
              Update Temperature
            </Button>
          </Form>
        )}
        </Formik>
          { (displaySkipOption)
          ?
            <>
              <Button variant="link" onClick={handleShow}>
              If you already have a PWT, are healthy, and do not wish to share more health information, click here.
              </Button>
              <Modal
                show={showSkipModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Update Without Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <>
                <p>If you have already established your personal warning temperature, you may elect to declare that your health is in good standing without sharing details. Doing so will inform your organization of your election. Your organization must opt-in to accept this result.</p>
                <p><b>By updating, you are confirming that you have both taken your temperature with it measuring below your PWT and are experiencing no symptoms</b>.</p>
                </>
                </Modal.Body>
                <Modal.Footer>
                  <Button className='pwt-button-secondary' onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button className='pwt-button' onClick={skipUpdate}>Update</Button>
                </Modal.Footer>
              </Modal>
            </>
          :
            null
          }
          
        </LoadingOverlay>
    </div>         
  );
}


export default compose(
  withRouter,
  withFirebase
)(UpdateTemperatureModule)

