import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Header from '../Header';

import { withAuthentication } from '../Session';
import { FirebaseAnalytics } from '../Firebase';

import MainSwitch from '../MainSwitch';



function App() {
  return (
    <HelmetProvider>
      <Router>
        <Header></Header>
        <MainSwitch></MainSwitch>
        <FirebaseAnalytics></FirebaseAnalytics>
      </Router>
  </HelmetProvider>
  );
}

export default withAuthentication(App);
