import React, { useState } from 'react';

import { withAuthorization } from '../../components/Session';

import { Alert, Button, Container, FormGroup, FormControl, Form } from "react-bootstrap";

import firebase from 'firebase/app';
import 'firebase/functions'

import LoadingOverlay from 'react-loading-overlay';


import { Formik } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';



function TemperatureTrackImportPage(props) {
  return(
      <Container style={{padding:0}}>   
        <Helmet>
          <title>Account Import | PWT</title>
          <meta name="description" content="Import your TemperatureTrack.us temperature data." />
        </Helmet>          
        <TemperatureTrackImportBase></TemperatureTrackImportBase>
      </Container>       
  );
}


function TemperatureTrackImportBase(props) {
  return (
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>TemperatureTrack Import</h1>
      <p>Enter your credentials below to attempt to import any existing data from a valid <a href="https://temperaturetrack.us" target='_blank' rel="noopener noreferrer">TemperatureTrack</a> account.</p>
      <p>Don't worry, you can always undo this import if your data doesn't look correct or meaningful.</p>
      <TemperatureTrackImportModule></TemperatureTrackImportModule>
      <hr/>
      <TemperatureTrackRemoveModule></TemperatureTrackRemoveModule>
    </div>
  );
}

function TemperatureTrackImportModule(props) {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showError, setShowError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [showBusy, setShowBusy] = useState(false);

  const [selectedOrg, setSelectedOrg] = useState('secureplanet');


  const validationSchema = Yup.object().shape({
    userID: Yup.string()
    .required("User ID is required.")
    .matches(
      /^\S*$/,
      "May not contain spaces."
    ),
    pin: Yup.string()
    .required("PIN is required."),
  });

  return(
    <div>
      <LoadingOverlay
        active={showBusy}
        spinner
        text='Fetching data from TemperatureTrack.us...'
        styles={{
          overlay: (base) => ({
            ...base,
          })
        }}
      >     
      <Formik
          initialValues={{ userID:"", pin:""}}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting, resetForm}) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);
              // importTTData();
              setShowError(false);
              setShowWarning(false);
              setShowSuccess(false);
              setShowBusy(true);
              // event.preventDefault();
              var addMessage = firebase.functions().httpsCallable('importTTAccount');
              addMessage({userID: values.userID, pin: values.pin, organization: selectedOrg})
              .then(function(result) {
                // Read result of the Cloud Function.
                setSuccessMessage(`${result.data.importedEntries} entries have been successfully imported from TemperatureTrack to PWT.`);
                setShowSuccess(true);
                setShowBusy(false);
              })
              .catch(function(error) {
                if (error.message === 'internal') {
                  setErrorMessage('Unable to communicate with the PWT servers. Check your internet connection and try again.');
                } else {
                  setErrorMessage(error.message);
                }
                setShowError(true);
                setShowBusy(false);
              });
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
        {( {values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup controlId="email" bssize="large">
              <Form.Label>User ID:</Form.Label>
                <FormControl
                // autoFocus
                  type="text"
                  name="userID"
                  placeholder="example"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.userID}
                  isInvalid={errors.userID && touched.userID}
                  isValid={!errors.userID && touched.userID}
                />
                <Form.Control.Feedback type="invalid" style={{
                    color: '#dc3545',
                    fontSize: '.8em',
                  }}>
                  {errors.userID}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup controlId="password" bssize="large">
                <Form.Label>PIN:</Form.Label>
                <FormControl
                  type="password"
                  name="pin"
                  placeholder="****"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pin}
                  isInvalid={errors.pin && touched.pin}
                  isValid={!errors.pin && touched.pin}
                />
                <Form.Control.Feedback type="invalid" style={{
                    color: '#dc3545',
                    fontSize: '.8em',
                  }}>
                  {errors.pin}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup controlId="organization" bssize="large">
              <label>Your Organization: </label>
                <select className="form-control" id="organizationSelect" onChange={ (e) => setSelectedOrg(e.target.value)}>
                  <option value="secureplanet">I-3 / Secure Planet</option>
                  <option value="chantillyair">Chantilly Air, LLC</option>
                </select>
              </FormGroup>
              <Alert show={showSuccess} variant="success">
                <Alert.Heading>Account Imported</Alert.Heading>
                <p>{successMessage}</p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowSuccess(false)} variant="outline-success">
                    Dismiss
                  </Button>
                </div>
              </Alert>
              <Alert show={showWarning} variant="warning">
                <Alert.Heading>Warning</Alert.Heading>
                <p>
                Something happened.
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowWarning(false)} variant="outline-warning">
                    Dismiss
                  </Button>
                </div>
              </Alert>
              <Alert show={showError} variant="danger">
                <Alert.Heading>Error Importing Account</Alert.Heading>
                <p>{errorMessage}</p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowError(false)} variant="outline-danger">
                    Dismiss
                  </Button>
                </div>
              </Alert>
              <Button className='pwt-button' block size="lg"  type="submit">
                  Import TemperatureTrack Data
              </Button>
            </Form>
          
        )}
        </Formik>
        </LoadingOverlay>  
    </div>   
  );
}



function TemperatureTrackRemoveModule(props) {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showError, setShowError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");


  const [showBusy, setShowBusy] = useState(false);

  function removeTTData(event) {
    setShowError(false);
    setShowWarning(false);
    setShowSuccess(false);
    setShowBusy(true);
    event.preventDefault();
    var addMessage = firebase.functions().httpsCallable('removeTTAccount');
    addMessage()
    .then(function(result) {
      // Read result of the Cloud Function.
      setSuccessMessage(`${result.data.deletedEntries} imported TemperatureTrack entries have been removed from PWT.`);
      setShowSuccess(true);
      setShowBusy(false);
    })
    .catch(function(error) {
      if (error.message === 'internal') {
        setErrorMessage('Unable to communicate with the PWT servers. Check your internet connection and try again.');
      } else {
        setErrorMessage(error.message);
      }
      setShowError(true);
      setShowBusy(false);
    });
  }
    return(
      <div>
        <LoadingOverlay
        active={showBusy}
        spinner
        text='Removing TemperatureTrack data...'
        styles={{
          overlay: (base) => ({
            ...base,
          })
        }}
        >       
        <Form onSubmit={removeTTData}>
            <h2>Undo Import</h2>
            <p>Removes all previously imported <a href="https://temperaturetrack.us" target='_blank' rel="noopener noreferrer">TemperatureTrack</a> data from your account.</p>
            <Alert show={showSuccess} variant="success">
            <Alert.Heading>Import Undone</Alert.Heading>
                <p>{successMessage}</p>
                <hr />
                <div className="d-flex justify-content-end">
                <Button onClick={() => setShowSuccess(false)} variant="outline-success">
                  Dismiss
                </Button>
            </div>
            </Alert>
            <Alert show={showWarning} variant="warning">
                      <Alert.Heading>Warning</Alert.Heading>
                      <p>
                      Something happened.
                      </p>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <Button onClick={() => setShowWarning(false)} variant="outline-warning">
                          Dismiss
                        </Button>
                      </div>
            </Alert>
            <Alert show={showError} variant="danger">
                      <Alert.Heading>Error Undoing Import</Alert.Heading>
                      <p>{errorMessage}</p>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <Button onClick={() => setShowError(false)} variant="outline-danger">
                          Dismiss
                        </Button>
                      </div>
            </Alert>
            <Button className='pwt-button' block size="lg" type="submit">
              Remove TemperatureTrack Data
            </Button> 
        </Form>
        </LoadingOverlay>
      </div>   
  );
}




  
const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(TemperatureTrackImportPage);
