import React, { useState } from 'react';
import { withRouter, Link, useParams } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import { Alert, Button, Container, Form, FormGroup, Modal, Row, Col } from "react-bootstrap";

import { Formik } from 'formik';
import * as Yup from 'yup';

import { enforceLogin } from '../../components/Session';

import firebase from 'firebase/app';
import 'firebase/functions'
import LoadingOverlay from 'react-loading-overlay';

import { Helmet } from 'react-helmet-async';



const RedeemTrialPage = () => (
    <Container style={{padding:0}}>
      <Helmet>
        <title>Redeem Trial | PWT</title>
        <meta name="description" content="Redeem your PWT Promo Code to start your free trial."/>
      </Helmet> 
        <RedeemTrialBase></RedeemTrialBase>
    </Container>            
);

function QueryURL() {
  let { promo } = useParams();
  if (promo == null) {
    promo = ''
  }
  return {promo}
}

function RedeemTrialBase(props) {

  return(
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Start Your PWT Trial</h1>
      <h2>Redeem Your Promo Code</h2>
      <RedeemTrialForm></RedeemTrialForm>
    </div>  
  );
}

function RedeemTrialModule(props) {
  let query = QueryURL();

  const [showError, setShowError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showSuccessPrompt, setShowSuccessPrompt] = useState(false);

  const [trialLengthDays, setTrialLengthDays]  = useState(0);

  const [validPromoCode, setValidPromoCode] = useState('');

  const validationSchema = Yup.object().shape({
    promoCode: Yup.string()
    .required("Promo Code is required")
    .label('Promo Code'),
  });

  const handleClose = () => setShowSuccessPrompt(false);
  const handleShow = () => setShowSuccessPrompt(true);


  return(
    <div>    
      <Modal
        show={showSuccessPrompt}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Valid Promo Code!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            (trialLengthDays < 1000)
            ?
              <p>The specified promo code is valid for a <b>{trialLengthDays}-day trial</b>. Click "Sign Up" to complete your registration.</p>
            :
              <p>The specified promo code is valid for a <b>lifetime membership</b>. Click "Sign Up" to complete your registration.</p>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button className='pwt-button-secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button className='pwt-button' onClick={() => props.history.push(`/trial/signup/${validPromoCode}`)}>Sign Up</Button>
        </Modal.Footer>
      </Modal>   
        <Formik
        initialValues={{ promoCode: query.promo }}
        validationSchema={validationSchema}
        onSubmit={(values, {setSubmitting, resetForm}) => {
          setSubmitting(true);
          setShowError(false);
          setSubmitting(true);
          var addMessage = firebase.functions().httpsCallable('checkPromo');
          addMessage({promoCode: values.promoCode})
          .then(function(result) {
            setValidPromoCode(values.promoCode);
            setTrialLengthDays(result.data.trial_length);
            setSubmitting(false);
            setShowSuccessPrompt(true);
          })
          .catch(function(error) {
            setSubmitting(false);
            setErrorMessage(error.message);
            setShowError(true);
          });
        }}
      >
      {( {values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="mx-auto">
          <LoadingOverlay
            active={isSubmitting}
            spinner
            text='Checking promo code...'
            styles={{
              overlay: (base) => ({
                ...base,
                borderRadius:"12.5px"
              })
            }}
          >

          <Form.Group controlId="formPromoCode">
              <Form.Label>Promo Code:</Form.Label>
              <Form.Control
                type="text"
                name="promoCode"
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.promoCode.toUpperCase()}
                isInvalid={errors.promoCode && touched.promoCode}
                isValid={!errors.promoCode && touched.promoCode}
              />
              <Form.Control.Feedback type="invalid" style={{
                      color: '#dc3545',
                      fontSize: '.8em',
                    }}>
                {errors.promoCode}
              </Form.Control.Feedback>
          </Form.Group>
          <Alert show={showError} variant="danger">
          <Alert.Heading>Could Not Redeem</Alert.Heading>
            <p>{errorMessage}</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShowError(false)} variant="outline-danger">
                Dismiss
              </Button>
            </div>
          </Alert>
          <Button className='pwt-button' block size="lg"  type="submit" disabled={isSubmitting}>
            Redeem Promo Code
          </Button>
          </LoadingOverlay>
        </Form>
          )}
      </Formik>
    </div>         
);
}


const RedeemTrialForm = compose(
  withRouter,
  withFirebase,
)(RedeemTrialModule);

export { RedeemTrialForm };

const condition = authUser => !!authUser;

export default enforceLogin(condition)(RedeemTrialPage);