import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorization = condition => Component => {

  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (!condition(authUser)) {
            this.props.history.push(ROUTES.SIGN_IN_ANONYMOUS);
          } 
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    // TODO: Conditionally render either component, invalid account (bad DB structure), or license expired (popup?)
    render() {
      return (
        <AuthUserContext.Consumer>
          {value =>
            condition(value.userDoc) ? 
            <Component {...this.props} /> 
            : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(
    withRouter,
    withFirebase,
  )(WithAuthorization);
};

export default withAuthorization;
