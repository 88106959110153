import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { withAuthorization } from '../../components/Session';

import { Alert, Button, Container } from "react-bootstrap";

import firebase from 'firebase/app';
import Dots from 'react-activity/lib/Dots';
import { Helmet } from 'react-helmet-async';



function VerifyViewPage(props) {
  return(
    <Container style={{padding:0}}>      
      <Helmet>
        <title>Temperature Verification Report | PWT</title>
        <meta name="description" content="View the results of a temperature verification." />
        <meta name="robots" content="noindex"/>
      </Helmet>       
      <VerifyViewPageBase></VerifyViewPageBase>
    </Container>               
  );
}


function VerifyViewPageBase(props) {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showBusy, setShowBusy] = useState(false);

  const [secret, setSecret] = useState(useParams())

  const [isValid, setIsValid] = useState(false);

  const [result, setResult] = useState(false);

  const [measuredTemperature, setMeasuredTemperature] = useState(0);

  const [measuredTemperatureUnit, setMeasuredTemperatureUnit] = useState('℉');

  const [isExpired, setIsExpired] = useState(false);

  const [expiration, setExpiration] = useState();

  const [timestamp, setTimestamp] = useState();


  

  
  useEffect(() => {
    function checkLegitimacy() {
      setShowError(false);
      setShowBusy(true);
      var addMessage = firebase.functions().httpsCallable('checkVerification');
      addMessage({secret: secret.id})
      .then(function(result) {
        setResult(result.data.result);
        setTimestamp(new Date(result.data.timestamp._seconds * 1000).toLocaleString());
        if (result.data.expired === true) {
          setIsExpired(true);
        }  else {
          setIsExpired(false);
          if (result.data.unit === 'fahrenheit') {
            setMeasuredTemperatureUnit('℉')
          } else {
            setMeasuredTemperatureUnit('℃')
          }
          setMeasuredTemperature(result.data.test_temperature);
        }
        setExpiration(new Date(result.data.expiration._seconds * 1000).toLocaleString());
        setShowBusy(false);
        setIsValid(true);
      })
      .catch(function(error) {
        setErrorMessage(error.message);
        setShowBusy(false);
        setIsValid(false);
      });
    }

    checkLegitimacy();
  }, [secret.id]);

  if (isValid === true) {
    return (
            <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
        <h1>Temperature Verification Report</h1>
        {(showBusy === true)
        ? 
          <div style={{display:"flex",flexDirection:"column", alignItems:'center', justifyContent:"center", paddingTop:20}}>
              <Dots color="#21436D" size={32} speed={1} animating={showBusy} />
          </div>
        : 
        <div>
          {(isExpired === true)
            ? 
              <div>
                <h2>Expired Report</h2>
                <p>The specified report is expired.</p>
                <p><b>Created</b>: {timestamp}</p>
                <p><b>Expiration</b>: {expiration}</p>
              </div>
            : 
              <div>
                <h2>Valid Report</h2>
                <p><b>Created</b>: {timestamp}</p>
                <p><b>Expiration</b>: {expiration}</p>
                {(result === true)
                ? 
                <div>
                    <h3 style={{color:'green'}}><b>PASS</b></h3>
                    <h4><b>Measured Temperature</b>: {measuredTemperature} {measuredTemperatureUnit}</h4>
                    <p>This report confirms that the user tested below their Personal Warning Temperature.</p>
                </div>
                : 
                <div>
                    <h3 style={{color:'red'}}><b>FAIL</b></h3>
                    <h3><b>Measured Temperature</b>: {measuredTemperature} {measuredTemperatureUnit}</h3>
                    <p>This report confirms that the user tested above or at their Personal Warning Temperature.</p>
                </div>
                }
                <br/>
                <h3>Fraudulent Report Notice:</h3>
                <p>To help prevent anyone from attempting to spoof a temperature report, always follow the below guidelines:</p>
                <ul>
                  <li>Ensure your browser is connected to our official domain of '<a href='https://app.warningtemperature.health' target='_blank' rel="noopener noreferrer">https://app.warningtemperature.health</a>.'</li>
                  <li>Check for the padlock icon in your address bar to confirm that you are connected securely.</li>
                  <li>Never check a report on a device you do not trust.</li>
                </ul>
              </div>
              }
            <div style={{paddingTop:20}}>
                <Alert show={showError} variant="danger">
                          <Alert.Heading>Error Validating Temperature</Alert.Heading>
                          <p>{errorMessage}</p>
                          <hr />
                          <div className="d-flex justify-content-end">
                            <Button onClick={() => setShowError(false)} variant="outline-danger">
                              Dismiss
                            </Button>
                          </div>
                </Alert>
            </div>
        </div>
        }
      </div>
    );
  } else {
    return (
            <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
        <h1>Temperature Verification Report</h1>
        {(showBusy === true)
          ? 
            <div style={{display:"flex",flexDirection:"column", alignItems:'center', justifyContent:"center", paddingTop:20}}>
                <Dots color="#21436D" size={32} speed={1} animating={showBusy} />
            </div>
          : 
          <div>
            <h3>Invalid Report</h3>
            <p>The specified report was not found and is therefore invalid. Please try again with a new report.</p>
            <p>Note that expired reports are removed after several days.</p>
            <div style={{paddingTop:20}}>
                  <Alert show={showError} variant="danger">
                            <Alert.Heading>Error Validating Temperature</Alert.Heading>
                            <p>{errorMessage}</p>
                            <hr />
                            <div className="d-flex justify-content-end">
                              <Button onClick={() => setShowError(false)} variant="outline-danger">
                                Dismiss
                              </Button>
                            </div>
                  </Alert>
            </div>
          </div>
        }
      </div>   
    );
  } 
}
  
const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(VerifyViewPage);
