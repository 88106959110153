import React, {useContext, useEffect, useState} from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

import { Button, Col, Row } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';

const PROVISIONAL_TEMPERATURE_FAHRENHEIT = 99;
const PROVISIONAL_TEMPERATURE_CELSIUS = 37.2222;

function TemperatureMemberAssessmentModuleBase(props) {
    let user = props.user
    let temperatureCallback = props.temperatureCallback;

    const [showBusy, setShowBusy] = useState(false);
    const [showAssessment, setShowAssessment] = useState('none');
  
    const [measuredTemperature, setMeasuredTemperature] = useState(0);
  
    const [temperatureData, setTemperatureData] = useState([]);
  
    const [temperatureDataMean, setTemperatureDataMean] = useState(0);
    
    const [totalTemperatureEntries, setTotalTemperatureEntries] = useState(0);
    const [totalHealthyEntries, setTotalHealthyEntries] = useState(0);
    const [warningTemperature, setWarningTemperature] = useState('');
  
    const [provisionalWarningTemperature, setProvisionalWarningTemperature] = useState(PROVISIONAL_TEMPERATURE_FAHRENHEIT);
  
    const [healthStatus, setHealthStatus] = useState('');
    const [healthTitle, setHealthTitle] = useState('');
    const [healthMessage, setHealthMessage] = useState('');
  
    const [chartUnit, setChartUnit] = useState('℉');
      
    const [hasUpdatedPlottingUnit, setHasUpdatedPlottingUnit] = useState(false);
    const [preferredPlottingUnit, setPreferredPlottingUnit] = useState('fahrenheit');
  
    const [assessmentData, setAssessmentData] = useState([]);
      
    const [lastUpdateString, setLastUpdateString] = useState('');
  
  
    function fahrenheitToCelsius(fahrenheit: number) {
      return ((fahrenheit - 32.0) * (5.0 / 9.0 ))
    }
  
    function celsiusToFahrenheit(celsius: number) {
      return ((celsius * 9.0 / 5.0 ) + 32.0)
    }
  
    function updatePlottingUnit(unit) {
      setHasUpdatedPlottingUnit(true);
      setPreferredPlottingUnit(unit);
    }
  
    function setNoDataStates() {
  
      setWarningTemperature(0);
      setProvisionalWarningTemperature(PROVISIONAL_TEMPERATURE_FAHRENHEIT);
      setChartUnit('℉');
  
      setTemperatureData([]);
    }  
  
    function TemperatureAssessmentBadge() {
      return (
            <Col>
              <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
              <Row>      
              <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                  {/* <h4>{`${healthTitle}`}</h4> */}
                  <p style={{fontSize: '1.4rem'}}>{`${healthTitle}`}</p>
                  <p><b>{`Measurement`}</b>{`: ${measuredTemperature} ${chartUnit}`}</p>
                  <p>{`${healthMessage}`}</p>
              </div>
              </Row>
              <Row>
                <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", marginBottom: 15}}>
                    {(warningTemperature !== 0)
                      ? 
                            <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", width: 150, height: 150, backgroundColor: 'darkgray', border: '10px solid #21436D', borderRadius: '50%'}}>
                                <Row style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", height:'100%'}}>
                                  {/* <h6><b>PWT</b></h6> */}
                                  <p style={{fontSize: '1.0rem'}}><b>{`PWT`}</b></p>
                                </Row>
                                <Row style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", height:'100%'}}>
                                  {/* <h4><b>{`${warningTemperature} ${chartUnit}`}</b></h4> */}
                                  <p style={{fontSize: '1.4rem'}}><b>{`${warningTemperature} ${chartUnit}`}</b></p>
                                </Row>
                                <Row style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", height:'100%'}}>
                                </Row>
                            </div>
                      : 
                          <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", width: 175, height: 175, backgroundColor: 'darkgray', border: '10px solid #21436D', borderRadius: '50%'}}>
                            <Row style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", height:'100%'}}>
                              {/* <h6><b>PWT</b></h6> */}
                              <p style={{fontSize: '1.0rem'}}><b>{`PWT`}</b></p>
                            </Row>
                            <Row style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", height:'100%'}}>
                              {/* <h4><b>{`${provisionalWarningTemperature} ${chartUnit}`}</b></h4> */}
                              <p style={{fontSize: '1.4rem'}}><b>{`${provisionalWarningTemperature} ${chartUnit}`}</b></p>
                            </Row>
                            <Row style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", height:'100%'}}>
                              <p><b>(provisional)</b></p>
                            </Row>
                          </div>
                      }
                </div>
              </Row>
              <Row>      
                <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                  {(warningTemperature === 0)
                  ?
                  <p>Use this <i><b>provisional</b></i> temperature as a guide until you have enough temperature entries to create your own Personal Warning Temperature.</p>
                  :
                  null
                  }
                  <p><b>{`Updated`}</b>{`: ${lastUpdateString}`}</p>
                 
                </div>
              </Row>
              </div>
            </Col>
      );
    }
  
    function updateBadge() {
      const parsedTemperatureData: { temperature: string; unit: string; timestamp: string; docID: string}[] = [];
      let temperatures: any[] = [];
      let pwtLastUpdate = assessmentData[0];
      let plottingUnit = ''
      if (hasUpdatedPlottingUnit === true) {
        plottingUnit = preferredPlottingUnit;
      } else {
        plottingUnit = pwtLastUpdate.unit;
      }
  
      let tempMeasuredTemperature = 0;
      let tempCDCFever = 0;
      let tempChannelUpper = 0;
      let tempChannelLower = 0;
      let tempPersonalWarningTemperature = 0;
      let tempTemperatureDataMeanPlotting = 0;
      let tempProvisionalWarningTemperaturePlotting = 0;
      let tempPersonalWarningTemperaturePlotting = 0;
      let tempTotalEntries = 0;
      let tempHealthyEntries = 0;
      let tempStatusTitle = '';
      let tempStatusMessage = '';
      let tempStatus = '';
      let tempChartUnit = '';
      let tempLastUpdateDate: Date;
      let tempLastUpdateDateString = '';
  
      tempMeasuredTemperature = pwtLastUpdate.temperature;
      tempPersonalWarningTemperature = pwtLastUpdate.health_assessment.personal_warning_temperature
      tempTemperatureDataMeanPlotting = pwtLastUpdate.health_assessment.mean;
      tempPersonalWarningTemperaturePlotting = pwtLastUpdate.health_assessment.personal_warning_temperature
      
      tempChannelUpper = pwtLastUpdate.health_assessment.channel_upper;
      tempChannelLower = pwtLastUpdate.health_assessment.channel_lower;
      tempTotalEntries = pwtLastUpdate.health_assessment.temperature_data_total_entries;
      tempHealthyEntries = pwtLastUpdate.health_assessment.healthy_entries;
      tempStatusTitle = pwtLastUpdate.health_assessment.title;
      tempStatusMessage = pwtLastUpdate.health_assessment.message;
      tempStatus = pwtLastUpdate.health_assessment.status;
  
      tempLastUpdateDate = new Date((assessmentData[0].timestamp.seconds * 1000));
      tempLastUpdateDateString = tempLastUpdateDate.toLocaleString(undefined, { timeZoneName: 'short' });
      
      // Conversions
      if (pwtLastUpdate.unit === 'fahrenheit') {
        tempProvisionalWarningTemperaturePlotting = PROVISIONAL_TEMPERATURE_FAHRENHEIT;
      } else if (pwtLastUpdate.unit === 'celsius') {
        tempProvisionalWarningTemperaturePlotting = PROVISIONAL_TEMPERATURE_CELSIUS;
      }
  
      if (plottingUnit === 'fahrenheit') {
        tempChartUnit = '℉';
        if (pwtLastUpdate.unit === 'fahrenheit') {
          // do nothing
        } else if (pwtLastUpdate.unit === 'celsius') {
          tempMeasuredTemperature = celsiusToFahrenheit(parseFloat(tempMeasuredTemperature));
          tempProvisionalWarningTemperaturePlotting = celsiusToFahrenheit(parseFloat(tempProvisionalWarningTemperaturePlotting));
          if (tempPersonalWarningTemperaturePlotting !== 0) {
            tempTemperatureDataMeanPlotting = celsiusToFahrenheit(parseFloat(tempTemperatureDataMeanPlotting));
            tempPersonalWarningTemperaturePlotting = celsiusToFahrenheit(parseFloat(tempPersonalWarningTemperaturePlotting));
            tempChannelUpper = celsiusToFahrenheit(parseFloat(tempChannelUpper));
            tempChannelLower = celsiusToFahrenheit(parseFloat(tempChannelLower));
          }
        }
      } else if (plottingUnit === 'celsius') {
        tempChartUnit = '℃';
        if (pwtLastUpdate.unit === 'fahrenheit') {  
          tempMeasuredTemperature = fahrenheitToCelsius(parseFloat(tempMeasuredTemperature));
          tempProvisionalWarningTemperaturePlotting = fahrenheitToCelsius(parseFloat(tempProvisionalWarningTemperaturePlotting));
          if (tempPersonalWarningTemperaturePlotting !== 0) {
            tempTemperatureDataMeanPlotting = fahrenheitToCelsius(parseFloat(tempTemperatureDataMeanPlotting));
            tempPersonalWarningTemperaturePlotting = fahrenheitToCelsius(parseFloat(tempPersonalWarningTemperaturePlotting));
            tempChannelUpper = fahrenheitToCelsius(parseFloat(tempChannelUpper));
            tempChannelLower = fahrenheitToCelsius(parseFloat(tempChannelLower));
          }
        } else if (pwtLastUpdate.unit === 'celsius') {
          // do nothing
        }
      }
      
      // Rounding
      tempMeasuredTemperature = Number(Math.round(parseFloat(tempMeasuredTemperature + 'e' + 2)) + 'e-' + 2);
      tempPersonalWarningTemperaturePlotting = Number(Math.round(parseFloat(tempPersonalWarningTemperaturePlotting + 'e' + 2)) + 'e-' + 2);
      tempProvisionalWarningTemperaturePlotting = Number(Math.round(parseFloat(tempProvisionalWarningTemperaturePlotting + 'e' + 2)) + 'e-' + 2);
      tempTemperatureDataMeanPlotting = Number(Math.round(parseFloat(tempTemperatureDataMeanPlotting + 'e' + 2)) + 'e-' + 2);
      temperatureCallback(tempLastUpdateDate);
      // Messages
      setMeasuredTemperature(tempMeasuredTemperature);
      setTemperatureDataMean(tempTemperatureDataMeanPlotting);
      setWarningTemperature(tempPersonalWarningTemperaturePlotting);
      setProvisionalWarningTemperature(tempProvisionalWarningTemperaturePlotting);
      setTotalTemperatureEntries(tempTotalEntries);
      setTotalHealthyEntries(tempHealthyEntries);
      setHealthTitle(tempStatusTitle);
      setHealthMessage(tempStatusMessage);
      setHealthStatus(tempStatus);
      setLastUpdateString(tempLastUpdateDateString);
  
      // Unit
      setChartUnit(tempChartUnit);
      if (plottingUnit !== preferredPlottingUnit) {
        setPreferredPlottingUnit(plottingUnit);
      }
    
      setShowBusy(false); 
    }
  
    useEffect(() => {
      setShowBusy(true);
      // Fetch last temperature entry doc
      var entry = [];
      props.firebase.temperature_data(user.uid)
      .orderBy("timestamp", 'desc')
      .limit(1)
      .get()
      .then((temperature_docs) => {
        let docs = temperature_docs.docs;
        if (docs.length > 0) {
          let doc = docs[0];
          entry.push(doc.data());
        }
        setAssessmentData(entry);
      })
      .catch((error) => {
        console.log(error);
        setAssessmentData(entry);
      })
    }, [user]);
    
    useEffect(() => { 
      async function loadPlottingData() {
        setShowBusy(true);
        // Fetch up-to-date health metrics from the latest temperature entry
        if (assessmentData.length < 1) {
            setNoDataStates();
            if (showBusy) {
              setShowBusy(false);
            }
          return;
        } else {
          updateBadge();
        }
        
      }
  
      loadPlottingData();
    }, [assessmentData, preferredPlottingUnit]);
    
      return (
        <LoadingOverlay
                  active={showBusy}
                  spinner
                  text='Refreshing...'
                  styles={{
                    overlay: (base) => ({
                      ...base,
                    })
                  }}
          >
        <div>     
          {(assessmentData.length < 1 && showBusy === false)
            ? 
              <div>
                <p>No data found.</p>
                <p>Upload your first temperature to start building your temperature profile!</p>
              </div>
            : 
              <div>
                <div style={{marginTop: 15}}>
                  {/* <h4>Temperature Assessment:</h4> */}
                  <p style={{fontSize: '1.4rem'}}>Temperature Assessment:</p>
                  <TemperatureAssessmentBadge></TemperatureAssessmentBadge>
                </div>
                <div style={{marginBottom: 15}}>
                  <label>Unit:</label>
                  <select className="form-control" id="plottingUnitSelector" value={preferredPlottingUnit} onChange={ (e) => updatePlottingUnit(e.target.value)}>
                    <option value="fahrenheit">Fahrenheit</option>
                    <option value="celsius">Celsius</option>
                  </select>
                </div>
              </div>
          } 
      </div>
      </LoadingOverlay> 
                    
      );
    
}


const TemperatureMemberAssessmentModule = compose(
    withFirebase,
  )(TemperatureMemberAssessmentModuleBase);
  
  export { TemperatureMemberAssessmentModule };
