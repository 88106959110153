import React, {useContext, useEffect, useState} from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

import { Button, Col, Row } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import ReactEcharts from 'echarts-for-react';

import firebase from 'firebase/app';

import DatePicker from "react-datepicker";

import { GreenScreenBannerModule } from '../GreenScreenBanner';
import { TemperatureAssessmentModule } from '../TemperatureAssessment'
import { TemperatureChartModule } from '../TemperatureChart'

import { AuthUserContext } from '../Session';



function TemperatureProfileBase(props) {
  let userContext = useContext(AuthUserContext);

  const [showAssessment, setShowAssessment] = useState(false);
  const [showChart, setShowCart] = useState(false);
  
  // Logic to show temperature assessment in the event the user doesn't belong to a group
  // This makes sense as they wouldn't need privacy by default as they aren't going to show their screen to someone
  useEffect(() => {
    let groups = userContext.userDoc.joined_groups;
    if (groups) {
      if (groups.length == 0) {
        setShowAssessment(true)
      }
    } else {
      setShowAssessment(true)
    }
  }, []);

  return (
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Temperature Profile</h1>
      <GreenScreenBannerModule></GreenScreenBannerModule>
      <hr/>
      {
        (userContext.assessmentDoc != null)
        ?
          <div>
            <Button className='pwt-button' block size="sm" type="button" onClick={() => 
              setShowAssessment(!showAssessment)
            }
            >
            {(showAssessment === true)
            ? 'Hide Assessment'
            : 'View Assessment'
            }
          </Button>
          {
            (showAssessment)
            ?
              <TemperatureAssessmentModule></TemperatureAssessmentModule>
            :
              null
          }
          <Button className='pwt-button' block size="sm" type="button" onClick={() => 
              setShowCart(!showChart)
            }
            >
            {(showChart === true)
            ? 'Hide Chart'
            : 'View Chart'
            }
          </Button>
          {
            (showChart)
            ?
              <TemperatureChartModule></TemperatureChartModule>
            :
              null
          }
          </div>
        :
        <div>
          <p>No data found.</p>
          <p>Upload your first temperature to start building your temperature profile!</p>
        </div>
      }
      
      
    </div>
  );

}






export default TemperatureProfileBase;
